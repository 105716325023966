import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import {
  GetConnectorAppBranchesInput,
  GetConnectorAppBranchesResponse,
} from '../../connectors-types';
import query from './get-connector-app-branches.gql';
import { ApolloClientCache } from 'ox-common-types';

const getConnectorAppBranches = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (
      getConnectorAppBranchesInput: GetConnectorAppBranchesInput,
      useCache = false,
    ) => {
      try {
        const results = await client.query<GetConnectorAppBranchesResponse>({
          query,
          variables: { getConnectorAppBranchesInput },
          fetchPolicy: useCache
            ? ApolloClientCache.CacheFirst
            : ApolloClientCache.NetworkOnly,
        });

        return results.data.getConnectorAppBranches;
      } catch (error) {
        logger.error(`getConnectorAppBranches query failed: ${error}`);
        throw error;
      }
    },
  };
};

export default getConnectorAppBranches;
