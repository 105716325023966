import { ScaVulnerability } from '../../../../../../types/issues-types';
import VulnerabilitiesStore from '../stores/vulnerabilities-store';

import { CveContext } from '../types';

export const setVulnerabilities = (
  vulnerabilities: ScaVulnerability[],
  update?: boolean,
) => {
  if (update) {
    VulnerabilitiesStore.data = vulnerabilities;
  } else {
    VulnerabilitiesStore.data = [
      ...VulnerabilitiesStore.data,
      ...vulnerabilities,
    ];
  }
};
export const setTotal = (total: number) => {
  VulnerabilitiesStore.total = total;
};
export const setOffset = (offset: number) => {
  VulnerabilitiesStore.offset = offset;
};

export const setLoadingVulns = (loading: boolean) => {
  VulnerabilitiesStore.loading = loading;
};

export const resetVulnerabilities = () => {
  VulnerabilitiesStore.data = [];
  VulnerabilitiesStore.offset = 0;
  VulnerabilitiesStore.total = 0;
  VulnerabilitiesStore.contextOptions = [];
  VulnerabilitiesStore.severityFactors = [];
};

export const setSearchValue = (searchValue: string) => {
  VulnerabilitiesStore.searchValue = searchValue;
};

export const setContextOptions = (options: CveContext[]) => {
  VulnerabilitiesStore.contextOptions = options;
};

export const setSeverityFactors = (severityFactors: string[]) => {
  VulnerabilitiesStore.severityFactors = severityFactors;
};
