// import { isRemoteSite } from 'env-utils';
import { env } from 'env-utils';
import apiConfig from './api-config.json';

export const resolveServiceUrl = (serviceName: keyof typeof apiConfig) => {
  const envVariable = env()[`REACT_APP_${serviceName}`];
  const envProtocol = env()['REACT_APP_PROTOCOL'];
  const serviceLink = `${envProtocol}://${env()['REACT_APP_DOMAIN_NAME']}/${
    apiConfig[serviceName]
  }`;
  return envVariable || serviceLink;
};
