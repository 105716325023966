import { AggregationItem } from 'ox-common-types';
import IssuesExclusionsStore from '../stores/issues-exclusions-store';
import { Exclusions } from '../types/issues-types';
import { OxExclusionId } from '@oxappsec/exclusion-service';

export const setExclusionsCategories = (categories: Exclusions[]) => {
  IssuesExclusionsStore.exclusionsCategories = categories;
};

export const setExcludeCategoryID = (categoryID: string) => {
  IssuesExclusionsStore.excludeCategoryId = categoryID;
};

export const setExcludeOxRuleId = (oxRuleId: OxExclusionId) => {
  IssuesExclusionsStore.excludeOxRuleId = oxRuleId;
};

export const setExclusionScope = (scope: string) => {
  IssuesExclusionsStore.exclusionScope = scope;
};

export const setExcludeCategoryName = (categoryName: string) => {
  IssuesExclusionsStore.excludeCategoryName = categoryName;
};

export const setExcludeIssueModal = (isExcludeIssueModal: boolean) => {
  IssuesExclusionsStore.excludeIssueModal = isExcludeIssueModal;
};

export const setExcludeBulkIssueModal = (showModal: boolean) => {
  IssuesExclusionsStore.excludeBulkIssuesModal = showModal;
};

export const setShowReportFalsePositiveModal = (payload: boolean) => {
  IssuesExclusionsStore.showReportFalsePositiveModal = payload;
};
export const setShowCancelReportFalsePositiveModal = (payload: boolean) => {
  IssuesExclusionsStore.showCancelReportFalsePositiveModal = payload;
};

export const closeExcludeIssueModal = () => setExcludeIssueModal(true);

export const setSelectedAggs = (selectedItems: AggregationItem[]) => {
  IssuesExclusionsStore.selectedAggs = selectedItems;
};

export const setIsExcludeDefault = (excludeDefault: boolean) => {
  IssuesExclusionsStore.excludeDefault = excludeDefault;
};
