export interface RequestAccessConfiguration {
  allowUserToRequestAccess: boolean;
  allowUserToCreateOrg: boolean;
}

export interface SetConfigurationResponse {
  updateSuccess: boolean;
}

export interface SetConfigurationInput {
  allow: boolean;
}

export enum RequestedAccessStatus {
  NA = 'NA',
  PENDING = 'PENDING',
  INVITATION_SENT = 'INVITATION_SENT',
  DISMISSED = 'DISMISSED',
}

export interface RequestedAccessInfo {
  orgId: string;
  email: string;
  status: RequestedAccessStatus;
  requestedAt: Date;
  updatedAt: Date;
  updatedBy: string;
  dismissCount: number;
}

export interface CanUserRequestAccessResponse {
  canRequestAccess: boolean;
  hasRequestedAccess: boolean;
  canCreateHisOwnOrg: boolean;
  requestedAccessInfos: RequestedAccessInfo[];
}

export interface RequestAccessResponse {
  requestedAccessInfos: RequestedAccessInfo[];
}

export interface RequestAccessInput {
  purpose: string;
}
