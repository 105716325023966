import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ApolloClientCache, Nullable } from 'ox-common-types';
import { Organization } from '../../organization-types';
import getOrgInfoQuery from './get-organization-info.gql';
import getOrgUsersInfoQuery from './get-organization-users-info.gql';

const getOrganizationInfo = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (useCache: boolean): Promise<Nullable<Organization>> => {
      try {
        const results = await client.query<GetOrganizationGQLResponse>({
          query: getOrgInfoQuery,
          fetchPolicy: useCache
            ? ApolloClientCache.CacheFirst
            : ApolloClientCache.NoCache,
        });
        return results.data.getOrganization;
      } catch (error) {
        return null;
      }
    },
  };
};

export const getOrganizationUsersInfo = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (): Promise<Nullable<Organization>> => {
      try {
        const results = await client.query<GetOrganizationGQLResponse>({
          query: getOrgUsersInfoQuery,
        });
        return results.data.getOrganization;
      } catch (error) {
        return null;
      }
    },
  };
};

export default getOrganizationInfo;

export interface GetOrganizationInfoInput {
  orgId: string;
  tryCache?: boolean;
}

export interface GetOrganizationGQLResponse {
  getOrganization: Organization;
}
