import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache, Nullable } from 'ox-common-types';
import {
  MessagingRecipient,
  MessagingVendorTypes,
} from '../../types/messaging-types';
import query from './get-recipients.gql';

const getRecipients = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      messageProvider: MessagingVendorTypes,
    ): Promise<Nullable<MessagingRecipient[]>> => {
      try {
        const results = await client.query<QueryResponse>({
          query,
          fetchPolicy: ApolloClientCache.CacheFirst,
          variables: {
            getRecipients: {
              messagingVendor: messageProvider,
            },
          },
        });

        return results.data.getRecipients;
      } catch (error) {
        logger.error('Failed to fetch recipients', error);
        return null;
      }
    },
  };
};

interface QueryResponse {
  getRecipients: MessagingRecipient[];
}

export default getRecipients;
