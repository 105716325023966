import { Nullable } from 'ox-common-types';
import { createStore, derive } from 'store-utils';
import {
  ActiveMember,
  NewMembersData,
  PendingInvitationMember,
} from '../types/members-types';
import {
  SCOPE_SELECTION_OPTIONS,
  ScopeOptions,
} from '../types/user-scope-types';
import {
  getAlreadyInvitedOrMemberEmailsObj,
  getInvalidEmailsObj,
  getIsSubmitButtonEnabled,
} from '../utils/members-utils';
import MembersStore from './members-store';

const baseStore = createStore<InviteMemberState>(
  {
    isInviteButtonEnabled: false,
    isInviteButtonLoading: false,
    isModalVisible: false,
    isSubmitButtonLoading: false,
    pendingInvitationMembers: [],
    hasErrorMessage: false,
    errorMessage: '',
    searchInput: '',
    selectedMember: null,
    newMembersData: {
      emails: [],
      roles: [],
      selectedAppOwnersScopeItems: [],
      selectedTagsScopeItems: [],
    },
    currentScopeOptionSelected:
      SCOPE_SELECTION_OPTIONS[ScopeOptions.EntireOrganization].value,
  },
  'Invite User Store',
);

const InviteMemberStore = derive<InviteMemberState, InviteMemberDerivedState>(
  {
    invalidEmailsObj: get => {
      const { newMembersData } = get(baseStore);
      const emails = newMembersData.emails;
      return getInvalidEmailsObj(emails);
    },
    alreadyInvitedOrMemberEmailsObj: get => {
      const { newMembersData } = get(baseStore);
      const emails = newMembersData.emails;
      return getAlreadyInvitedOrMemberEmailsObj(emails);
    },
    pendingInvitationMembersFiltered: get => {
      const { pendingInvitationMembers, searchInput } = get(baseStore);
      const lowerCaseValue = searchInput.toLowerCase();
      return pendingInvitationMembers.filter(pendingMember => {
        return pendingMember.inviteeEmail
          ?.toLowerCase()
          .includes(lowerCaseValue);
      });
    },
    isSubmitButtonEnabled: get => {
      const {
        newMembersData,
        selectedMember,
        errorMessage,
        currentScopeOptionSelected,
      } = get(baseStore);
      const { activeMembers } = get(MembersStore);

      return getIsSubmitButtonEnabled({
        newMembersData,
        selectedMember,
        errorMessage,
        currentScopeOptionSelected,
        activeMembers,
      });
    },
  },
  { proxy: baseStore },
);

export interface InviteMemberState {
  isModalVisible: boolean;
  isInviteButtonEnabled: boolean;
  isInviteButtonLoading: boolean;
  pendingInvitationMembers: PendingInvitationMember[];
  hasErrorMessage: boolean;
  errorMessage: string;
  searchInput: string;
  selectedMember: Nullable<ActiveMember>;
  newMembersData: NewMembersData;
  isSubmitButtonLoading: boolean;
  currentScopeOptionSelected: string;
}

export interface InviteMemberDerivedState {
  invalidEmailsObj: { invalidEmails: string[]; invalidEmailsErrMsg: string };
  alreadyInvitedOrMemberEmailsObj: {
    alreadyInvitedOrMemberEmails: string[];
    alreadyInvitedOrMemberEmailsErrMsg: string;
  };
  pendingInvitationMembersFiltered: PendingInvitationMember[];
  isSubmitButtonEnabled: boolean;
}

export default InviteMemberStore as InviteMemberState &
  InviteMemberDerivedState;
