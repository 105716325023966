import {
  OptionsObject,
  ProviderContext,
  SnackbarKey,
  SnackbarMessage,
  VariantType,
} from 'notistack';
import React from 'react';
import {
  SnackbarWrapper,
  SnackbarWrapperProps,
} from '../components/SnackbarWrapper';
import SnackbarStore from '../store/snackbar-store';
import { logger } from 'logging-utils';

const defaultOptions = {
  variant: 'info',
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
} as OptionsObject;

export const setSnackbarActions = (
  open: ProviderContext['enqueueSnackbar'],
  close: ProviderContext['closeSnackbar'],
) => {
  SnackbarStore.open = open;
  SnackbarStore.close = close;
};

export const openSnackbar = (
  snackbarMessage: SnackbarMessage,
  options?: OptionsObject,
  snackbarWrapperOptions: Omit<
    SnackbarWrapperProps,
    'closeFunction' | 'id'
  > = {},
) => {
  const { hideCloseIcon, isShowingSpinner, value, spinnerVariant, valueCb } =
    snackbarWrapperOptions;

  const key = options?.key || Math.random().toString(36);
  if (!options) {
    options = {
      key,
    };
  } else if (!options.key) {
    options.key = key;
  }
  const Wrapper = React.createElement(
    SnackbarWrapper,
    {
      closeFunction: () => {
        closeSnackbar(key);
      },
      hideCloseIcon,
      isShowingSpinner,
      id: `${key}`,
      spinnerVariant,
      value,
      valueCb,
    },
    snackbarMessage,
  );
  SnackbarStore.open &&
    SnackbarStore.open(Wrapper, {
      ...defaultOptions,
      ...options,
    });
};

/**
 * This is only required if you want to close the snackbar programmatically
 * if you don't use the 'persist' option when opening a snackbar, this is not needed
 * if you are using 'persist' make sure to provide a 'key' prop to be able to close it
 **/
export const closeSnackbar = (key?: SnackbarKey) => {
  SnackbarStore.close && SnackbarStore.close(key);
};

// helper functions

export const bottomRightSnackbar = (message: string, variant: VariantType) => {
  openSnackbar(message, {
    variant,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
  });
};

export const bottomRightSuccessSnackbar = (message: string) => {
  bottomRightSnackbar(message, 'success');
};

export const bottomRightErrorSnackbar = (message: string) => {
  bottomRightSnackbar(message, 'success');
};

export const openErrorSnackbar = (message: string) => {
  openSnackbar(message, { variant: 'error' });
};

export const openSuccessSnackbar = (message: string) => {
  openSnackbar(message, { variant: 'success' });
};

export const openErrorSnackbarAndLog = (message: string, error?: unknown) => {
  logger.error(message, error);
  openSnackbar(message, { variant: 'error' });
};
