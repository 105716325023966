import { Nullable } from 'ox-common-types';
import { createStore } from 'store-utils';
import { Connector } from '../connectors-types';

const ConnectorConfigureStore = createStore<ConnectorConfigureState>(
  {
    originalConnector: null,
    isModalVisible: false,
    selectedConnector: null,
    isLoadingVerifyAndSaveCredentials: false,
    isLoadingRemoveCredentials: false,
    isLoadingEnableDisable: false,
    invalidForm: false,
    hostUrlErrorMsg: '',
    hostUrlValidationError: false,
    repoSelectionModalOpen: false,
    isRepoSelectionModalFirstOpen: false,
    isConfirmationOpen: false,
    isStartNewScanModalOpen: false,
    isRepoLimitModalOpen: false,
  },
  'Connector Configure Store',
);

interface ConnectorConfigureState {
  isModalVisible: boolean;
  selectedConnector: Nullable<Connector>;
  originalConnector: Nullable<Connector>;
  isLoadingVerifyAndSaveCredentials: boolean;
  isLoadingRemoveCredentials: boolean;
  isLoadingEnableDisable: boolean;
  invalidForm: boolean;
  hostUrlValidationError: boolean;
  hostUrlErrorMsg: string;
  repoSelectionModalOpen: boolean;
  isRepoSelectionModalFirstOpen: boolean;
  isConfirmationOpen: boolean;
  isStartNewScanModalOpen: boolean;
  isRepoLimitModalOpen: boolean;
}

export default ConnectorConfigureStore;
