import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { AddAppBranchToMonitorInput } from '../../connectors-types';
import mutation from './monitor-app-branches-to-monitor.gql';

const monitorConnectorAppBranches = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (
      monitorConnectorAppBranchesInput: AddAppBranchToMonitorInput,
    ) => {
      try {
        const results = await client.mutate<AddAppBranchesToMonitorResponse>({
          mutation,
          variables: { monitorConnectorAppBranchesInput },
        });

        return results.data;
      } catch (error) {
        logger.error(`addAppBranchesToMonitor mutation failed: ${error}`);
        return false;
      }
    },
  };
};

interface AddAppBranchesToMonitorResponse {
  addAppBranchesToMonitor: {
    success: boolean;
  };
}

export default monitorConnectorAppBranches;
