import { Nullable } from '@oxappsec/ox-consolidated-gpt-types';
import { AppPages, currentPage } from 'app-navigator';
import { createSimpleAsyncAction } from 'async-utils';
import { AppEvents } from 'common-events';
import { getAllTableRowsIdsBetweenSelection } from 'common-utils';
import {
  FetchFileResponse,
  fileDownloadService,
  fileDownloadStore,
  handleDownloadPollerDone,
  handleDownloadProgressPercentage,
  handleFileReadyAction,
} from 'download-utils';
import { debounce, isEmpty, isNull } from 'lodash-es';
import { logger } from 'logging-utils';
import pluralize from 'pluralize';
import { FileDownloadPoller } from 'polling-utils';
import { closeSnackbar, openSnackbar } from 'snackbar-utils';
import { trackAppcuesEvent } from 'telemetry';
import { snapshot } from 'valtio';
import { GlobalDataViewSelector } from '../../app/components/DataViewSelector/global-data-view-selector-store';
import InventoryFiltersStore from '../../dashboard-inventory/stores/inventory-filters-store';
import { loadDashboardInfo } from '../../dashboard/actions/dashboard-actions';
import exclusionsService from '../../exclusions/services';
import { Relevance } from '../../irrelevant-apps/irrelevant-apps-types';
import ScanStore from '../../new-scan/store/scan-store';
import { DownloadType, QueryResponse } from '../applications-types';
import applicationsService from '../services';
import ApplicationsFiltersStore from '../stores/applications-filters-store';
import { applicationsFiltersStoreActions } from '../stores/applications-filters-store-actions';
import ApplicationsStore from '../stores/applications-store';
import { applicationsStoreActions } from '../stores/applications-store-actions';
import { getSelectedAppsIDs } from '../utils/application-utils';

export type LoadApplicationsParams = {
  update?: boolean;
  cache?: boolean;
  appId?: string;
  search?: string;
  scrollDirection?: 'bottom' | 'top';
};

const PDF_LIMITATION_NUMBER = 50;

export const applicationsActions = {
  loadApplicationsIds: async () => {
    const { scanID, isScanning, realScanId } = snapshot(ScanStore);
    const { filters } = snapshot(InventoryFiltersStore);
    const { allSelected, applications } = snapshot(ApplicationsStore);
    const { selectedTagIds, selectedAppOwnersEmails } = snapshot(
      GlobalDataViewSelector,
    );
    const { filterBy, selectedConditionalFilters } = snapshot(
      ApplicationsFiltersStore,
    );

    const apiParams = {
      isAppIdOnly: true,
      search: ApplicationsStore.appFilterValue,
      applicationFilters: filters,
      offset: 0,
      ...(realScanId ? { scanId: realScanId } : scanID && { scanId: scanID }),
      owners: selectedAppOwnersEmails,
      tagIds: selectedTagIds,
    };

    const filtersBy = !isEmpty(filterBy) ? filterBy : null;
    const conditionalFilters =
      selectedConditionalFilters.length > 0 ? selectedConditionalFilters : null;

    if (conditionalFilters) {
      apiParams['conditionalFilters'] = conditionalFilters;
    } else if (filtersBy) {
      apiParams['filters'] = filtersBy;
    }

    const response = await applicationsService.getApplicationsIds.execute(
      apiParams,
      !isScanning,
    );

    if (response && response.applications) {
      ApplicationsStore.selected = response.applications.reduce(
        (acc, { appId }) => {
          acc[appId] = applications?.find(app => app.appId === appId)?.fakeApp
            ? false
            : allSelected;
          return acc;
        },
        {},
      );

      ApplicationsStore.allApps = response.applications;
    }
  },

  loadApplications: createSimpleAsyncAction(
    async (params?: LoadApplicationsParams) => {
      const {
        update = false,
        cache = true,
        appId,
        scrollDirection = 'bottom',
        search = '',
      } = params || {};
      const { isScanning } = snapshot(ScanStore);

      if (search) {
        ApplicationsStore.appFilterValue = search;
      }
      if (update) {
        ApplicationsStore.offset = 0;
        ApplicationsStore.topOffset = 0;
      }

      const {
        total,
        offset,
        orderField,
        appFilterValue,
        orderDirection,
        orderByCategory,
        topOffset,
      } = snapshot(ApplicationsStore);

      if (scrollDirection === 'bottom' && total < offset) return;
      if (scrollDirection === 'top' && topOffset <= 0) return;

      const columnKey = orderField;
      const { filters } = snapshot(InventoryFiltersStore);
      const { scanID, realScanId } = snapshot(ScanStore);
      const { filterBy, selectedConditionalFilters } = snapshot(
        ApplicationsFiltersStore,
      );
      const { selectedTagIds, selectedAppOwnersEmails } = snapshot(
        GlobalDataViewSelector,
      );

      const filtersBy = isEmpty(filterBy) ? undefined : filterBy;
      const conditionalFilters =
        selectedConditionalFilters.length > 0
          ? selectedConditionalFilters
          : undefined;

      const apiParams = {
        topOffset,
        scrollDirection,
        offset: offset,
        owners: selectedAppOwnersEmails,
        tagIds: selectedTagIds,
        search: appFilterValue,
        limit: 50,
        applicationFilters: filters,
        ...(realScanId ? { scanId: realScanId } : scanID && { scanId: scanID }),
        orderBy: {
          direction: orderDirection,
          field: columnKey,
          category: orderByCategory,
        },
        appId,
      };

      if (conditionalFilters) {
        apiParams['conditionalFilters'] = conditionalFilters;
      } else if (filtersBy) {
        apiParams['filters'] = filtersBy;
      }

      const response = await applicationsService.getApplications.execute(
        apiParams,
        isScanning ? false : cache,
      );

      if (update) {
        applicationsActions.loadApplicationsIds();
      }

      if (response && response.applications) {
        const {
          offset,
          applications: baseApplications,
          total,
          topOffset,
          totalFilteredApps,
          totalIrrelevantApps,
        } = response;

        loadDashboardInfo();
        const isAppPage = currentPage() === AppPages.Applications;

        ApplicationsStore.selectedSourceControl = response.applications.reduce(
          (acc, { appId }) => {
            acc[appId] = baseApplications?.find(
              app => app.appId === appId,
            )?.type;
            return acc;
          },
          {},
        );
        if (baseApplications.length > 0 && isAppPage) {
          trackAppcuesEvent(AppEvents.Apps.Loaded);
        }
        applicationsStoreActions.setApplicationsPosition(
          response.selectedPosition,
        );
        applicationsStoreActions.setApplicationsOffset(offset);
        applicationsStoreActions.setApplicationsTopOffset(topOffset);
        applicationsStoreActions.setApplications({
          baseApplications,
          update,
          scrollDirection,
        });
        applicationsStoreActions.setApplicationsTotal(total);
        applicationsStoreActions.setIrrelevantApplicationsTotal(
          totalIrrelevantApps,
        );
        applicationsFiltersStoreActions.setTotalAppsFilters(totalFilteredApps);
      }
    },
    {
      asyncState: ApplicationsStore.loading,
      errorMessage: 'Failed to load applications',
    },
  ),

  selectApp: (appId: string, isShiftPressed: boolean) => {
    const { selected, applications, selectedAppsIds } =
      snapshot(ApplicationsStore);
    if (isShiftPressed && applications) {
      const selectedUpdatedIds = getAllTableRowsIdsBetweenSelection(
        appId,
        'appId',
        applications,
        selectedAppsIds,
      );
      const selectedUpdated = applications?.reduce(
        (acc: { [appId: string]: boolean }, app) => {
          acc[app.appId] = selectedUpdatedIds.includes(app.appId);
          return acc;
        },
        {},
      );
      ApplicationsStore.selected = selectedUpdated;
    } else {
      ApplicationsStore.selected[appId] = !selected[appId];
    }
  },

  setBusinessPriority: async (value: number, ids?: string[]) => {
    const appIds = ids || getSelectedAppsIDs();
    const { selected } = snapshot(ApplicationsStore);
    const showError = (e?) => {
      logger.error(e);
      openSnackbar(`Failed to change business priority`, {
        variant: 'error',
      });
      return false;
    };
    try {
      const response = await applicationsService.setPriority.execute({
        priority: value,
        appId: appIds,
      });

      if (!response) {
        showError();
        return false;
      }
      if (response) {
        openSnackbar(
          `Business priority  for ${pluralize(
            'apps',
            appIds.length,
            true,
          )}  was changed succefully`,
          {
            variant: 'success',
          },
        );
      }
      if (ApplicationsStore.applications) {
        const updatedApplicationsList = ApplicationsStore.applications?.map(
          app =>
            selected[app.appId] ? { ...app, businessPriority: value } : app,
        );
        applicationsStoreActions.setApplicationsList(updatedApplicationsList);
      }
    } catch (e) {
      showError(e);
    }
  },

  onSearchChange: debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    applicationsStoreActions.setSearchValue(e.target.value);
    applicationsActions.loadApplications({ update: true });
  }, 300),

  setIrrelevant: async () => {
    try {
      const { selected, applications } = snapshot(ApplicationsStore);
      const input: { appId: string; appName: string; comment?: string }[] = [];
      applications?.forEach(app => {
        if (selected[app.appId]) {
          input.push({
            appId: app.appId,
            appName: app.appName,
            comment: app.exclusionComment,
          });
        }
      });
      const response = await exclusionsService.excludeApplications.execute(
        input,
      );
      if (response.exclusions?.length) {
        openSnackbar('Apps were set irrelevant succesfully', {
          variant: 'success',
        });
        applicationsStoreActions.clearSelectedApplication();
        applicationsStoreActions.setIsApplicationDrawerOpen(false);
        applicationsActions.loadApplications({ update: true, cache: false });
      }
    } catch (e) {
      logger.error(e);
      openSnackbar('Failed to make the application irrelevant', {
        variant: 'error',
      });
    }
  },

  resetAppRelevance: async (appId: string[]) => {
    const response = await applicationsService.restorePriority.execute({
      appId: appId,
    });
    if (response) {
      applicationsActions.loadApplications({ update: true });
    }
    openSnackbar(
      response
        ? 'App business priority was reset!'
        : 'Failed to reset app business priority',
      {
        variant: response ? 'success' : 'error',
      },
    );

    return response;
  },

  setAppRelevance: async (appId: string, relevance: Relevance) => {
    const response = await applicationsService.setRelevance.execute({
      appIds: [appId],
      relevance,
    });

    openSnackbar(
      response ? 'Relevance was changed!' : 'Failed to change relevance',
      { variant: response ? 'success' : 'error' },
    );

    return response;
  },

  handleDownloadApplications: async (
    downloadType: DownloadType,
    filtered?: boolean,
  ) => {
    const downloadApplicationsCSVSnackbarKey =
      'download-applications-csv-snackbar';
    const { downloadProgress } = snapshot(fileDownloadStore);
    fileDownloadStore.appsCsvDownloadInProgress = true;
    const {
      total,
      orderField,
      appFilterValue,
      orderDirection,
      orderByCategory,
      selected,
    } = snapshot(ApplicationsStore);
    const { selectedCount } = snapshot(ApplicationsStore);
    const totalRows = filtered ? selectedCount : total;
    if (
      totalRows > PDF_LIMITATION_NUMBER &&
      downloadType === DownloadType.PDF
    ) {
      applicationsStoreActions.displayLimitationModal();
      return;
    }
    openSnackbar(
      `Exporting ${
        filtered ? 'selected' : 'entire'
      } Applications to ${downloadType}`,
      {
        variant: 'default',
        persist: true,
        key: downloadApplicationsCSVSnackbarKey,
      },
      {
        hideCloseIcon: false,
        isShowingSpinner: true,
        spinnerVariant: 'determinate',
        value: downloadProgress,
        valueCb: () => {
          return snapshot(fileDownloadStore).downloadProgress;
        },
      },
    );

    const columnKey = orderField;
    const { filters } = snapshot(InventoryFiltersStore);
    const { scanID, realScanId } = snapshot(ScanStore);
    const { selectedTagIds, selectedAppOwnersEmails } = snapshot(
      GlobalDataViewSelector,
    );
    let response: QueryResponse | null = null;
    const filtersInput = {
      appId: Object.entries(selected).reduce((acc, next) => {
        const [k, v] = next;
        v && acc.push(k);
        return acc;
      }, [] as string[]),
    };
    const scan = realScanId ? { scanID: realScanId } : scanID && { scanID };

    switch (downloadType) {
      case DownloadType.CSV:
        response = await applicationsService.exportAppsToCsv.execute({
          owners: selectedAppOwnersEmails,
          tagIds: selectedTagIds,
          search: appFilterValue,
          applicationFilters: filters,
          ...(realScanId
            ? { scanId: realScanId }
            : scanID && { scanId: scanID }),
          orderBy: {
            direction: orderDirection,
            field: columnKey,
            category: orderByCategory,
          },
          filters: filtered ? filtersInput : {},
        });
        break;
      case DownloadType.PDF:
        response = await applicationsService.exportAppsToPdf.execute({
          scanId: realScanId || (scan ? scan.scanID : Date.now().toString()),
          filters: filtered ? filtersInput : {},
        });
        break;
    }

    if (isNull(response)) {
      closeSnackbar(downloadApplicationsCSVSnackbarKey);
      openSnackbar(
        `Failed generating applications csv, please try again later..`,
        {
          variant: 'error',
        },
      );
      return;
    }

    const poller = new FileDownloadPoller<FetchFileResponse['fetchReadyFile']>(
      () => {
        if (!response?.requestId) {
          throw new Error(
            `response object or requestId were null: ${response}`,
          );
        }
        return fileDownloadService.fetchReadyFile.execute({
          requestId: response?.requestId,
        });
      },
      (response: Nullable<FetchFileResponse['fetchReadyFile']>) => {
        if (response) {
          if (response.error) {
            closeSnackbar(downloadApplicationsCSVSnackbarKey);
            openSnackbar(
              `Something went wrong during applications export ${downloadType} format. We are already investigate it. Please try again later...`,
              {
                variant: 'error',
              },
            );
            return false;
          }
          totalRows && handleDownloadProgressPercentage(totalRows);
          if (response.isFileReady) {
            handleFileReadyAction(
              response,
              `Successfully downloaded ${
                filtered ? 'filtered' : 'entire'
              } Applications ${downloadType}`,
            );
          }
          return !response.isFileReady;
        } else {
          return false;
        }
      },
      () => {},
      () => {
        handleDownloadPollerDone(downloadApplicationsCSVSnackbarKey);
      },
    );
    poller.startPolling();
    setTimeout(() => {
      poller.cancelPolling();
    }, 720000);
  },
};
