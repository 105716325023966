import { useTheme } from '@mui/material';
import useResizeObserver from '@react-hook/resize-observer';
import {
  ArcElement,
  ChartData,
  ChartEvent,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  Plugin,
  Tooltip,
  TooltipItem,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import color from 'color';
import { FC, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { createChartJSGradient } from '../LineChart/chartjs-utils';
import { GENERAL_COLORS } from '../../../ox-common-types/src/color-types';

ChartJS.register(ArcElement, Tooltip, Legend);

const capitalizeFirstLetter = (str: string) =>
  `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

export const PieChart: FC<PieChartProps> = ({
  data,
  labels,
  colors,
  tooltipItemName,
  onClick,
  isUseGradientColors = true,
}) => {
  const theme = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const chartRef = useRef<ChartJS<'line', number[], number>>(null);
  const [size, setSize] = useState<{ width: number; height: number }>();

  // to get the initial size
  useLayoutEffect(() => {
    if (!ref.current) return;
    const { width, height } = ref.current.getBoundingClientRect();
    setSize({ width, height });
  }, []);

  // watch for container resize
  useResizeObserver(ref, entry => {
    const { width, height } = entry.contentRect;
    chartRef.current?.resize(width, height);
  });

  const _colors = colors.length > 0 ? colors : GENERAL_COLORS;

  const memoizedData = useMemo((): ChartData<'pie', number[], string> => {
    return {
      labels: labels.map(capitalizeFirstLetter),
      datasets: [
        {
          data,
          hoverOffset: 5,
          hoverBorderColor: 'transparent',
          backgroundColor: function (context) {
            const chart = context.chart;
            const { ctx, chartArea } = chart;
            const bgColor = () => {
              if (!isUseGradientColors) {
                return _colors[context.dataIndex];
              } else if (chartArea) {
                return createChartJSGradient(
                  ctx,
                  chartArea,
                  _colors[context.dataIndex],
                  'dark',
                );
              } else {
                return undefined;
              }
            };
            return bgColor();
          },
          borderColor: theme.palette.background.paper,
        },
      ],
    };
  }, [theme, data, labels, _colors, isUseGradientColors]);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
      ref={ref}>
      {size && (
        <Pie
          data={memoizedData}
          width={size.width}
          height={size.height}
          plugins={[ChartDataLabels] as Plugin<'pie'>[]}
          options={
            {
              onClick: onClick
                ? (_, elements) => {
                    elements.length > 0 && onClick(elements[0]?.index);
                  }
                : undefined,
              onHover: onClick
                ? (event: ChartEvent, chartElement) => {
                    if (event.native?.target) {
                      const el = event.native.target as HTMLElement;
                      el.style.cursor = chartElement[0] ? 'pointer' : 'default';
                    }
                  }
                : undefined,
              layout: {
                padding: 2, // since we're using hoverOffset
              },
              cutout: '60%',
              responsive: false,
              maintainAspectRatio: true,
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  callbacks: {
                    label: (item: TooltipItem<'pie'>) =>
                      ` ${item.raw}${
                        tooltipItemName ? ' ' + tooltipItemName : ''
                      }`,
                  },
                },
                // https://chartjs-plugin-datalabels.netlify.app/samples/charts/doughnut.html
                datalabels: {
                  formatter: (value: number) =>
                    Intl.NumberFormat(navigator.language, {
                      notation: 'compact',
                      maximumFractionDigits: 1,
                    }).format(value),
                  font: {
                    weight: 'bold',
                  },
                  color: function (context: { dataIndex: number }) {
                    const rawBgColor = _colors[context.dataIndex];
                    const bgColor = color(
                      rawBgColor,
                      // colors prop may contain a mixture of color formats
                      rawBgColor.startsWith('#') ? 'hex' : 'rgb',
                    );
                    return bgColor.isDark()
                      ? theme.palette.common.white
                      : theme.palette.common.black;
                  },
                },
              },
            } as ChartOptions<'pie'>
          }
        />
      )}
    </div>
  );
};

export interface PieChartProps {
  data: number[];
  labels: string[];
  colors: string[];
  tooltipItemName?: string;
  onClick?: (index: number) => void;
  isUseGradientColors?: boolean;
}
