import { NodeEnvironmentType } from 'ox-common-types';
import { env } from './env';

declare global {
  interface Window {
    env: Record<string, string | number | boolean>;
  }
}

export const getEnvironmentType = (): NodeEnvironmentType => {
  return env().REACT_APP_ENVIRONMENT as NodeEnvironmentType;
};

export const isDevelopment = (): boolean => {
  return (
    getEnvironmentType() === NodeEnvironmentType.Local ||
    getEnvironmentType() === NodeEnvironmentType.Development
  );
};

export const isProd = (): boolean => {
  const envType = getEnvironmentType();
  return [NodeEnvironmentType.Beta, NodeEnvironmentType.Production].includes(
    envType,
  );
};

export const isTesting = (): boolean => {
  return getEnvironmentType() === NodeEnvironmentType.Test;
};

export const isRemoteSite = (): boolean => {
  const remoteSite = env().REACT_APP_IS_REMOTE_SITE === 'true';

  // remote site will be provided in case we are running on a customer site,
  // TODO remove on prem flag once we finish the move to K8, on prem flag is legacy and used for BC
  return remoteSite || getEnvironmentType() === NodeEnvironmentType.OnPrem;
};

export const getEnvVar = (
  name: string,
  defaultValue?: unknown,
): string | unknown => {
  return env()[`REACT_APP_${name}`] || defaultValue;
};

export const resolveNumericEnvVar = (varName: string, defaultValue: number) => {
  const value = env()[varName];
  return value ? parseInt(value) : defaultValue;
};

export const isLocalDevelopment = () => {
  return getEnvironmentType() === NodeEnvironmentType.Local;
};

export const USER_LOGINS_COUNT = `${
  env().REACT_APP_AUTH0_AUDIENCE
}/logins_count` as const;
