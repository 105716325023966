import { logger } from 'logging-utils';
import organizationService from '../services';
import { setOrgInfo } from '../store-actions/org-info-store-actions';
import { snapshot } from 'valtio';
import OrgInfoStore from '../stores/org-info-store';

export const fetchOrgInfo = async (useCache: boolean = true) => {
  try {
    const orgInfo = await organizationService.getOrganizationInfo.execute(
      useCache,
    );
    if (!orgInfo) {
      return null;
    }
    setOrgInfo(orgInfo);
    return orgInfo;
  } catch (e) {
    logger.error(`Failed to fetch org info. error: ${e}`);
    return null;
  }
};

export const fetchOrgUsersInfo = async () => {
  try {
    const orgInfo =
      await organizationService.getOrganizationUsersInfo.execute();
    if (!orgInfo) {
      return;
    }
    setOrgInfo(orgInfo);
  } catch (e) {
    logger.error(`Failed to fetch org info. error: ${e}`);
    return;
  }
};

export const setIsOnboardingRequired = async (
  isOnboardingRequired: boolean,
) => {
  try {
    const success = await organizationService.setIsOnboardingRequired.execute(
      isOnboardingRequired,
    );
    if (!success) {
      return;
    }

    const { organization } = snapshot(OrgInfoStore);
    if (!organization) {
      return;
    }

    const orgInfo = { ...organization, isOnboardingRequired };
    setOrgInfo(orgInfo);
  } catch (e) {
    logger.error(`Failed to fetch org info. error: ${e}`);
    return;
  }
};
