import { openSnackbar } from 'snackbar-utils';
import { useSnapshot } from 'valtio';
import requestAccessService from '../services';
import {
  setCanUserRequestAccessResponse,
  setIsLoadingRequestAccess,
  updateRequestAccessInfo,
} from '../store-actions/request-access-store-actions';
import RequestAccessStore from '../stores/request-access-store';

export const fetchCanUserRequestAccess = async () => {
  const response = await requestAccessService.canUserRequestAccess.execute();

  setCanUserRequestAccessResponse(response);

  return response;
};

export const requestAccess = async (purpose: string) => {
  setIsLoadingRequestAccess(true);

  const response = await requestAccessService.requestAccess.execute({
    purpose,
  });

  if (response && response.requestedAccessInfos?.length > 0) {
    openSnackbar('Access requested successfully', { variant: 'success' });
    updateRequestAccessInfo(response.requestedAccessInfos);
  } else {
    openSnackbar('Failed to request access', { variant: 'error' });
  }

  setIsLoadingRequestAccess(false);
};

export const useRequestAccess = () => useSnapshot(RequestAccessStore);
