import { AppEvents } from 'common-events';
import { logger } from 'logging-utils';
import { useEffect, useRef } from 'react';
import { usePrevious } from 'react-utils';
import { snapshot, useSnapshot } from 'valtio';
import scanService from '../services';
import GradualStore from '../store/gradual-store';
import ScanStore, { ScanPhase } from '../store/scan-store';
import {
  closeCancelScanModal,
  decrementScanCount,
  setCancelScanErrorMessage,
  setEnabledScheduledScans,
  setIsDeltaScan,
  setIsLoadingCancelScan,
  setScanError,
  setScanId,
  setScanPhase,
  setScanSettings,
} from '../store/scan-store-actions';
import {
  clearStore as clearDiscoveryStore,
  startDiscoveryPhase,
} from './discovery-actions';
import { clearStore as clearGradualStore } from './gradual-actions';
import { Phase } from '../scan-types';
import { isDevelopment } from 'env-utils';

export const scanAll = async (
  isDemo?: boolean,
  isFullScan?: boolean,
  isContainerFullScan?: boolean,
  isThirdPartyFullScan?: boolean,
) => {
  setScanPhase(ScanPhase.ScanStarted);
  try {
    const result = await scanService.scanAll.execute({
      isDemo,
      isFullScan,
      isContainerFullScan,
      isThirdPartyFullScan,
    });
    if (result) {
      setScanId(result.scanID);
      setIsDeltaScan(result.isFullScan);
      document.dispatchEvent(new CustomEvent(AppEvents.Scan.ScanStart));
      startDiscoveryPhase();
    }
  } catch (error) {
    setScanError(`${error}`);
  }
};

export const fetchScanSettingsAndStatus = async () => {
  const result = await scanService.scanSettingsAndStatus.execute();
  if (result) {
    const { checkScanInProgress, getScanSettings } = result;
    if (checkScanInProgress && checkScanInProgress.isInProgress) {
      setScanId(checkScanInProgress.scanID);
      setIsDeltaScan(checkScanInProgress.isFullScan);
      startDiscoveryPhase();
    }
    if (getScanSettings) {
      setScanSettings(getScanSettings);
    }
    return checkScanInProgress;
  }
};

export const cancelScan = async () => {
  try {
    const { scanID: scanId } = snapshot(ScanStore);

    if (!scanId) {
      logger.error(`missing scanId, can not cancel scan`);
      setCancelScanErrorMessage('No active scan found');
      return;
    }
    setIsLoadingCancelScan(true);
    const cancelScanResponse = await scanService.cancelScan.execute(scanId);
    if (!cancelScanResponse) {
      setCancelScanErrorMessage('Something went wrong, please try again later');
      return;
    }
    const { isCanceled, error, previousScanId } = cancelScanResponse;
    if (!isCanceled) {
      logger.error(`failed to cancel scan for ${scanId} with error: ${error}`);
      setCancelScanErrorMessage('Something went wrong, please try again later');
      return;
    }
    clearScanStore();
    setScanId(previousScanId);
    setIsLoadingCancelScan(false);
    decrementScanCount();
  } catch (e) {
    logger.error(`failed to cancel scan`, e);
  } finally {
    closeCancelScanModal();
  }
};

const clearScanStore = () => {
  setScanPhase(ScanPhase.Done);
  clearGradualStore();
  clearDiscoveryStore();
  setScanId(null);
};

const useGradualUpdateOld = (
  callback?: (scanned: number, total: number) => void,
) => {
  const isMountEffect = useRef(true);
  const { phase } = useSnapshot(ScanStore);
  const previousPhase = usePrevious<ScanPhase>(phase);
  const inProgress = useIsScanning();
  const { scanned, total } = useSnapshot(GradualStore);
  const prevScanned = usePrevious<number>(scanned);

  useEffect(() => {
    if (!isMountEffect.current && scanned > (prevScanned || 0) && callback) {
      callback(scanned, total);
    }

    isMountEffect.current = false;
  }, [callback, scanned, total, prevScanned]);

  // make the last call when gradual updates are done
  useEffect(() => {
    const checkPreviousPhase =
      previousPhase &&
      previousPhase !== ScanPhase.Done &&
      phase === ScanPhase.Done;
    if (checkPreviousPhase) {
      callback && callback(scanned, total);
    }
  }, [callback, phase, previousPhase, scanned, total]);

  return { scanned, total, inProgress };
};

const useGradualUpdateNew = (
  callback?: (scanned: number, total: number) => void,
) => {
  const isMountEffect = useRef(true);
  const { phase } = useSnapshot(ScanStore);
  const previousPhase = usePrevious<ScanPhase>(phase);
  const inProgress = useIsScanning();
  const { scanProgressItems, scanned, total } = useSnapshot(GradualStore);
  const prevScanned =
    usePrevious<{ phase: Phase; text: string; percentage: number }[]>(
      scanProgressItems,
    );

  useEffect(() => {
    if (inProgress) {
      if (!isMountEffect.current && callback) {
        for (const currItem of scanProgressItems) {
          const prevItem = prevScanned?.find(
            item => item.phase === currItem.phase,
          );
          if (
            !prevItem ||
            (prevItem && currItem.percentage !== prevItem.percentage)
          ) {
            callback(scanned, total);
          }
        }
      }
      isMountEffect.current = false;
    }
  }, [callback, scanned, total, prevScanned, scanProgressItems, inProgress]);

  // make the last call when gradual updates are done
  useEffect(() => {
    const checkPreviousPhase =
      previousPhase &&
      previousPhase !== ScanPhase.Done &&
      phase === ScanPhase.Done;
    if (checkPreviousPhase) {
      callback && callback(scanned, total);
    }
  }, [callback, phase, previousPhase, scanned, total]);

  return { scanned, total, inProgress };
};

export const useGradualUpdate = isDevelopment()
  ? useGradualUpdateNew
  : useGradualUpdateOld;

export const useIsScanning = () => {
  const { isScanning } = useSnapshot(ScanStore);
  return isScanning;
};

export const enabledScheduledScan = async (enabled: boolean) => {
  const result = await scanService.setEnabledScheduledScan.execute(enabled);
  if (result) {
    setEnabledScheduledScans(result.setEnabledScheduledScan);
  }
};
export const useScan = () => useSnapshot(ScanStore);
