import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { logger } from 'logging-utils';
import { AggregationColumn, AggregationItem } from 'ox-common-types';
import { FC, useCallback, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { InfoIcon } from '../InfoIcon';
import AggInfoModal from './AggInfoModal';
import AggregatedRow from './AggregationRow';
import { filterConfigByTypes } from './aggregation-utils';

export const AggregationsTable: FC<
  React.PropsWithChildren<AggregationsProps>
> = props => {
  let {
    items,
    config,
    info,
    withSelection,
    allSelected,
    selected,
    toggleAll,
    toggle,
    isInfo,
    exclusionCategory,
  } = props;
  const [additionalData, setAdditionalData] = useState<Record<
    string,
    string[]
  > | null>(null);
  const handleRowClick = (additionalData: string) => {
    try {
      setAdditionalData(JSON.parse(additionalData));
    } catch {
      logger.info('Failed to parse additional info for aggregation item');
    }
  };
  const handleClose = useCallback(() => setAdditionalData(null), []);
  const { classes } = useStyles();
  if (!config) return null;

  withSelection =
    withSelection === undefined ? config.withSelection : withSelection;

  const lineNumbers = items?.map(i => Number(i.startLine));
  const isAllLineNumberMinusOne = lineNumbers?.every(i => i === -1) || false;

  let filterColumns = filterConfigByTypes(config.columns, items);
  filterColumns = filterColumns?.filter(
    column => !(isAllLineNumberMinusOne && column.key === 'startLine'), // Exclude "Line" column
  );
  return (
    <>
      {additionalData && (
        <AggInfoModal
          open={!!additionalData}
          handleClose={handleClose}
          additionalInfo={additionalData}
        />
      )}
      <div className={classes.header}>
        <Typography variant='body2' color='text.secondary'>
          {isInfo ? `Aggregations (${items.length})` : 'Aggregations'}
        </Typography>
        {isInfo && (
          <InfoIcon
            marginX={1}
            title={
              'The selections below affect what is sent to the following actions in the card above: View Code Fix, Open PR, Create Jira Ticket, Exclude Issue and Snooze Issue.'
            }
          />
        )}
        {info && (
          <Typography variant='body2' color='text.primary' ml={1}>
            {info}
          </Typography>
        )}
      </div>
      <TableContainer sx={{ width: '100%', cursor: 'default', mt: 1 }}>
        <Table size='small' stickyHeader>
          <TableHead>
            <TableRow>
              {withSelection && (
                <TableCell padding='checkbox'>
                  <Checkbox
                    checked={allSelected}
                    onChange={toggleAll}
                    disabled={exclusionCategory === 'general'}
                    color='primary'
                  />
                </TableCell>
              )}
              {filterColumns?.map((column: AggregationColumn) => (
                <TableCell sx={column.sx} key={`header${column.key}`}>
                  <Typography variant='body2' color='textPrimary'>
                    {column.header}
                  </Typography>
                </TableCell>
              ))}
              {items.some(i => i.additionalToolData) && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, index) => (
              <AggregatedRow
                onClick={handleRowClick}
                key={`aggregatedRow${index}`}
                index={index}
                item={item}
                withSelection={withSelection}
                columns={filterColumns}
                toggle={toggle}
                selected={selected?.[index]}
                selectedItem={item}
                exclusionCategory={exclusionCategory}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const useStyles = makeStyles()(() => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

export interface AggregationsProps {
  items: AggregationItem[];
  withSelection?: boolean;
  config: { columns: AggregationColumn[]; withSelection: boolean };
  allSelected?: boolean;
  selected?: { [key: number]: boolean };
  toggle?: (index: number, aggItem: AggregationItem) => void;
  toggleAll?: () => void;
  info?: string;
  isInfo?: boolean;
  exclusionCategory?: string;
  sourceToolType?: string;
}
