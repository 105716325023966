import { createAsyncState } from 'async-utils';
import { Nullable } from 'ox-common-types';
import {
  ConditionalFilter,
  FilterIssueType,
  FilterItems,
  SearchInput,
  getNumberOfSelectedFilters,
} from 'ox-filter-utils';
import { createStore, derive } from 'store-utils';
import {
  FilterByTypesToolTip,
  Issue,
  IssueDetails,
  IssueSortKey,
  IssuesOrder,
} from '../../common/types/issues-types';

export const initialPipelineIssuesState: Partial<PipelineIssuesStoreState> = {
  issues: [],
  selectedIssue: null,
  totalIssues: 0,
  offset: 0,
  issuesFiltersType: null,
  issuesFiltersTypeSearch: null,
  isModalVisible: false,
  filtersOpen: true,
  order: [IssuesOrder.Desc],
  orderBy: [IssueSortKey.JobTriggeredAt],
  filterIssuesBy: {},
  totalFilteredIssues: 0,
  filtersByTypesToolTip: { category: '', value: '' },
  selectedIssueId: null,
  policyDisableModal: false,
  loadingIssue: false,
  applicationIrrelevantModal: false,
  sliderValuePriority: [0, 100],
  lastDrawerHeight: undefined,
  isFromTopIssues: false,
  isActiveSlackConnection: false,
  showCreateJiraTicketDialog: false,
  showCreateBulkJiraTicketDialog: false,
  showLinkJiraTicketDialog: false,
  showUnlinkJiraTicketDialog: false,
  showSendSlackMessageDialog: false,
  isQuickFixModalVisible: false,
  isQuickFixPermissionDeniedModalVisible: false,
  isFilesDiffModalVisible: false,
  showConfirmExportCSVDialog: false,
  menuIssueId: null,
  applicationStatistics: null,
  totalResolvedIssues: 0,
  totalActiveIssues: 0,
  commentModal: false,
  isAddingCommentLoading: false,
  isDeleteCommentLoading: false,
  issueComment: '',
  loadingJiraTicketsStatus: false,
  searchValues: [],
  searchValue: '',
  changeSeverityModal: false,
  showBulkChangeSeverityModal: false,
  showBulkIssueCommentModal: false,
  showReportFalsePositiveModal: false,
  isLoadingApplicationStatistics: false,
  selected: {},
  allSelected: false,
  showBulkResetSeverityButton: false,
  showBulkEditCommentButton: false,
  showBulkDeleteCommentButton: false,
  conditionalFilters: [],
  filterItems: null,
  isLoadingFilters: false,
};

const baseStore = createStore<PipelineIssuesStoreState>(
  {
    ...JSON.parse(JSON.stringify(initialPipelineIssuesState)),
    loading: createAsyncState(),
    loadingFilters: createAsyncState(),
  },
  'Pipeline Issues Store',
);

const PipelineIssuesStore = derive(
  {
    numberOfFilters: get => {
      const { filterIssuesBy, searchValue, conditionalFilters } =
        get(baseStore);
      return getNumberOfSelectedFilters(
        filterIssuesBy,
        searchValue,
        conditionalFilters,
      );
    },
  },

  { proxy: baseStore },
);

export interface PipelineIssuesStoreState {
  selected: {
    [x: string]: boolean;
  };
  allSelected: boolean;
  showBulkResetSeverityButton: boolean;
  showBulkEditCommentButton: boolean;
  issues: Issue[];
  selectedIssue: Nullable<IssueDetails>;
  selectedIssueId: Nullable<string>;
  totalIssues: number;
  offset: number;
  loading: ReturnType<typeof createAsyncState>;
  loadingFilters: ReturnType<typeof createAsyncState>;
  loadingJiraTicketsStatus: boolean;
  issuesFiltersType: Nullable<FilterIssueType>;
  issuesFiltersTypeSearch: Nullable<FilterIssueType>;
  isModalVisible: boolean;
  filtersOpen: boolean;
  order: IssuesOrder[];
  orderBy: IssueSortKey[];
  filterIssuesBy: {};
  totalFilteredIssues: number;
  filtersByTypesToolTip: FilterByTypesToolTip;
  policyDisableModal: boolean;
  loadingIssue: boolean;
  applicationIrrelevantModal: boolean;
  sliderValuePriority: number[];
  lastDrawerHeight?: number;
  isFromTopIssues: boolean;
  isActiveSlackConnection: boolean;
  showCreateJiraTicketDialog: boolean;
  showCreateBulkJiraTicketDialog: boolean;
  showLinkJiraTicketDialog: boolean;
  showUnlinkJiraTicketDialog: boolean;
  showSendSlackMessageDialog: boolean;
  isQuickFixModalVisible: boolean;
  isQuickFixPermissionDeniedModalVisible: boolean;
  isFilesDiffModalVisible: boolean;
  showConfirmExportCSVDialog: boolean;
  menuIssueId: Nullable<Issue['id']>;
  applicationStatistics: Nullable<FilterIssueType>;
  totalResolvedIssues: number;
  totalActiveIssues: number;
  commentModal: boolean;
  isAddingCommentLoading: boolean;
  isDeleteCommentLoading: boolean;
  issueComment: string;
  searchValues: SearchInput[];
  searchValue: string;
  changeSeverityModal: boolean;
  showBulkChangeSeverityModal: boolean;
  showBulkIssueCommentModal: boolean;
  showReportFalsePositiveModal: boolean;
  isLoadingApplicationStatistics: boolean;
  showBulkDeleteCommentButton: boolean;
  conditionalFilters: ConditionalFilter[];
  filterItems: Nullable<FilterItems>;
  isLoadingFilters: boolean;
}

export default PipelineIssuesStore;
