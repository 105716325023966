import { AppPages, AppPagesDisplayName } from 'app-navigator';
import { OscarIcon } from 'common-icons';
import { IconType } from 'ox-common-types';
import { AiOutlineLineChart } from 'react-icons/ai';
import { BiScan } from 'react-icons/bi';
import { BsListCheck } from 'react-icons/bs';
import { FaCloud, FaInfinity } from 'react-icons/fa';
import { GiGlowingArtifact, GiSecretBook } from 'react-icons/gi';
import { LuListX } from 'react-icons/lu';
import {
  MdAccountTree as AccountTree,
  MdApps as Apps,
  MdCampaign as Campaign,
  MdInsertChart as InsertChart,
  MdLogin,
  MdOutlineGroups,
  MdOutlineNotificationsActive,
  MdOutlineVpnKey,
  MdPlaylistPlay,
  MdPolicy as Policy,
  MdSettings as Settings,
  MdTableRows as TableRows,
} from 'react-icons/md';
import { PiListDashesFill } from 'react-icons/pi';
import { RxActivityLog } from 'react-icons/rx';
import { SiQuicklook } from 'react-icons/si';
import { IconApiApp } from '@tabler/icons-react';
import { TiFlowChildren } from 'react-icons/ti';
import { VscOrganization } from 'react-icons/vsc';
import { ReactComponent as ExclusionsIcon } from '../../assets/icons/exclusions-small.svg';
import { TabQueryParam } from '../../settings/types/settings-types';
import { FaCloudBolt } from 'react-icons/fa6';
import { BomPageMenuItemIcon } from '../../assets/icons/react-icon-wrapper';
import { FeatureFlagKeys } from '../../feature-flags/feature-flags-types';
import { IconEyeOff } from '@tabler/icons-react';
import { IconFileAnalytics } from '@tabler/icons-react';
import { FaLowVision } from 'react-icons/fa';

export const PrimaryMenuItems: MenuItemConfig[] = [
  {
    href: AppPages.Dashboard,
    icon: InsertChart,
    label: 'Dashboard',
    subItems: [
      {
        href: AppPages.Dashboard,
        icon: InsertChart,
        label: 'Dashboard',
      },
      {
        href: AppPages.ExecutiveReport,
        icon: IconFileAnalytics as IconType,
        label: 'Executive Report',
        featureFlagKey: FeatureFlagKeys.executiveReportPage,
        badgeName: 'Beta',
      },
    ],
  },
  {
    href: AppPages.Applications,
    icon: TableRows,
    label: 'Applications',
    subItems: [
      {
        href: AppPages.Applications,
        icon: TableRows,
        label: 'Active Applications',
      },
      {
        href: AppPages.IrrelevantApps,
        icon: LuListX,
        label: 'Irrelevant Applications',
      },
    ],
  },
  {
    href: AppPages.ActiveIssues,
    icon: Campaign,
    label: 'Issues',
    subItems: [
      {
        icon: Campaign,
        label: 'Active Issues',
        href: AppPages.ActiveIssues,
      },
      {
        icon: BsListCheck,
        label: 'Resolved Issues',
        href: AppPages.ResolvedIssues,
        featureFlagKey: 'reslovedIssues',
        badgeName: 'Beta',
      },
      {
        icon: IconEyeOff as IconType,
        label: AppPagesDisplayName['removed-issues'],
        href: AppPages.RemovedIssues,
        featureFlagKey: 'reslovedIssues',
        badgeName: 'Beta',
      },
    ],
  },
  {
    href: AppPages.PipelineSummary,
    icon: FaInfinity,
    label: 'Pipeline',
    subItems: [
      {
        href: AppPages.PipelineSummary,
        icon: FaInfinity,
        label: 'Pipeline Summary',
      },
      {
        href: AppPages.PipelineIssues,
        icon: PiListDashesFill,
        label: 'Pipeline Issues',
      },
    ],
  },
  {
    href: AppPages.Connectors,
    icon: Apps,
    label: 'Connectors',
  },
  {
    href: AppPages.Policies,
    icon: Policy,
    label: 'Policies',
  },
  {
    href: AppPages.Exclusions,
    icon: ExclusionsIcon,
    label: 'Exclusions',
  },

  {
    icon: BomPageMenuItemIcon,
    label: 'BOM',
    href: AppPages.Bom,
    subItems: [
      {
        href: AppPages.Bom,
        icon: BomPageMenuItemIcon,
        label: 'BOM Overview',
      },
      {
        href: AppPages.Sbom,
        icon: AccountTree,
        label: 'SBOM',
      },
      {
        href: AppPages.ApiInventory,
        icon: IconApiApp as IconType,
        label: 'API BOM',
      },
      {
        href: AppPages.SaasBom,
        icon: FaCloudBolt,
        label: 'SaaS BOM',
      },
      {
        href: AppPages.Artifacts,
        icon: GiGlowingArtifact,
        label: 'Artifact BOM',
      },
      {
        href: AppPages.CloudBom,
        icon: FaCloud,
        label: 'Cloud BOM',
        featureFlagKey: FeatureFlagKeys.enableCBOM,
      },
    ],
  },
  {
    href: AppPages.OSCAR,
    icon: OscarIcon,
    label: 'OSC&R',
  },
  {
    href: AppPages.WorkflowManagement,
    icon: TiFlowChildren,
    label: 'Workflow',
    subItems: [
      {
        href: AppPages.WorkflowManagement,
        icon: TiFlowChildren,
        label: 'Workflow',
      },
      {
        href: AppPages.WorkflowExecutionLogs,
        icon: MdPlaylistPlay,
        label: 'Workflow Execution Logs',
      },
    ],
  },
];

export const secondaryMenuItems: MenuItemConfig[] = [
  {
    icon: AiOutlineLineChart,
    label: 'Scan History',
    href: AppPages.ScanHistory,
    isDevelopment: true,
  },
  {
    icon: RxActivityLog,
    label: 'Audit Logs',
    href: AppPages.AuditLogs,
  },
  {
    icon: MdOutlineGroups,
    label: AppPagesDisplayName.members,
    href: AppPages.Members,
  },
  {
    icon: Settings,
    label: 'Settings',
    href: AppPages.Settings,
    subItems: [
      {
        icon: VscOrganization,
        label: 'Organization Settings',
        href: AppPages.Settings + `?tab=${TabQueryParam.OrgSettings}`,
      },
      {
        icon: SiQuicklook,
        label: 'Usability Settings',
        href: AppPages.Settings + `?tab=${TabQueryParam.Usability}`,
      },
      {
        icon: BiScan,
        label: 'Scan Settings',
        href: AppPages.Settings + `?tab=${TabQueryParam.ScanSettings}`,
      },
      {
        icon: TableRows,
        label: 'Applications Settings',
        href: AppPages.Settings + `?tab=${TabQueryParam.ApplicationsSettings}`,
      },
      {
        icon: MdOutlineNotificationsActive,
        label: 'Notification Settings',
        href: AppPages.Settings + `?tab=${TabQueryParam.NotificationSettings}`,
      },
      {
        icon: MdLogin,
        label: 'Login Settings',
        href: AppPages.Settings + `?tab=${TabQueryParam.LoginSettings}`,
      },
      {
        icon: MdOutlineVpnKey,
        label: 'API Key Settings',
        href: AppPages.Settings + `?tab=${TabQueryParam.ApiKeySettings}`,
      },
      {
        icon: GiSecretBook,
        label: 'Secrets Settings',
        href: AppPages.Settings + `?tab=${TabQueryParam.Secrets}`,
      },

      {
        icon: FaInfinity,
        label: 'Pipeline Settings',
        featureFlagKey: FeatureFlagKeys.enableDefaultPipelineSettings,
        href: AppPages.Settings + `?tab=${TabQueryParam.PipelineSettings}`,
      },

      {
        icon: FaLowVision,
        label: 'View Settings',
        href: AppPages.Settings + `?tab=${TabQueryParam.ViewSettings}`,
        featureFlagKey: FeatureFlagKeys.viewPreferencesEnabled,
      },
    ],
  },
];

export const PageIcons = {
  [AppPages.SaasBom]: FaCloudBolt,
  [AppPages.Dashboard]: InsertChart,
  [AppPages.Applications]: TableRows,
  [AppPages.IrrelevantApps]: LuListX,
  [AppPages.ActiveIssues]: Campaign,
  [AppPages.ResolvedIssues]: BsListCheck,
  [AppPages.RemovedIssues]: IconEyeOff,
  [AppPages.PipelineSummary]: FaInfinity,
  [AppPages.PipelineIssues]: PiListDashesFill,
  [AppPages.Connectors]: Apps,
  [AppPages.Policies]: Policy,
  [AppPages.Exclusions]: ExclusionsIcon,
  [AppPages.Sbom]: AccountTree,
  [AppPages.Artifacts]: GiGlowingArtifact,
  [AppPages.ApiInventory]: IconApiApp,
  [AppPages.OSCAR]: OscarIcon,
  [AppPages.WorkflowManagement]: TiFlowChildren,
  [AppPages.WorkflowExecutionLogs]: MdPlaylistPlay,
  [AppPages.ScanHistory]: AiOutlineLineChart,
  [AppPages.AuditLogs]: RxActivityLog,
  [AppPages.Members]: MdOutlineGroups,
  [AppPages.Settings]: Settings,
  [AppPages.Settings + `?tab=${TabQueryParam.OrgSettings}`]: VscOrganization,
  [AppPages.Settings + `?tab=${TabQueryParam.Usability}`]: SiQuicklook,
  [AppPages.Settings + `?tab=${TabQueryParam.ScanSettings}`]: BiScan,
  [AppPages.Settings + `?tab=${TabQueryParam.ApplicationsSettings}`]: TableRows,
  [AppPages.Settings + `?tab=${TabQueryParam.NotificationSettings}`]:
    MdOutlineNotificationsActive,
  [AppPages.Settings + `?tab=${TabQueryParam.LoginSettings}`]: MdLogin,
  [AppPages.Settings + `?tab=${TabQueryParam.ApiKeySettings}`]: MdOutlineVpnKey,
  [AppPages.Settings + `?tab=${TabQueryParam.Secrets}`]: GiSecretBook,
  [AppPages.ExecutiveReport]: IconFileAnalytics,
  [AppPages.CloudBom]: FaCloud,
};

export interface MenuItemConfig {
  href: string;
  icon: IconType;
  label: string;
  featureFlagKey?: string;
  featureFlagBadgeKey?: string;
  badgeName?: string;
  subItems?: MenuItemConfig[];
  isHidden?: boolean;
  isDevelopment?: boolean;
}

export const PageIconsByDisplayName = PrimaryMenuItems.reduce((acc, item) => {
  acc[item.label] = item.icon;
  if (item.subItems) {
    item.subItems.forEach(subItem => {
      acc[subItem.label] = subItem.icon;
    });
  }
  return acc;
}, {});
