import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';

export const CustomTooltip = styled(
  ({
    className,
    maxHeight,
    maxWidth,
    ...props
  }: TooltipProps & {
    className?: string;
    maxHeight?: number;
    maxWidth?: number;
    disabled?: boolean;
  }) => {
    if (props.disabled) return props.children;
    return <Tooltip {...props} classes={{ popper: className }} />;
  },
)(({ theme, maxHeight, maxWidth }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: maxWidth || 300,
    maxHeight: maxHeight || 350,
    overflow: 'auto',
  },
}));
