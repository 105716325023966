import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { Nullable } from 'ox-common-types';
import mutation from './set-is-onboarding-required.gql';

const setIsOnboardingRequired = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (
      isOnboardingRequired: boolean,
    ): Promise<Nullable<boolean>> => {
      try {
        const results = await client.mutate<SetIsOnboardingRequiredResponse>({
          mutation,
          variables: { isOnboardingRequired },
        });

        if (!results.data) {
          throw new Error('failed to set isOnboardingRequired');
        }

        return results.data.success;
      } catch (error) {
        logger.error(
          `Got an error while setting isOnboardingRequired flag: ${error}`,
        );
        return null;
      }
    },
  };
};

interface SetIsOnboardingRequiredResponse {
  success: boolean;
}

export default setIsOnboardingRequired;
