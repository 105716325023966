import { ReactComponent as MailchimpIcon } from '../assets/mailchimp.svg';
import { ReactComponent as WorkfrontIcon } from '../assets/workfront.svg';
import { ReactComponent as AxosoftIcon } from '../assets/axosoft.svg';
import { ReactComponent as SnowflakeIcon } from '../assets/snowflake.svg';
import { ReactComponent as TeamGanttIcon } from '../assets/team-gantt.svg';
import { SiMongodb } from 'react-icons/si';
import { ReactComponent as AzureSqlIcon } from '../assets/azure-sql.svg';
import { SiDatadog } from 'react-icons/si';
import { ReactComponent as FreshbooksIcon } from '../assets/freshbooks.svg';
import { ReactComponent as CodecovIcon } from '../assets/codecov.svg';
import { ReactComponent as MondayIcon } from '../../assets/icons/monday-action-icon.svg';
import { ReactComponent as AirtableIcon } from '../assets/airtable.svg';
import { ReactComponent as AlgoliaIcon } from '../assets/algolia.svg';
import { ReactComponent as GrafanaIcon } from '../assets/grafana.svg';
import { ReactComponent as AirbrakeIcon } from '../assets/airbrake.svg';
import { ReactComponent as BitgoIcon } from '../assets/bitgo.svg';
import { ReactComponent as CrashlyticsIcon } from '../assets/crashlytics.svg';
import { ReactComponent as EquifaxIcon } from '../assets/equifax.svg';
import { ReactComponent as FronteggIcon } from '../assets/frontegg.svg';
import { ReactComponent as GoogleMapsIcon } from '../assets/googlemaps.svg';
import { SiConsul } from 'react-icons/si';
import { ReactComponent as RecaptchaIcon } from '../assets/recaptcha.svg';
import { ReactComponent as NewRelicIcon } from '../assets/newrelic.svg';
import { ReactComponent as SendgridIcon } from '../assets/sendgrid.svg';
import { ReactComponent as XigniteIcon } from '../assets/xi.svg';
import { ReactComponent as AsanaIcon } from '../assets/asana.svg';
import { ReactComponent as RapidApiIcon } from '../assets/rapidapi.svg';
import { ReactComponent as TwocaptchaIcon } from '../assets/twocaptcha.svg';
import { FaSquarespace } from 'react-icons/fa';
import { ReactComponent as KanbanizeIcon } from '../assets/kanbanize.svg';
import { ReactComponent as ShippoIcon } from '../assets/shippo.svg';
import { ReactComponent as PlaidIcon } from '../assets/plaid.svg';
import { ReactComponent as LogzioIcon } from '../assets/logzio.svg';
import { SiPostman } from 'react-icons/si';
import { SiOpenai } from 'react-icons/si';
import { SiFavro } from 'react-icons/si';
import { ReactComponent as ClarizenIcon } from '../assets/clarizen.svg';
import { ReactComponent as WrikeIcon } from '../assets/wrike.svg';
import { ReactComponent as HiveIcon } from '../assets/hive.svg';
import { ReactComponent as BitrixIcon } from '../assets/bitrix24.svg';
import { ReactComponent as RedboothIcon } from '../assets/redbooth.svg';
import { ReactComponent as ClickUpIcon } from '../assets/click-up.svg';
import { ReactComponent as BasecampIcon } from '../assets/basecamp.svg';
import { ReactComponent as PlannerIcon } from '../assets/planner.svg';
import { ReactComponent as SmartsheetIcon } from '../assets/smartsheet.svg';
import { ReactComponent as PromotexterIcon } from '../assets/promotexter.svg';
import { ReactComponent as TeamworkIcon } from '../assets/teamwork.svg';
import { ReactComponent as PodioIcon } from '../assets/podio.svg';
import { ReactComponent as ZohoProjectsIcon } from '../assets/zoho-projects.svg';
import { ReactComponent as ZohoIcon } from '../assets/zoho.svg';
import { ReactComponent as MeisterTaskIcon } from '../assets/meister-task.svg';
import { SiTodoist } from 'react-icons/si';
import { ReactComponent as SplunkIcon } from '../assets/splunk.svg';
import { ReactComponent as LiquidPlannerIcon } from '../assets/liquid-planner.svg';
import { RiNotionFill } from 'react-icons/ri';
import { SiZendesk } from 'react-icons/si';
import { GrHeroku } from 'react-icons/gr';
import { FaLinkedin } from 'react-icons/fa';
import { FaHubspot } from 'react-icons/fa';
import { SiMailgun } from 'react-icons/si';
import { SiOpsgenie } from 'react-icons/si';
import { FaYoutube } from 'react-icons/fa';
import { AiOutlineAlibaba } from 'react-icons/ai';
import { FaDigitalOcean } from 'react-icons/fa';
import { SiDatabricks } from 'react-icons/si';
import { FaXTwitter } from 'react-icons/fa6';
import { FaYandexInternational } from 'react-icons/fa';
import { SiPlanetscale } from 'react-icons/si';
import { FaIntercom } from 'react-icons/fa';
import { FaFigma } from 'react-icons/fa';
import { SiDocusign } from 'react-icons/si';
import { FaDropbox } from 'react-icons/fa';
import { FaDiscord } from 'react-icons/fa';
import { ReactComponent as SlackIcon } from '../assets/slack.svg';
import { RiTrelloFill } from 'react-icons/ri';
import { ReactComponent as JiraIcon } from '../assets/jira.svg';
import { ReactComponent as HuggingFaceIcon } from '../assets/hugging-face.svg';
import { ReactComponent as AzureOpenAIIcon } from '../assets/azure-ai.svg';
import { ReactComponent as AwsSagemakerIcon } from '../assets/aws-sagemaker.svg';
import { ReactComponent as GCPVertexAIIcon } from '../assets/gcp-vertex-ai.svg';
import { ReactComponent as SapHanaIcon } from '../assets/sap-hana.svg';
import { ReactComponent as AlteryxIcon } from '../assets/alteryx.svg';
import { ReactComponent as UnionAIIcon } from '../assets/union-ai.svg';
import { ReactComponent as DialogflowIcon } from '../assets/dialogflow.svg';
import { ReactComponent as ClearMLIcon } from '../assets/clearml.svg';
import { ReactComponent as DeepInfraIcon } from '../assets/deep-infra.svg';
import { ReactComponent as AnyscaleIcon } from '../assets/anyscale.svg';
import { ReactComponent as CnvrgIOIcon } from '../assets/cnvrg.svg';
import { ReactComponent as IBMWatsonxIcon } from '../assets/ibm-watsonx.svg';
import { ReactComponent as SalesforceEinsteinAI } from '../assets/salesforce-einstein-ai.svg';
import { ReactComponent as NvidiaOmniverse } from '../assets/nvidia-omniverse.svg';
import { ReactComponent as NvidiaAIPlatform } from '../assets/nvidia-ai-platform.svg';
import { ReactComponent as HyperstackIcon } from '../assets/hyperstack.svg';
import { ReactComponent as RunpodioIcon } from '../assets/runpod.svg';
import { ReactComponent as VastaiIcon } from '../assets/vast.svg';
import { ReactComponent as AporiaIcon } from '../assets/aporia.svg';
import { ReactComponent as SpeechmaticsIcon } from '../assets/speechmatics.svg';
import { ReactComponent as KluIcon } from '../assets/klu.svg';
import { ReactComponent as OblivusIcon } from '../assets/oblivus.svg';
import { ReactComponent as SisenseIcon } from '../assets/sisense.svg';
import { ReactComponent as PostmarkIcon } from '../assets/postmark.svg';
import { FaTelegram } from 'react-icons/fa';
import { ReactComponent as GoogleDriveIcon } from '../assets/google-drive.svg';
import { ReactComponent as GoogleSheetsIcon } from '../assets/google-sheets.svg';

export const SaasBomIcons = {
  'MongoDB Atlas': { Icon: SiMongodb },
  'Azure SQL DB': { Icon: AzureSqlIcon },
  SnowflakeDB: { Icon: SnowflakeIcon },
  Jira: { Icon: JiraIcon },
  Slack: { Icon: SlackIcon },
  Datadog: { Icon: SiDatadog, iconColor: '#5C2D90' },
  Codecov: { Icon: CodecovIcon },
  Monday: { Icon: MondayIcon },
  Airtable: { Icon: AirtableIcon },
  Algolia: { Icon: AlgoliaIcon },
  Notion: { Icon: RiNotionFill },
  Zendesk: { Icon: SiZendesk, iconColor: '#00373E' },
  Grafana: { Icon: GrafanaIcon },
  Heroku: { Icon: GrHeroku, iconColor: '#430098' },
  Hubspot: { Icon: FaHubspot, iconColor: '#FF5A48' },
  Mailchimp: { Icon: MailchimpIcon },
  Mailgun: { Icon: SiMailgun, iconColor: '#C02428' },
  Airbrake: { Icon: AirbrakeIcon },
  Bitgo: { Icon: BitgoIcon },
  Consul: { Icon: SiConsul, iconColor: '#E03875' },
  Crashlytics: { Icon: CrashlyticsIcon },
  Equifax: { Icon: EquifaxIcon },
  Frontegg: { Icon: FronteggIcon },
  GoogleMaps: { Icon: GoogleMapsIcon },
  Kanbanize: { Icon: KanbanizeIcon },
  NewRelic: { Icon: NewRelicIcon },
  OpenAI: { Icon: SiOpenai, iconColor: '#74AA9C' },
  OpsGenie: { Icon: SiOpsgenie, iconColor: '#2684FF' },
  ReCaptcha: { Icon: RecaptchaIcon },
  Sendgrid: { Icon: SendgridIcon },
  Xignite: { Icon: XigniteIcon },
  Youtube: { Icon: FaYoutube, iconColor: '#FF0000' },
  Alibaba: { Icon: AiOutlineAlibaba },
  Asana: { Icon: AsanaIcon },
  DigitalOcean: { Icon: FaDigitalOcean, iconColor: '#0080FF' },
  Databricks: { Icon: SiDatabricks, iconColor: '#FF3621' },
  Twitter: { Icon: FaXTwitter },
  Yandex: { Icon: FaYandexInternational, iconColor: '#E6311F' },
  Twocaptcha: { Icon: TwocaptchaIcon },
  Squarespace: { Icon: FaSquarespace },
  Shippo: { Icon: ShippoIcon },
  RapidApi: { Icon: RapidApiIcon },
  Postman: { Icon: SiPostman, iconColor: '#FF6C37' },
  Planetscale: { Icon: SiPlanetscale },
  Plaid: { Icon: PlaidIcon },
  Promotexter: { Icon: PromotexterIcon },
  Linkedin: { Icon: FaLinkedin },
  Intercom: { Icon: FaIntercom },
  Freshbooks: { Icon: FreshbooksIcon },
  Figma: { Icon: FaFigma },
  Docusign: { Icon: SiDocusign },
  Dropbox: { Icon: FaDropbox, iconColor: '#007EE5' },
  Discord: { Icon: FaDiscord, iconColor: '#5865F2' },
  'Logz.io': { Icon: LogzioIcon },
  Splunk: { Icon: SplunkIcon },
  Trello: { Icon: RiTrelloFill, iconColor: '#0052CC' },
  Wrike: { Icon: WrikeIcon },
  ClickUp: { Icon: ClickUpIcon },
  Basecamp: { Icon: BasecampIcon },
  'Microsoft Planner': { Icon: PlannerIcon },
  Smartsheet: { Icon: SmartsheetIcon },
  Workfront: { Icon: WorkfrontIcon },
  Teamwork: { Icon: TeamworkIcon },
  Podio: { Icon: PodioIcon },
  'Zoho Projects': { Icon: ZohoProjectsIcon },
  Zoho: { Icon: ZohoIcon },
  MeisterTask: { Icon: MeisterTaskIcon },
  Favro: { Icon: SiFavro, iconColor: '#512DA8' },
  'Todoist Business': { Icon: SiTodoist, iconColor: '#F82D21' },
  LiquidPlanner: { Icon: LiquidPlannerIcon },
  Axosoft: { Icon: AxosoftIcon },
  TeamGantt: { Icon: TeamGanttIcon },
  Clarizen: { Icon: ClarizenIcon },
  Hive: { Icon: HiveIcon },
  Redbooth: { Icon: RedboothIcon },
  Bitrix24: { Icon: BitrixIcon },
  'Hugging Face': { Icon: HuggingFaceIcon },
  'Azure OpenAI': { Icon: AzureOpenAIIcon },
  'AWS SageMaker': { Icon: AwsSagemakerIcon },
  'GCP Vertex AI': { Icon: GCPVertexAIIcon },
  'SAP HANA': { Icon: SapHanaIcon },
  Alteryx: { Icon: AlteryxIcon },
  'Union.ai': { Icon: UnionAIIcon },
  Dialogflow: { Icon: DialogflowIcon },
  ClearML: { Icon: ClearMLIcon },
  'Deep Infra': { Icon: DeepInfraIcon },
  Anyscale: { Icon: AnyscaleIcon },
  'cnvrg.io': { Icon: CnvrgIOIcon },
  'IBM Watsonx': { Icon: IBMWatsonxIcon },
  'Salesforce Einstein AI': { Icon: SalesforceEinsteinAI },
  'NVIDIA Omniverse': { Icon: NvidiaAIPlatform },
  'NVIDIA AI Platform': { Icon: NvidiaOmniverse },
  Hyperstack: { Icon: HyperstackIcon },
  RunPod: { Icon: RunpodioIcon },
  'Vast.ai': { Icon: VastaiIcon },
  Aporia: { Icon: AporiaIcon },
  Speechmatics: { Icon: SpeechmaticsIcon },
  Klu: { Icon: KluIcon },
  Oblivus: { Icon: OblivusIcon },
  Telegram: { Icon: FaTelegram, iconColor: '#229ED9' },
  Sisense: { Icon: SisenseIcon },
  Postmark: { Icon: PostmarkIcon },
  'Google Drive': { Icon: GoogleDriveIcon },
  'Google Sheets': { Icon: GoogleSheetsIcon },
};
