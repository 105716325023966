import { orgManagementClient } from 'api-clients';
import changeOrganizationName from './change-org-name/change-org-name';
import createOrganization from './create/create-organization';
import getOrganizations from './get-organizations/get-organizations';
import getOrganizationInfo, {
  getOrganizationUsersInfo,
} from './get-org-info/get-organization-info';
import setIsOnboardingRequired from './set-is-onboarding-required/set-is-onboarding-required';

const organizationService = {
  createOrganization: createOrganization(orgManagementClient),
  getOrganizations: getOrganizations(orgManagementClient),
  changeOrganizationName: changeOrganizationName(orgManagementClient),
  getOrganizationInfo: getOrganizationInfo(orgManagementClient),
  getOrganizationUsersInfo: getOrganizationUsersInfo(orgManagementClient),
  setIsOnboardingRequired: setIsOnboardingRequired(orgManagementClient),
};

export default organizationService;
