export enum CloudTypes {
  AWS = 'AWS',
  GCP = 'GCP',
  Azure = 'Azure',
}

export enum CloudSubType {
  Lambda = 'Lambda',
  CloudFunction = 'Cloud Function',
  ECS = 'ECS',
  EC2 = 'EC2',
  EKS = 'EKS',
  AKS = 'AKS',
  ComputeEngine = 'Compute Engine',
  Generic = 'Generic',
  GKE = 'GKE',
}
