import { FC, useMemo } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import {
  CveSeverityFactor,
  ScaVulnerability,
} from '../../../../apps/web/src/issues/common/types/issues-types';
import React from 'react';
import { CveSeverityIconMap, CveSeverityOrder } from './icon-utils';

const CveSeverityFactors: FC<
  React.PropsWithChildren<CveSeverityFactorsProps>
> = ({ row }) => {
  const factors = useMemo(
    () =>
      row.cveSeverityFactor?.length
        ? [...row.cveSeverityFactor].sort(factorsCompare)
        : [],
    [row.cveSeverityFactor],
  );
  return (
    <Box display='flex' flexDirection='row' gap={1}>
      {factors?.map((factor, index) => {
        const Icon = CveSeverityIconMap.get(factor.sfName);

        return (
          Icon && (
            <Tooltip
              key={index}
              arrow
              title={
                <Typography color='text.primary' variant='caption'>
                  <b>{factor.sfName}</b> <br />
                  {factor.sfDescription}
                </Typography>
              }>
              <span style={{ display: 'inline-flex' }}>
                <Icon width={24} height={24} />
              </span>
            </Tooltip>
          )
        );
      })}
    </Box>
  );
};

const factorsCompare = (a: CveSeverityFactor, b: CveSeverityFactor) =>
  CveSeverityOrder[a.sfName] - CveSeverityOrder[b.sfName];

export interface CveSeverityFactorsProps {
  row: ScaVulnerability;
}

export default CveSeverityFactors;
