import { AttackPathMetadataCloudType } from '../attack-path-types';

export const extractNameAndEmail = str => {
  // Split the string into words
  const words = str.split(' ');

  let name, email;

  if (words.length > 1) {
    // The last element is the email, the rest is the name
    email = words.pop();
    name = words.join(' ');
  } else if (words.length === 1) {
    // Determine if the single word is an email or a name
    const isEmail = words[0].includes('@');
    email = isEmail ? words[0] : '';
    name = isEmail ? '' : words[0];
  } else {
    // Both name and email are empty
    name = '';
    email = '';
  }

  return { name, email };
};

const categoriesNames = {
  iac: 'IaC',
  scm: 'SCM',
  crm: 'CRM',
  ai: 'AI',
};

export const resolveSaasCategory = purpose => {
  const category = categoriesNames[purpose];
  let spacedStr = purpose.replace(/([A-Z])/g, ' $1').trim();
  return category || spacedStr.charAt(0).toUpperCase() + spacedStr.slice(1);
};

export const getAttackPathCloudIdentifier = (
  cloudType: AttackPathMetadataCloudType,
): string => {
  const lowercasedCloudType = cloudType?.toLocaleLowerCase();
  switch (lowercasedCloudType) {
    case AttackPathMetadataCloudType.AWS:
      return 'Account ID';
    case AttackPathMetadataCloudType.Azure:
      return 'Subscription ID';
    case AttackPathMetadataCloudType.GCP:
      return 'Project ID';
    default:
      return 'Account ID';
  }
};

export const getAttackPathLinkIdentifier = (
  cloudType: AttackPathMetadataCloudType,
): string => {
  const lowercasedCloudType = cloudType?.toLocaleLowerCase();
  switch (lowercasedCloudType) {
    case AttackPathMetadataCloudType.AWS:
      return 'Link to Account';
    case AttackPathMetadataCloudType.Azure:
      return 'Link to Subscription';
    case AttackPathMetadataCloudType.GCP:
      return 'Link to Project';
    default:
      return 'Link to Account';
  }
};
