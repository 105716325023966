import { IconButton, Tooltip } from '@mui/material';
import { IconLink } from '@tabler/icons-react';
import { FC, useCallback } from 'react';
import { openSnackbar } from 'snackbar-utils';

const CopyLinkOption: FC<React.PropsWithChildren<CopyOptionsProps>> = ({
  copyLink,
}) => {
  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(copyLink.link);
    openSnackbar(
      'Link copied!',
      {
        variant: 'success',
        autoHideDuration: 2000,
      },
      {
        hideCloseIcon: true,
        maxwidth: 10,
      },
    );
  }, [copyLink]);
  return (
    <Tooltip title={copyLink.label} arrow placement='top'>
      <IconButton size='small' onClick={copyToClipboard} color='primary'>
        <IconLink size='1.2rem' />
      </IconButton>
    </Tooltip>
  );
};

export interface CopyOptionsProps {
  copyLink: { link: string; label: string };
}

export default CopyLinkOption;
