import { orgManagementClient } from 'api-clients';
import addUserRoles from './add-user-roles/add-user-roles';
import createInvitation from './create-invitation/create-invitation';
import createMultipleInvitations from './create-multiple-invitations/create-multiple-invitations';
import deleteMember from './delete-member/delete-member';
import deleteUserRoles from './delete-user-roles/delete-user-roles';
import getOrgMembersSuggestions from './get-active-members-suggestions/get-active-members-suggestions';
import getMembersScreen from './get-members-screen/get-members-screen';
import resendInvitation from './resend-invitation/resend-invitation';
import revokeInvitation from './revoke-invitation/revoke-invitation';
import updateMember from './update-member/update-member';
import updateRequestAccess from './update-request-access/update-request-access';
import getInvitationLink from './get-invitation-link/get-invitation-link';

const membersService = {
  getMembersScreen: getMembersScreen(orgManagementClient),
  createInvitation: createInvitation(orgManagementClient),
  createMultipleInvitations: createMultipleInvitations(orgManagementClient),
  revokeInvitation: revokeInvitation(orgManagementClient),
  addUserRoles: addUserRoles(orgManagementClient),
  deleteUserRoles: deleteUserRoles(orgManagementClient),
  deleteMember: deleteMember(orgManagementClient),
  getActiveMembers: getOrgMembersSuggestions(orgManagementClient),
  resendInvitation: resendInvitation(orgManagementClient),
  updateMember: updateMember(orgManagementClient),
  updateRequestAccess: updateRequestAccess(orgManagementClient),
  getInvitationLink: getInvitationLink(orgManagementClient),
};

export default membersService;
