import { Nullable } from 'ox-common-types';
import { FilterItems, FilterTypeToFilter } from 'ox-filter-utils';
import { createStore, derive } from 'store-utils';
import {
  ConditionalFilter,
  filterEmptyItems,
  filterItemsBySearchValues,
  SearchInput,
} from '../../../../../utilities/ox-filter-utils/src/filters-utils';

export const initialApplicationsFiltersStoreValues = {
  isLoadingFilters: false,
  filterBy: {},
  totalFilteredApps: 0,
  sliderBusinessPriority: [0, 100],
  searchValues: {},
  lazyFilters: null,
  appsSearchValues: [],
  selectedConditionalFilters: [],
};

const baseStore = createStore<AppFilterStoreState>(
  initialApplicationsFiltersStoreValues,
  'Applications Filters Store',
);

const ApplicationsFiltersStore = derive(
  {
    numberOfFilters: get => {
      const { filterBy } = get(baseStore);
      return (
        Object.keys(filterBy).reduce(
          (acc, key) =>
            key === FilterTypeToFilter.BusinessPriority
              ? acc + 1
              : acc + filterBy[key].length,
          0,
        ) || 0
      );
    },
    filterItems: (get): Nullable<FilterItems> => {
      const { searchValues, lazyFilters, filterBy } = get(baseStore);
      const filteredSearchValues = filterEmptyItems(searchValues);
      const isSearchValuesExist = Object.keys(filteredSearchValues).length > 0;

      if (isSearchValuesExist && lazyFilters) {
        return filterItemsBySearchValues(
          lazyFilters,
          filteredSearchValues,
          filterBy,
        );
      } else {
        return lazyFilters;
      }
    },
  },
  { proxy: baseStore },
);

export interface AppFilterStoreState {
  isLoadingFilters: boolean;
  filterBy: {};
  totalFilteredApps: number;
  sliderBusinessPriority: number[];
  searchValues: {};
  lazyFilters: Nullable<FilterItems>;
  appsSearchValues: SearchInput[];
  selectedConditionalFilters: ConditionalFilter[];
}

export default ApplicationsFiltersStore;
