import { downloadFileFromURL } from 'common-utils';
import { closeSnackbar, openSnackbar } from 'snackbar-utils';
import { snapshot } from 'valtio';
import { fileDownloadStore } from '../store/file-download-store';
import { isRemoteSite } from 'env-utils';
import { TokenStore } from 'common-auth';
import * as url from 'url';

export function handleDownloadProgressPercentage(total: number) {
  const { downloadProgress } = snapshot(fileDownloadStore);
  const duration = total >= 2000 ? 75 : 100;
  const newProgressPercentage = (100 / total) * duration + downloadProgress;
  if (newProgressPercentage < 90) {
    fileDownloadStore.downloadProgress = newProgressPercentage;
  } else if (downloadProgress < 99) {
    if (downloadProgress < 90) {
      fileDownloadStore.downloadProgress = 90;
    } else {
      fileDownloadStore.downloadProgress = downloadProgress + 1;
    }
  }
}

export function handleFileReadyAction(
  response: {
    downloadLink?: string | undefined;
    isFileReady: boolean;
    error?: boolean | undefined;
  },
  snackbarMsg: string,
) {
  fileDownloadStore.downloadProgress = 100;
  if (isRemoteSite()) {
    const { authToken } = snapshot(TokenStore);
    const urlWithAccessToken = url.parse(response.downloadLink!, true);
    urlWithAccessToken.query.access_token = authToken;
    downloadFileFromURL(url.format(urlWithAccessToken));
  } else {
    downloadFileFromURL(response.downloadLink!);
  }

  openSnackbar(snackbarMsg, {
    variant: 'success',
  });
}

export function handleDownloadPollerDone(snackbarKey: string) {
  fileDownloadStore.downloadProgress = 0;
  fileDownloadStore.issuesCsvDownloadInProgress = false;
  closeSnackbar(snackbarKey);
}
