import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import {
  GetVulnerabiltiesInput,
  VulnerabilitiesResponse,
} from '../../types/issues-types';
import query from './get-vulnerabilities.gql';

const fetchScaVulnerabilities = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (input: GetVulnerabiltiesInput) => {
      const results = await client.query<{
        getScaVulnerabilities: VulnerabilitiesResponse;
      }>({
        query,
        variables: { getScaVulnerabilitiesInput: input },
      });

      if (!results.data) return null;
      return results.data.getScaVulnerabilities;
    },
  };
};

export default fetchScaVulnerabilities;
