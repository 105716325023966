import { RepoTypes, TicketProvider } from 'ox-common-types';
import { CiCdFields, Issue } from '../types/issues-types';
import { RawIssue } from '../types/raw-issue-type';

export const convertIssue = (issue: RawIssue, index: number): Issue => {
  if (!issue.createdAt && !issue.created) {
    throw new Error(
      `Failed to convert issue id ${issue.issueId}, no createAt or created properties.`,
    );
  }

  let resolvedIssueDate;
  try {
    resolvedIssueDate =
      issue.resolvedIssueDate && new Date(issue.resolvedIssueDate);
  } catch {}

  let jobTriggeredDate;
  try {
    jobTriggeredDate =
      issue.cicdFields?.jobTriggeredAt &&
      new Date(issue.cicdFields?.jobTriggeredAt);
  } catch {}
  return {
    id: issue.id,
    correlatedRegistry: issue.correlatedRegistry,
    index,
    fix: issue.fixes,
    issueId: issue.issueId,
    severity: issue.severity,
    mainTitle: issue.mainTitle,
    secondTitle: issue.secondTitle,
    category: issue.category.name,
    categoryId: issue.category.categoryId,
    description: issue.description,
    fixes: issue.fixes,
    policyId: issue.policy?.id,
    repo: {
      type: issue.app.type as RepoTypes,
      name: issue.app.name,
    },
    owner: issue.owners,
    createdDate: new Date(issue.createdAt),
    scanCreatedDate: new Date(issue.created),
    occurrences: issue.occurrences,
    exclusions: issue.exclusions,
    app: issue.app,
    fixIssue: issue.fixIssue,
    isPRAvailable: issue.isPRAvailable,
    isFixAvailable: issue.isFixAvailable,
    prDeatils: issue.prDeatils || null,
    autoFix: issue.autoFix,
    comment: issue.comment,
    originalSeverity: issue.originalSeverity,
    overrideSeverity: issue.overrideSeverity,
    slackNotification: issue.slackNotification || [],
    tickets: issue.tickets,
    jiraTickets:
      issue.tickets
        ?.filter(jiraTicket => jiraTicket.provider === TicketProvider.Jira)
        .map(jiraTicket => {
          const { link } = jiraTicket;
          return {
            ...jiraTicket,
            key: link.split('/')[4],
          };
        }) || [],
    resolvedIssueDate,
    excludedByAlert: issue.excludedByAlert,
    cicdFields: {
      ...(issue.cicdFields || {}),
      jobTriggeredAt: jobTriggeredDate,
    } as CiCdFields,
    isGPTFixAvailable: issue.isGPTFixAvailable,
    gptInfo: issue.gptInfo,
    isFalsePositive: issue.isFalsePositive,
    falsePositiveComment: issue.falsePositiveComment,
    extraInfo: issue.extraInfo,
    policy: issue.policy,
    exclusionCategory: issue.exclusionCategory,
    exclusionId: issue.exclusionId,
    ruleId: issue.ruleId,
    originalToolSeverity: issue.originalToolSeverity,
    disappearedDate: issue.disappearedDate,
    issueDetailsHeaders: issue.issueDetailsHeaders,
    messages: issue.messages || [],
    resolvedReason: issue.resolvedReason,
    resolvedReasonDetails: issue.resolvedReasonDetails,
    disappearedReason: issue.disappearedReason,
    disappearedReasonDetails: issue.disappearedReasonDetails,
    isCanceledFalsePositive: issue.isCanceledFalsePositive,
    cancelFalsePositiveComment: issue.cancelFalsePositiveComment,
  };
};
