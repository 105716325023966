import { AppPages } from 'app-navigator';
import { lazy } from 'react';
import { RouteConfig } from './routing-types';

export const privateRoutes: RouteConfig[] = [
  {
    path: AppPages.Dashboard,
    element: lazy(() => import('../../pages/dashboard/DashboardPage')),
  },
  {
    path: AppPages.ExecutiveReport,
    element: lazy(
      () => import('../../pages/executive-report/ExecutiveReportPage'),
    ),
  },
  {
    path: AppPages.Onboarding,
    element: lazy(
      () => import('../../../onboarding/containers/OnboardingContainer'),
    ),
  },
  {
    path: AppPages.Applications,
    element: lazy(() => import('../../pages/applications/ApplicationsPage')),
  },
  {
    path: AppPages.ActiveIssues,
    element: lazy(() => import('../../pages/issues/IssuesPage')),
  },
  {
    path: AppPages.ResolvedIssues,
    element: lazy(
      () => import('../../pages/resolved-issues/ResolvedIssuesPage'),
    ),
  },
  {
    path: AppPages.RemovedIssues,
    element: lazy(() => import('../../pages/removed-issues/RemovedIssuesPage')),
  },
  {
    path: AppPages.PipelineIssues,
    element: lazy(
      () => import('../../pages/pipeline-issues/PipelineIssuesPage'),
    ),
  },
  {
    path: AppPages.Settings,
    element: lazy(() => import('../../pages/settings/SettingsPage')),
  },
  {
    path: AppPages.Connectors,
    element: lazy(() => import('../../pages/connectors/ConnectorsPage')),
  },
  {
    path: AppPages.Policies,
    element: lazy(() => import('../../pages/policies/PoliciesPage')),
  },
  {
    path: AppPages.Members,
    element: lazy(() => import('../../pages/members/MembersPage')),
  },
  {
    path: AppPages.AuditLogs,
    element: lazy(() => import('../../pages/audit-logs/AuditLogsPage')),
  },
  {
    path: AppPages.IDPConfigure,
    element: lazy(() => import('../../pages/configure-idp/ConfigureIDPPage')),
  },
  {
    path: AppPages.GitHubAppInstallationConfigure,
    element: lazy(
      () => import('../../pages/configure-github-app/ConfigureGitHubAppPage'),
    ),
  },
  {
    path: AppPages.GitHubAppROInstallationConfigure,
    element: lazy(
      () => import('../../pages/configure-github-app/ConfigureGitHubAppPage'),
    ),
  },
  {
    path: AppPages.BitbucketAppInstallationConfigure,
    element: lazy(
      () =>
        import('../../pages/configure-bitbucket-app/ConfigureBitbucketAppPage'),
    ),
  },
  {
    path: AppPages.IrrelevantApps,
    element: lazy(
      () => import('../../pages/irrelevant-apps/IrrelevantAppsPage'),
    ),
  },
  {
    path: AppPages.Exclusions,
    element: lazy(() => import('../../pages/exclusions/ExclusionsPage')),
  },
  {
    path: AppPages.Sbom,
    element: lazy(() => import('../../pages/sbom/SbomPage')),
  },
  {
    path: AppPages.Artifacts,
    element: lazy(() => import('../../pages/artifacts/ArtifactsPage')),
  },
  {
    path: AppPages.ScanHistory,
    element: lazy(() => import('../../pages/scans-history/ScanHistoryPage')),
  },
  {
    path: AppPages.PipelineSummary,
    element: lazy(
      () => import('../../pages/pipeline-summary/PipelineSummaryPage'),
    ),
  },
  {
    path: AppPages.ReleaseNotes,
    element: lazy(() => import('../../pages/release-notes/ReleaseNotesPage')),
  },
  {
    path: AppPages.OSCAR,
    element: lazy(() => import('../../pages/Oscar/OscarPage')),
  },
  {
    path: AppPages.WhatsNew,
    element: lazy(() => import('../../pages/whats-new/WhatsNewPage')),
  },
  {
    path: AppPages.WorkflowManagement,
    element: lazy(
      () => import('../../pages/policy-workflow/WorkflowManagementPage'),
    ),
  },
  {
    path: AppPages.PolicyWorkflow,
    element: lazy(
      () => import('../../pages/policy-workflow/PolicyWorkflowPage'),
    ),
  },
  {
    path: AppPages.WorkflowExecutionLogs,
    element: lazy(
      () => import('../../pages/policy-workflow/ExecutionLogsPage'),
    ),
  },
  {
    path: AppPages.ApiInventory,
    element: lazy(() => import('../../pages/api-inventory/ApiInventoryPage')),
  },
  {
    path: AppPages.AppSecHero,
    element: lazy(() => import('../../pages/app-sec-hero/AppSecHeroPage')),
    showOnlyInDev: true,
  },
  {
    path: AppPages.SaasBom,
    element: lazy(() => import('../../pages/saas-bom/SaasBomPage')),
  },

  {
    path: AppPages.Bom,
    element: lazy(() => import('../../pages/bom/BomPage')),
  },
  {
    path: AppPages.CloudBom,
    element: lazy(() => import('../../pages/cloud-bom/CloudBomPage')),
  },
];
