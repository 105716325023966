import { DateUnit, IDateRange } from 'ox-common-types';
import { createStore, derive } from 'store-utils';
import ScanStore from '../../new-scan/store/scan-store';

const baseStore = createStore<TopBarState>(
  {
    isLoadingTopBarActions: false,
  },
  'Top Bar Store',
);

const TopBarStore = derive(
  {
    dateRange: get => {
      const { isDemoEnabled } = get(ScanStore);

      if (isDemoEnabled) {
        // if demo is enabled, we show 3 months of data as default - request from  sales / marketing: https://oxsecurity.atlassian.net/browse/OXDEV-31656
        return {
          limit: 3,
          unit: DateUnit.Month,
        };
      } else {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const limit = urlParams.get('limit');
        const unit = urlParams.get('unit');
        return {
          limit: limit ? parseInt(limit) : 1,
          unit: unit || DateUnit.Week,
        } as IDateRange;
      }
    },
  },
  { proxy: baseStore },
);

export interface TopBarState {
  isLoadingTopBarActions: boolean;
}

export default TopBarStore;
