import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import query from './get-invitation-link.gql';
import { Nullable } from 'ox-common-types';

const getInvitationLink = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (
      invitationId: string,
    ): Promise<Nullable<GetInvitationLinkResponse>> => {
      try {
        const input: GetInvitationLinkInput = { invitationId };

        const results = await client.query<{
          getInvitationLink: GetInvitationLinkResponse;
        }>({
          query,
          variables: { input },
        });

        if (!results || !results.data) {
          return null;
        }

        return results.data.getInvitationLink;
      } catch {
        return null;
      }
    },
  };
};

export interface GetInvitationLinkInput {
  invitationId: string;
}

export interface GetInvitationLinkResponse {
  invitationURL: string;
}

export default getInvitationLink;
