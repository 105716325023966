import {
  ConditionalFilter,
  ConditionalFiltersCondition,
} from 'ox-filter-utils';

const addMissingPropertiesToConditionalFilters = (
  conditionalFilters: ConditionalFilter[],
) => {
  if (!conditionalFilters || conditionalFilters.length === 0) {
    return [];
  }
  return conditionalFilters.map(filter => {
    const _filter = { ...filter };
    if (!_filter.hasOwnProperty('greaterThan')) {
      _filter.greaterThan = null;
    }
    if (!_filter.hasOwnProperty('lessThan')) {
      _filter.lessThan = null;
    }
    if (!_filter.hasOwnProperty('values')) {
      _filter.values = [];
    }
    _filter.values = [..._filter.values];

    return _filter;
  });
};

const generateConditionalFilters = (params: {
  existingConditionalFilters: ConditionalFilter[];
  type: string;
  value: string;
  condition: ConditionalFiltersCondition;
}) => {
  const { type, value, condition, existingConditionalFilters } = params;
  const conditionalFilters = addMissingPropertiesToConditionalFilters(
    existingConditionalFilters,
  );
  const oppositeCondition =
    condition === ConditionalFiltersCondition.OR
      ? ConditionalFiltersCondition.NOT
      : ConditionalFiltersCondition.OR;
  const existingItem = conditionalFilters.find(
    item => item.fieldName === type && item.condition === condition,
  );
  const oppositeItem = conditionalFilters.find(
    item => item.fieldName === type && item.condition === oppositeCondition,
  );

  if (!existingItem) {
    conditionalFilters.push({
      condition,
      fieldName: type,
      values: [value],
    });
  } else {
    const valueExists = existingItem?.values.includes(value);
    existingItem.values = valueExists
      ? existingItem.values.filter(v => v !== value)
      : [...existingItem.values, value];
  }
  if (oppositeItem) {
    oppositeItem.values = oppositeItem.values.filter(v => v !== value);
  }
  const conditionalFiltersNoEmptyItems = conditionalFilters.filter(item => {
    if (item.condition === ConditionalFiltersCondition.BETWEEN) {
      return true;
    } else {
      return item.values.length > 0;
    }
  });
  return conditionalFiltersNoEmptyItems;
};

const translateFiltersToConditionalFilters = (filters: {}) => {
  const conditionalFilters = Object.keys(filters).map(key => {
    const values = filters[key];
    return {
      condition: ConditionalFiltersCondition.OR,
      fieldName: key,
      values: values,
    };
  });
  return conditionalFilters;
};

export const conditionalFiltersUtils = {
  addMissingPropertiesToConditionalFilters,
  generateConditionalFilters,
  translateFiltersToConditionalFilters,
};
