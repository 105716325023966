import {
  Box,
  IconButton,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { SeverityType } from 'ox-common-types';
import { FilterTypeToFilter } from 'ox-filter-utils';
import { FC, useMemo } from 'react';
import { SeverityColor } from 'severity-utils';
import { capitalizeFirstLetter } from 'string-utils';
import { makeStyles } from 'tss-react/mui';
import { IssueDetails, SeverityDiffStatus } from '../../types/issues-types';
import { mappingSeveritisChangeReason } from '../../utils/issue-utils';
import ActionsOptionsTooltip from '../ActionsOptions/ActionsOptionsTooltip';
import SeverityChangeFactor from './SeverityChangeFactor';
import { snapshot, useSnapshot } from 'valtio';
import SettingsStore from '../../../../settings/stores/settings-store';
import {
  SettingsName,
  SettingsSubType,
} from '../../../../settings/types/settings-types';
import CorrelatedIssue from './CorrelatedIssue';

import { isDevelopment } from 'env-utils';
import IssueCardSeverityDiff from './IssueCardSeverityDiff';
import { InfoIcon } from 'ox-react-components';
import SeverityFactorsBreakdown from 'ox-react-components/src/SeverityFactorsBreakdown/SeverityFactorsBreakdown';
import { useOxFlags } from '../../../../feature-flags/actions/feature-flags-actions';
import { CveSeverity } from 'ox-react-components/src/Vulnerabilities/icon-utils';
import IssueCardCategory from './IssueCardCategory';
import IssueCardSeverity from './IssueCardSeverity';
import { setSelectedTab } from '../IssueDrawer/IssueDrawerTabs/store-actions/issue-drawer-tabs-store-actions';
import { setParamsToUrl } from 'app-navigator';
import { FaExternalLinkAlt } from 'react-icons/fa';
import IssuesDrawerTabsStore from '../IssueDrawer/IssueDrawerTabs/store/issue-drawer-tabs-store';

const IssueCardHeader: FC<IssueCardSeverityProps> = ({
  oxSeverity,
  originalSeverity,
  severityBreakdown,
  severity,
  overrideSeverity,
  overrideSeverityReason,
  filters,
  correlatedIssueId,
  category,
  categoryId,
  appType,
  registryType,
  previousSeverity,
  severityFactorsDiff,
  isCVERelated,
  importantSeverityBreakdown,
  severityChangedReason,
  highestOXCVESeverity,
}) => {
  const { classes } = useStyles();
  const { issueSeverityBreakdownEnabled } = useOxFlags();
  const showOriginalSeverity = !originalSeverity
    ? oxSeverity
    : originalSeverity;
  const severitiesByType = mappingSeveritisChangeReason(severityBreakdown);

  const { orgSettingsMapByName } = useSnapshot(SettingsStore);
  const isEnableAdvancedOptionsToolTip = orgSettingsMapByName
    .find(s => s.name === SettingsName.Tooltips)
    ?.settings.find(
      s => s.settingsSubType === SettingsSubType.AdvancedOptionsToolTip,
    )?.enabled;

  const theme = useTheme();
  const CategoryInfo = <IssueCardCategory category={category} />;

  const factors = useMemo(
    () =>
      severityChangedReason
        ?.filter(
          item =>
            Math.abs(item.changeNumber) >= 1 &&
            !Object.values(CveSeverity).find(sev => sev === item.shortName),
        )
        .map(item => ({
          sfName: item.shortName,
          sfChangedNumber: item.changeNumber,
        })),

    [severityChangedReason],
  );
  const severityBlock = (
    <Box display='flex' flexDirection='row' gap={0.5} alignItems='center'>
      <Typography variant='h1' color='text.secondary'>
        Severity
      </Typography>
      <IssueCardSeverity
        severity={severity}
        isEnableAdvancedOptionsToolTip={isEnableAdvancedOptionsToolTip}
        filters={filters}
      />

      {issueSeverityBreakdownEnabled ? (
        <InfoIcon
          placement='right'
          marginX={0.5}
          customColor={theme.palette.primary.main}
          title={
            <SeverityFactorsBreakdown
              severityTitle={
                isCVERelated ? 'Highest CVSS CVE Severity' : 'Tool Severity'
              }
              secondSeverityTitle={
                isCVERelated ? 'Highest OX CVE Severity*' : undefined
              }
              overrideSeverity={overrideSeverity}
              overrideSeverityReason={overrideSeverityReason}
              secondSeverity={isCVERelated ? highestOXCVESeverity : undefined}
              finalSeverityTitle='Final Issue Severity'
              oxSeverity={severity}
              originalSeverity={showOriginalSeverity}
              importantSeverityBreakdown={importantSeverityBreakdown}
              factors={factors}
              bottomDescription={
                isCVERelated && (
                  <span>
                    *OX recalculates CVE severity using KEV, EPSS, AI exploit
                    simulations, and other factors. The highest recalculated CVE
                    severity serves as the base, with additional adjustments
                    applied at the issue level. See the Vulnerabilities{' '}
                    <IconButton
                      sx={{
                        display: 'inline-flex',
                        mx: 0.5,
                        mb: 0.5,
                        width: '0.5rem',
                        height: '0.5rem',
                        p: 0,
                      }}
                      onClick={() => {
                        const { issueDrawerTabs } = snapshot(
                          IssuesDrawerTabsStore,
                        );
                        const tabToOpen =
                          issueDrawerTabs.find(
                            tab =>
                              tab.label === 'Vulnerabilities' ||
                              tab.label === 'Reachable Vulnerabilities',
                          )?.label || '';

                        // TODO: after removing reachable / npot reachable tabs remove this code
                        if (tabToOpen) {
                          setSelectedTab(tabToOpen);
                        }

                        setParamsToUrl({ tab: tabToOpen });
                      }}>
                      <FaExternalLinkAlt style={{ color: '#585cfc' }} />
                    </IconButton>
                    tab for severity calculations at the CVE level.
                  </span>
                )
              }
            />
          }
        />
      ) : null}
    </Box>
  );

  const originalSeverityBlock = (
    <Typography variant='body2' color='text.secondary'>
      Tool Severity&nbsp;
      <SeverityColor severity={showOriginalSeverity}>
        {color => (
          <span
            style={{
              color,
              textTransform: 'uppercase',
              fontWeight: 600,
            }}>
            {showOriginalSeverity}
          </span>
        )}
      </SeverityColor>
    </Typography>
  );

  const previousSeverityBlock =
    isDevelopment() &&
    previousSeverity?.severity &&
    previousSeverity?.severityChangedDate ? (
      <Tooltip
        title={
          severityFactorsDiff && (
            <IssueCardSeverityDiff
              date={previousSeverity.severityChangedDate}
              diff={severityFactorsDiff}
            />
          )
        }>
        <Typography variant='body2' color='text.secondary'>
          Previous Severity&nbsp;
          <SeverityColor severity={previousSeverity.severity}>
            {color => (
              <span
                style={{
                  color,
                  textTransform: 'uppercase',
                  fontWeight: 600,
                }}>
                {previousSeverity.severity}
              </span>
            )}
          </SeverityColor>
        </Typography>
      </Tooltip>
    ) : null;
  return (
    <div className={classes.issueCardSeverity}>
      <div className={classes.firstColumn}>
        <div className={classes.textContainer}>
          {isEnableAdvancedOptionsToolTip ? (
            <>
              <ActionsOptionsTooltip
                value={categoryId?.toString()!}
                displayValue={category!}
                withFilter
                withName
                filters={filters[FilterTypeToFilter.Category]}
                filterType={FilterTypeToFilter.Category}>
                {CategoryInfo}
              </ActionsOptionsTooltip>
              {severityBlock}
              <ActionsOptionsTooltip
                value={capitalizeFirstLetter(showOriginalSeverity)}
                withFilter
                withName
                filters={filters[FilterTypeToFilter.OriginalSeverity]}
                filterType={FilterTypeToFilter.OriginalSeverity}>
                {originalSeverityBlock}
              </ActionsOptionsTooltip>
              {previousSeverityBlock}
            </>
          ) : (
            <>
              {CategoryInfo}
              {severityBlock}
              {originalSeverityBlock}
              {previousSeverityBlock}
            </>
          )}

          {overrideSeverity && (
            <Typography color='text.primary' fontSize='0.65rem'>
              {`* User changed from OX Severity ${oxSeverity}`}
            </Typography>
          )}
        </div>
        <div style={{ paddingTop: 8 }}>
          {correlatedIssueId && category && (
            <CorrelatedIssue
              correlatedIssueId={correlatedIssueId}
              category={category}
              appType={appType}
              registryType={registryType}
            />
          )}
        </div>
      </div>

      <SeverityChangeFactor
        severitiesByType={severitiesByType}
        filters={filters}
        isEnableAdvancedOptionsToolTip={isEnableAdvancedOptionsToolTip}
      />
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  issueCardSeverity: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
    alignItems: 'flex-start',
  },
  firstColumn: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: theme.spacing(1),
    paddingTop: 8,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: theme.spacing(3),
  },
}));

export interface IssueCardSeverityProps {
  oxSeverity: SeverityType;
  originalSeverity: SeverityType;
  severityBreakdown: IssueDetails['severityBreakdown'];
  severityChangedReason: IssueDetails['severityChangedReason'];
  severity: string;
  overrideSeverity: boolean;
  filters: {};
  correlatedIssueId?: string;
  category?: string;
  appType?: string;
  registryType?: string;
  categoryId?: string;
  previousSeverity?: {
    severity: string;
    severityChangedDate: string;
  };
  severityFactorsDiff?: {
    shortName: string;
    change: number;
    status: SeverityDiffStatus;
  }[];
  isCVERelated?: boolean;
  importantSeverityBreakdown: string[];
  highestOXCVESeverity: string;
  overrideSeverityReason: string;
}

export default IssueCardHeader;
