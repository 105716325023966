import { messagingClient } from 'api-clients';
import getRecipients from './get-recipients/get-recipients';
import sendMessage from './send-message/send-message';

const messagingService = {
  sendMessage: sendMessage(messagingClient),
  getRecipients: getRecipients(messagingClient),
};

export default messagingService;
