import { AppEvents } from 'common-events';
import { loadSingleApplication } from '../actions/single-application-actions';
import { applicationsStoreActions } from '../stores/applications-store-actions';

export const setupApplicationsListeners = () => {
  document.addEventListener(AppEvents.Scan.ScanStart, () => {
    applicationsStoreActions.clearStore();
  });

  document.addEventListener('application-owners: update', () => {
    loadSingleApplication({ cache: false });
  });
};
