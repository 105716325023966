import {
  PermissionScope,
  PermissionTypes,
} from '@oxappsec/ox-unified-permissions';
import { SiSlack } from 'react-icons/si';
import { snapshot } from 'valtio';
import ConnectorsStore from '../../../connectors/stores/connectors-store';
import { messagingActions } from '../../../messaging-module/actions/messaging-actions';
import {
  MessagingAction,
  MessagingVendorTypes,
  messagingVendorIcon,
} from '../../../messaging-module/types/messaging-types';
import { setMenuIssueId } from '../../active-issues/actions/active-issues-actions';
import { openConnectorsNotConnectedModal } from '../../active-issues/connectors-not-connected-modal/actions/connector-not-connected-modal-actions';
import { ConnectorsNotConnectedModalTypes } from '../../active-issues/connectors-not-connected-modal/types/connector-not-connected-modal-types';
import { setMenuIssueIdPipeline } from '../../pipeline-issues/actions/pipeline-issues-actions';
import {
  BulkIssuesAction,
  IssueAction,
  IssuePages,
} from '../types/issues-types';
import { openSendSlackMessageDialog } from '../actions/slack-actions';

const messagingPermissions = [
  {
    permissionScope: PermissionScope.Edit,
    permissionType: PermissionTypes.IssuesSlack, // TODO Messaging: change to messaging permission
  },
];

export const getMessagingActions = (
  page: IssuePages,
  issuesIds: string[],
  preActionFunction?: (issuesIds: string[]) => Promise<void>,
): IssueAction[] => {
  const { connectedMessagingConnectors } = snapshot(ConnectorsStore);
  const atLeastOneMessagingConnectorConnected = Object.values(
    connectedMessagingConnectors,
  ).some(c => c.isConfigured);

  const handleOpenSendMessageModal = async (
    messagingVendor: MessagingVendorTypes,
  ) => {
    if (!atLeastOneMessagingConnectorConnected) {
      openConnectorsNotConnectedModal(
        ConnectorsNotConnectedModalTypes[messagingVendor],
      );
      return;
    }

    if (messagingVendor === MessagingVendorTypes.Slack) {
      openSendSlackMessageDialog(page);
    } else {
      messagingActions.openSendMessageModal({
        page,
        selectedMessagingVendor: messagingVendor as MessagingVendorTypes,
        selectedIssuesIds: issuesIds,
        selectedAction: MessagingAction.SingleIssueMessage,
      });
    }
    setMenuIssueId(null);
    setMenuIssueIdPipeline(null);
  };

  if (!atLeastOneMessagingConnectorConnected) {
    return [
      {
        actionId: 'messaging-actions',
        title: 'Messaging Actions',
        tooltipText: 'No Messaging Connector is connected',
        icon: SiSlack,
        onClick: () => {
          openConnectorsNotConnectedModal(
            ConnectorsNotConnectedModalTypes.Slack,
          );
        },
        permissions: messagingPermissions,
        highlight: true,
        relevantIssuePages: [
          IssuePages.CurrentIssues,
          IssuePages.PipelineIssues,
        ],
      },
    ];
  }

  const getRelevantIssuePages = (connectorName: string) => {
    if (connectorName === ConnectorsNotConnectedModalTypes.Slack) {
      return [IssuePages.CurrentIssues, IssuePages.PipelineIssues];
    } else {
      // TODO messaging: add pipeline issues when we have it in the messaging service
      return [IssuePages.CurrentIssues];
    }
  };

  return Object.keys(connectedMessagingConnectors).reduce(
    (acc: IssueAction[], connectorName) => {
      if (connectedMessagingConnectors[connectorName].isConfigured) {
        const isConnectorInvalid =
          connectedMessagingConnectors[connectorName]?.isConnectorInvalid;
        acc.push({
          actionId: `send-${connectorName}-message`,
          title: `Send message to ${connectorName}`,
          tooltipText: isConnectorInvalid
            ? `Access to ${connectorName} is expired or revoked`
            : `Send message to ${connectorName}`,
          disabled: isConnectorInvalid,
          icon: messagingVendorIcon[connectorName],
          onClick: () =>
            handleOpenSendMessageModal(connectorName as MessagingVendorTypes),
          permissions: messagingPermissions,
          highlight: connectorName === ConnectorsNotConnectedModalTypes.Slack,
          relevantIssuePages: getRelevantIssuePages(connectorName),
        });
      }

      return acc;
    },
    [],
  ) as IssueAction[];
};

export const getBulkMessagingActions = ({
  selectedIssuesIds,
  page,
}: {
  selectedIssuesIds: string[];
  page: IssuePages;
}): BulkIssuesAction[] => {
  const selectedCount = selectedIssuesIds.length;
  const { connectedMessagingConnectors } = snapshot(ConnectorsStore);
  const atLeastOneMessagingConnectorConnected = Object.values(
    connectedMessagingConnectors,
  ).some(c => c.isConfigured);
  if (!atLeastOneMessagingConnectorConnected) {
    return [
      {
        disabled: selectedCount === 0,
        text:
          selectedCount === 0 ? 'No issues selected' : 'Slack is not connected',
        icon: SiSlack,
        onClick: () => {
          openConnectorsNotConnectedModal(
            ConnectorsNotConnectedModalTypes.Slack,
          );
        },
        permissions: messagingPermissions,
      },
    ];
  }

  const handleOpenSendBulkMessageModal = async (
    messagingVendor: MessagingVendorTypes,
  ) => {
    if (!atLeastOneMessagingConnectorConnected) {
      openConnectorsNotConnectedModal(
        ConnectorsNotConnectedModalTypes[messagingVendor],
      );
      return;
    }

    if (messagingVendor === MessagingVendorTypes.Slack) {
      openSendSlackMessageDialog(page);
    } else {
      messagingActions.openSendMessageModal({
        page,
        selectedMessagingVendor: messagingVendor as MessagingVendorTypes,
        selectedIssuesIds,
        selectedAction: MessagingAction.BulkIssuesMessage,
      });
    }
    setMenuIssueId(null);
    setMenuIssueIdPipeline(null);
  };

  const getBulkMessagingActionText = (connectorName: string) => {
    if (selectedCount === 0) {
      return 'No issues selected';
    }
    if (connectedMessagingConnectors[connectorName]?.isConnectorInvalid) {
      return `Access to ${connectorName} is expired or revoked`;
    }
    return `Send message to ${connectorName}`;
  };

  return Object.keys(connectedMessagingConnectors).reduce(
    (acc: BulkIssuesAction[], connectorName) => {
      if (connectorName === ConnectorsNotConnectedModalTypes.Slack) {
        // remove this when we have slack in messaging service
        return acc;
      }
      const isConnectorInvalid =
        connectedMessagingConnectors[connectorName]?.isConnectorInvalid;
      const isConfigured =
        connectedMessagingConnectors[connectorName]?.isConfigured;
      if (isConfigured) {
        acc.push({
          text: getBulkMessagingActionText(connectorName),
          disabled: selectedCount === 0 || isConnectorInvalid,
          icon: messagingVendorIcon[connectorName],
          onClick: () =>
            handleOpenSendBulkMessageModal(
              connectorName as MessagingVendorTypes,
            ),
          permissions: messagingPermissions,
        });
      }

      return acc;
    },
    [],
  );
};
