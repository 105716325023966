import { Button, Typography } from '@mui/material';
import {
  PermissionScope,
  PermissionTypes,
} from '@oxappsec/ox-unified-permissions';
import { FC, useState, useRef, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import withDisabledIfUnauthorized, {
  WithDisabledIfUnauthorizedProps,
} from '../../../../permissions/higher-order-components/withDisabledIfUnauthorized';

const IssueCardImpact: FC<React.PropsWithChildren<IssueCardImpactProps>> = ({
  children,
  maxHeight,
}) => {
  const { classes } = useStyles();

  const [showMore, setShowMore] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  useEffect(() => {
    if (contentRef.current && maxHeight) {
      setIsOverflowing(contentRef.current.scrollHeight > maxHeight + 10);
    }
  }, [children, maxHeight]);

  return (
    <div className={classes.issueCardImpact}>
      <div className={classes.header}>
        <Typography variant='body2' color='text.secondary'>
          Impact
        </Typography>
      </div>
      <Typography
        variant='body2'
        color='text.primary'
        mt={1}
        className={classes.issueCardImpactText}
        maxHeight={showMore ? undefined : maxHeight}
        component='div'
        ref={contentRef}>
        <div dangerouslySetInnerHTML={{ __html: children }} />
        {!showMore && isOverflowing && maxHeight && (
          <div className={classes.showMore} />
        )}
      </Typography>
      {isOverflowing && maxHeight && (
        <Button
          className={classes.button}
          onClick={handleShowMore}
          size='small'>
          {showMore ? 'Show less' : 'Show more'}
        </Button>
      )}
    </div>
  );
};

const useStyles = makeStyles()(theme => ({
  button: {
    width: 70,
    textTransform: 'none',
    p: 0,
    m: 0,
  },
  issueCardImpact: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '350px',
  },
  issueCardImpactText: {
    overflow: 'hidden',
    position: 'relative',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    minHeight: 40,
  },
  showMore: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '2rem',
  },
}));

export interface IssueCardImpactProps extends WithDisabledIfUnauthorizedProps {
  children: string;
  maxHeight?: number;
}

export default withDisabledIfUnauthorized(IssueCardImpact, {
  requiredPermissions: [
    {
      permissionScope: PermissionScope.Edit,
      permissionType: PermissionTypes.IssuesChatGPT,
    },
  ],
});
