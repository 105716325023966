import VulnerabilitiesTable from 'ox-react-components/src/Vulnerabilities/VulnerabilitiesTable';
import { FC, useEffect } from 'react';
import { useInfinityScroll } from 'react-utils';
import { useSnapshot } from 'valtio';
import IssuesStore from '../../../../../../../active-issues/stores/issues-store';
import {
  fetchVulnerabilities,
  searchVulnerabilities,
  sortVulnerabilities,
} from '../actions/vulnerabilities-action';
import VulnerabilitiesStore from '../stores/vulnerabilities-store';
import issuesService from '../../../../../../services';
import { resetVulnerabilities } from './store-actions';
import { GetVulnerabiltiesInput } from '../../../../../../types/issues-types';

const VulnerabilitiesTableContainer: FC = () => {
  const { selectedIssueId } = useSnapshot(IssuesStore);
  const { data, loading, contextOptions, orderDirection, orderField } =
    useSnapshot(VulnerabilitiesStore);
  const { onScroll } = useInfinityScroll({
    threshold: 0.9,
    load: () =>
      fetchVulnerabilities({
        selectedIssueId,
        getVulnerabilitiesFunction,
        update: false,
      }),
    loading,
  });
  const getVulnerabilitiesFunction = (input: GetVulnerabiltiesInput) =>
    issuesService.getVulnerabilities.execute(input);
  useEffect(() => {
    fetchVulnerabilities({
      selectedIssueId,
      getVulnerabilitiesFunction,
      update: true,
    });
    return resetVulnerabilities;
  }, [selectedIssueId]);

  return (
    <VulnerabilitiesTable
      rows={data}
      contextOptions={contextOptions}
      onScroll={onScroll}
      onSearch={(search, context) =>
        searchVulnerabilities({
          selectedIssueId,
          getVulnerabilitiesFunction,
          search,
          context,
        })
      }
      loading={loading}
      onSort={field =>
        sortVulnerabilities({
          selectedIssueId,
          getVulnerabilitiesFunction,
          field,
        })
      }
      orderField={orderField}
      orderDirection={orderDirection}
    />
  );
};

export default VulnerabilitiesTableContainer;
