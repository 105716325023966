import { CloudSubType, IconType, RepoTypes } from 'ox-common-types';
import { FaGithub } from 'react-icons/fa';
import { SiGerrit, SiJfrog } from 'react-icons/si';
import { snapshot } from 'valtio';
import { calcDateRange } from '../../app/store-actions/top-bar-store-actions';
import TopBarStore from '../../app/stores/top-bar-store';
import { ReactComponent as ACRIcon } from '../../assets/icons/acr-icon.svg';
import { ReactComponent as GcpArtifactIcon } from '../../assets/icons/artifact_registry.svg';
import { ReactComponent as AWSCodeBuildIcon } from '../../assets/icons/aws-codebuild.svg';
import { ReactComponent as AwsEC2 } from '../../assets/icons/aws-ec2.svg';
import { ReactComponent as AwsEcr } from '../../assets/icons/aws-ecr.svg';
import { ReactComponent as AwsCloudEcs } from '../../assets/icons/aws-ecs.svg';
import { ReactComponent as EKSIcon } from '../../assets/icons/aws-eks.svg';
import { ReactComponent as AzureIcon } from '../../assets/icons/azure-icon.svg';
import {
  ReactComponent as BitbucketIcon,
  ReactComponent as BitbucketstashIcon,
} from '../../assets/icons/bitBucket-icon.svg';
import { ReactComponent as GcpContainerIcon } from '../../assets/icons/container_registry.svg';
import { ReactComponent as ContainerIcon } from '../../assets/icons/containers-icon.svg';
import { ReactComponent as DockerHubIcon } from '../../assets/icons/dockerhub-icon.svg';
import { ReactComponent as GcpCloudFunctions } from '../../assets/icons/gcp-cloudFunctions.svg';
import { ReactComponent as GcpComputeEngine } from '../../assets/icons/gcp-computeEngine.svg';
import { ReactComponent as GcpLogo } from '../../assets/icons/gcp-logo.svg';
import { ReactComponent as GenericIcon } from '../../assets/icons/generic-icon.svg';
import { ReactComponent as GithubActions } from '../../assets/icons/github-actions-icon.svg';
import { ReactComponent as GitlabIcon } from '../../assets/icons/gitlab-icon.svg';
import { ReactComponent as JenkinsIcon } from '../../assets/icons/jenkins.svg';
import { ReactComponent as LambdaIcon } from '../../assets/icons/lambda-icon.svg';
import { ReactComponent as NPMIcon } from '../../assets/icons/npm-logo.svg';
import { ReactComponent as OrcaIcon } from '../../assets/icons/orca-logo.svg';
import { ReactComponent as ServerlessIcon } from '../../assets/icons/serverless.svg';
import { ReactComponent as WizIcon } from '../../assets/icons/wiz-logo.svg';
import { ReactComponent as YarnIcon } from '../../assets/icons/yarn-logo.svg';
import { ReactComponent as PrismaIcon } from '../../assets/icons/prisma-logo.svg';

import { repoIconsMap, resolveAzureIcon, AzureIcons } from 'common-icons';

import ArtifactsStore from '../stores/artifacts-store';
import {
  ArtifactInfoTypes,
  CICDSystems,
  CloudArtifactData,
  CloudThirdPartyTypes,
  CodeArtifactSCMTypes,
  RegistryTypes,
} from '../types/artifacts-type';
import { GlobalDataViewSelector } from '../../app/components/DataViewSelector/global-data-view-selector-store';
import {
  OxCategory,
  getCategoriesByOrder,
} from '@oxappsec/ox-consolidated-categories';
import ConnectorsStore from '../../connectors/stores/connectors-store';

const artifactsIconsMap = new Map<ArtifactInfoTypes, IconType>([
  [ArtifactInfoTypes.DockerContainer, ContainerIcon],
  [ArtifactInfoTypes.Container, ContainerIcon],
  [ArtifactInfoTypes.Lambda, LambdaIcon],
  [ArtifactInfoTypes.NPM, NPMIcon],
  [ArtifactInfoTypes.Yarn, YarnIcon],
  [ArtifactInfoTypes.Serverless, ServerlessIcon],
]);

export const getArtifactIconType = (type: ArtifactInfoTypes) => {
  return artifactsIconsMap.get(type);
};

const scmIconsMap = new Map<CodeArtifactSCMTypes, IconType | undefined>([
  [CodeArtifactSCMTypes.GitLab, GitlabIcon],
  [CodeArtifactSCMTypes.Github, FaGithub],
  [CodeArtifactSCMTypes.Bitbucketstash, BitbucketstashIcon],
  [CodeArtifactSCMTypes.Azure, AzureIcon],
  [CodeArtifactSCMTypes.AWSCodeBuild, AWSCodeBuildIcon],
  [CodeArtifactSCMTypes.Bitbucket, BitbucketIcon],
  [CodeArtifactSCMTypes.AzureContainerRegistry, ACRIcon],

  [
    CodeArtifactSCMTypes.AzureReposTFVC,
    repoIconsMap.get(RepoTypes.AzureReposTFVC),
  ],
  [CodeArtifactSCMTypes.AzureReposGit, repoIconsMap.get(RepoTypes.AzureRepos)],
  [CodeArtifactSCMTypes.AzureTFS, repoIconsMap.get(RepoTypes.AzureTFS)],
  [CodeArtifactSCMTypes.Gerrit, SiGerrit],
]);

export const getScmIconType = (scmType: CodeArtifactSCMTypes) => {
  return scmIconsMap.get(scmType);
};

const cicdSystemIconsMap = new Map<CICDSystems, IconType>([
  [CICDSystems.GithubActions, GithubActions],
  [CICDSystems.Gitlab, GitlabIcon],
  [CICDSystems.Jenkins, JenkinsIcon],
  [CICDSystems.Github, FaGithub],
]);

export const getCicdSystemIconType = (cicdSystemType: CICDSystems) => {
  return cicdSystemIconsMap.get(cicdSystemType);
};

//remove this and use the same icons as app-flows at generateCategoryToolTipIcon
const cloudSubTypeIconMap = new Map<CloudSubType, IconType>([
  [CloudSubType.CloudFunction, GcpCloudFunctions],
  [CloudSubType.ComputeEngine, GcpComputeEngine],
  [CloudSubType.EC2, AwsEC2],
  [CloudSubType.ECS, AwsCloudEcs],
  [CloudSubType.Lambda, LambdaIcon],
  [CloudSubType.EKS, EKSIcon],
  [CloudSubType.Generic, GenericIcon],
  [CloudSubType.AKS, resolveAzureIcon(AzureIcons.aks) as IconType],
]);

export const getCloudSubTypeIconType = (cloudSubType: CloudSubType) => {
  return cloudSubTypeIconMap.get(cloudSubType);
};

const registryTypeIconMap = new Map<RegistryTypes, IconType>([
  [RegistryTypes.AmazonECR, AwsEcr],
  [RegistryTypes.Lambda, LambdaIcon],
  [RegistryTypes.ECR, AwsEcr],
  [RegistryTypes.GCP, GcpLogo],
  [RegistryTypes.JFrog, SiJfrog],
  [RegistryTypes.Generic, GenericIcon],
  [RegistryTypes.GoogleArtifactRegistry, GcpArtifactIcon],
  [RegistryTypes.GoogleArtifactContainer, GcpContainerIcon],
  [RegistryTypes.GitlabRegistry, GitlabIcon],
  [RegistryTypes.Container, GcpLogo],
  [RegistryTypes.DockerHub, DockerHubIcon],
  [RegistryTypes.JFrogArtifactory, SiJfrog],
  [RegistryTypes.ACR, ACRIcon],
]);

const cloudThirdPartyTypeMap = new Map<CloudThirdPartyTypes, IconType>([
  [CloudThirdPartyTypes.Wiz, WizIcon],
  [CloudThirdPartyTypes.Orca, OrcaIcon],
  [CloudThirdPartyTypes.Prisma, PrismaIcon], //bc
  [CloudThirdPartyTypes.PrismaCspm, PrismaIcon],
  [CloudThirdPartyTypes.PrismaArtifacts, PrismaIcon],
]);

export const getRegistryIconType = (registryType: RegistryTypes) => {
  return registryTypeIconMap.get(registryType);
};

export const getCloudIconType = (cloudThirdPartyType: CloudThirdPartyTypes) => {
  return cloudThirdPartyTypeMap.get(cloudThirdPartyType);
};

export const getCorrelatedIconType = (
  iconType: RegistryTypes | CloudThirdPartyTypes,
) => {
  return (
    registryTypeIconMap.get(iconType as RegistryTypes) ||
    cloudThirdPartyTypeMap.get(iconType as CloudThirdPartyTypes) ||
    undefined
  );
};

export const sumSeverities = (severities: number[]) => {
  let total = 0;
  for (let i = 0; i < severities.length; i++) {
    total += severities[i];
  }
  return total;
};

export const isNullObj = (
  obj: CloudArtifactData[],
  propertyToCheck: string,
) => {
  obj.forEach(item => {
    if (item[propertyToCheck] === null) {
      return true;
    }
  });
  return false;
};

export const getArtifactsRequestParams = (limit: number) => {
  const { offset, filterArtifactsBy, topFiltersArtifactsBy, hashSearch } =
    snapshot(ArtifactsStore);
  const { dateRange } = snapshot(TopBarStore);
  const [from, to] = calcDateRange(dateRange);
  const { selectedAppOwnersEmails, selectedTagIds } = snapshot(
    GlobalDataViewSelector,
  );

  return {
    offset: offset,
    limit,
    artifactTopFilters: topFiltersArtifactsBy,
    filters: filterArtifactsBy,
    owners: selectedAppOwnersEmails,
    tagIds: selectedTagIds,
    search: hashSearch,
    dateRange: {
      from,
      to,
    },
  };
};

export const getPbomCategories = () => {
  const { connectedDastConnectors } = snapshot(ConnectorsStore);
  let categories: OxCategory[] = getCategoriesByOrder().filter(
    category => category.isPbomCategory,
  );
  if (connectedDastConnectors.length === 0) {
    categories = categories.filter(category => category.id !== 31);
  }

  return categories.map(item => ({
    key: item.key,
    value: item.displayName,
  }));
};
