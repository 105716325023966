import { ReactComponent as AwsAccessAnalyzer } from '../../assets/aws-accessanalyzer.svg';
import { ReactComponent as AwsAcm } from '../../assets/aws-acm.svg';
import { ReactComponent as ApiGateway } from '../../assets/aws-api-gateway.svg';
import { ReactComponent as AwsAutoScaling } from '../../assets/aws-autoscaling.svg';
import {
  ReactComponent as AwsCloudFormation,
  ReactComponent as CloudFormationIcon,
} from '../../assets/aws-cloudformation.svg';
import { ReactComponent as AwsCloudTrail } from '../../assets/aws-cloudtrail.svg';
import { ReactComponent as AwsCloudWatch } from '../../assets/aws-cloudwatch.svg';
import { ReactComponent as AwsConfigureService } from '../../assets/aws-configureservice.svg';
import {
  ReactComponent as AwsDynamoDB,
  ReactComponent as DynamoDBIcon,
} from '../../assets/aws-dynamodb.svg';
import { ReactComponent as AwsEC2 } from '../../assets/aws-ec2.svg';
import { ReactComponent as AwsEcr } from '../../assets/aws-ecr.svg';
import { ReactComponent as AwsCloudEcs } from '../../assets/aws-ecs.svg';
import { ReactComponent as AwsEFS } from '../../assets/aws-efs.svg';
import { ReactComponent as AwsEKS } from '../../assets/aws-eks.svg';
import { ReactComponent as AwsElb } from '../../assets/aws-elb.svg';
import { ReactComponent as AwsEs } from '../../assets/aws-es.svg';
import { ReactComponent as AwsFargate } from '../../assets/aws-fargate.svg';
import { ReactComponent as AwsGlacier } from '../../assets/aws-glacier.svg';
import { ReactComponent as AwsGlue } from '../../assets/aws-glue.svg';
import { ReactComponent as AwsGuardDuty } from '../../assets/aws-guardduty.svg';
import { ReactComponent as IamIcon } from '../../assets/aws-iam.svg';
import { ReactComponent as AwsKMS } from '../../assets/aws-kms.svg';
import { ReactComponent as AwsLambdaIcon } from '../../assets/aws-lambda.svg';
import { ReactComponent as AwsMacie } from '../../assets/aws-macie.svg';
import { ReactComponent as AwsRds } from '../../assets/aws-rds.svg';
import { ReactComponent as AwsRedShift } from '../../assets/aws-redshift.svg';
import { ReactComponent as AwsRoute53 } from '../../assets/aws-route53.svg';
import { ReactComponent as S3Icon } from '../../assets/aws-s3-logo.svg';
import { ReactComponent as AwsSageMaker } from '../../assets/aws-sagemaker.svg';
import { ReactComponent as AwsSecurityHub } from '../../assets/aws-securityhub.svg';
import { ReactComponent as AwsSns } from '../../assets/aws-sns.svg';
import { ReactComponent as AwsSqs } from '../../assets/aws-sqs.svg';
import { ReactComponent as AwsSSM } from '../../assets/aws-ssm.svg';
import { ReactComponent as AwsSupport } from '../../assets/aws-support.svg';
import { ReactComponent as AwsTrustAdvisor } from '../../assets/aws-trustadvisor.svg';
import { ReactComponent as AwsVPC } from '../../assets/aws-vpc.svg';
import { ReactComponent as AzureCognitiveService } from '../../assets/azue-cognitiveServices.svg';
import { ReactComponent as AzureActiveDir } from '../../assets/azure-activeDirectory.svg';
import { ReactComponent as AzureApiApps } from '../../assets/azure-apiApps.svg';
import { ReactComponent as AzureApiManagement } from '../../assets/azure-apiManagment.svg';
import { ReactComponent as Automation } from '../../assets/azure-automation-icon.svg';
import { ReactComponent as AzureAutomation } from '../../assets/azure-automation.svg';
import { ReactComponent as AzureB2C } from '../../assets/azure-b2c.svg';
import { ReactComponent as AzureBatch } from '../../assets/azure-batch.svg';
import { ReactComponent as AzureCloudService } from '../../assets/azure-cloudService.svg';
import { ReactComponent as AzureContentDelivery } from '../../assets/azure-content.svg';
import { ReactComponent as AzureCortana } from '../../assets/azure-cortana.svg';
import { ReactComponent as AzureDataFactory } from '../../assets/azure-dataFactory.svg';
import { ReactComponent as AzureDataLakeAnalytics } from '../../assets/azure-dataLakeAnalytics.svg';
import { ReactComponent as AzureDataLakeStore } from '../../assets/azure-dataLakeStore.svg';
import { ReactComponent as AzureDeveloperTools } from '../../assets/azure-developerTools.svg';
import { ReactComponent as AzureDevops } from '../../assets/azure-devops.svg';
import { ReactComponent as AzureDevTestLabs } from '../../assets/azure-devTests.svg';
import { ReactComponent as AzureDocumentDb } from '../../assets/azure-documentDB.svg';
import { ReactComponent as AzureDomainService } from '../../assets/azure-domain.svg';
import { ReactComponent as AzureEventHubs } from '../../assets/azure-eventsHub.svg';
import { ReactComponent as AzureServiceFabric } from '../../assets/azure-fabric.svg';
import { ReactComponent as AzureFunctions } from '../../assets/azure-functions.svg';
import { ReactComponent as AzureHDInsights } from '../../assets/azure-hd.svg';
import { ReactComponent as AzureHockeyApp } from '../../assets/azure-hockeyapp.svg';
import { ReactComponent as AzureIotHub } from '../../assets/azure-iotHub.svg';
import { ReactComponent as AzureIotSuite } from '../../assets/azure-iotSuite.svg';
import { ReactComponent as AzureKeyVault } from '../../assets/azure-keyVault.svg';
import { ReactComponent as AzureLogicApps } from '../../assets/azure-logicApps.svg';
import { ReactComponent as AzureMediaService } from '../../assets/azure-media.svg';
import { ReactComponent as AzureMachineLearning } from '../../assets/azure-ml.svg';
import { ReactComponent as AzureMobileApps } from '../../assets/azure-mobileApps.svg';
import { ReactComponent as AzureMobileEngagement } from '../../assets/azure-mobileEngagement.svg';
import { ReactComponent as AzureMultiFactor } from '../../assets/azure-multi.svg';
import { ReactComponent as AzureNotificationsHubs } from '../../assets/azure-notificationsHub.svg';
import {
  ReactComponent as AzureLogAnalytics,
  ReactComponent as AzureOperationsManagement,
} from '../../assets/azure-opManagment.svg';
import { ReactComponent as AzureAzurePortal } from '../../assets/azure-portal.svg';
import { ReactComponent as AzurePowerBI } from '../../assets/azure-powerBI.svg';
import { ReactComponent as AzureRedisCache } from '../../assets/azure-redis.svg';
import { ReactComponent as AzureVirtualMachinesScale } from '../../assets/azure-scale.svg';
import { ReactComponent as AzureSchedular } from '../../assets/azure-scheduler.svg';
import {
  ReactComponent as AzureContainerSecurity,
  ReactComponent as AzureSecurityCenter,
} from '../../assets/azure-security.svg';
import { ReactComponent as AzureSqlDatabase } from '../../assets/azure-sqlDatabase.svg';
import { ReactComponent as AzureSqlServerStretch } from '../../assets/azure-sqlServerDb.svg';
import { ReactComponent as AzureSqlDataWarehouse } from '../../assets/azure-sqlWarehouse.svg';
import { ReactComponent as AzureStorage } from '../../assets/azure-storage.svg';
import { ReactComponent as AzureStorSimple } from '../../assets/azure-storSimple.svg';
import { ReactComponent as AzureStreamAnalytics } from '../../assets/azure-stream.svg';
import { ReactComponent as AzureVirtualMachines } from '../../assets/azure-virtualMachines.svg';
import { ReactComponent as AzureVisualStudio } from '../../assets/azure-vs.svg';
import { ReactComponent as AzureVSapp } from '../../assets/azure-vsAppInsights.svg';
import { ReactComponent as AzureWebApps } from '../../assets/azure-webApps.svg';
import { ReactComponent as CloudFrontIcon } from '../../assets/cloudfront-icon.svg';
import { ReactComponent as GcpAppEngine } from '../../assets/gcp-appEngine.svg';
import { ReactComponent as GcpCloudBalancing } from '../../assets/gcp-balancing.svg';
import { ReactComponent as GcpBigQuery } from '../../assets/gcp-bigquery.svg';
import { ReactComponent as GcpCloudBigtable } from '../../assets/gcp-bigtable.svg';
import { ReactComponent as GcpCloudCDN } from '../../assets/gcp-cdn.svg';
import { ReactComponent as GcpCloudBuild } from '../../assets/gcp-cloudBuild.svg';
import { ReactComponent as GcpCloudConsole } from '../../assets/gcp-cloudConsole.svg';
import { ReactComponent as GcpCloudEndpoints } from '../../assets/gcp-cloudEndpoints.svg';
import { ReactComponent as GcpCloudFunctions } from '../../assets/gcp-cloudFunctions.svg';
import { ReactComponent as GcpCloudRun } from '../../assets/gcp-cloudRun.svg';
import { ReactComponent as GcpCloudSpeechAPI } from '../../assets/gcp-cloudSpeeach.svg';
import { ReactComponent as GcpComputeEngine } from '../../assets/gcp-computeEngine.svg';
import { ReactComponent as GcpCloudIotCore } from '../../assets/gcp-core.svg';
import { ReactComponent as GcpCloudDataflow } from '../../assets/gcp-dataflow.svg';
import { ReactComponent as GcpDataLossPrevention } from '../../assets/gcp-dataLoss.svg';
import { ReactComponent as GcpCloudDataprep } from '../../assets/gcp-dataprep.svg';
import { ReactComponent as GcpCloudDataproc } from '../../assets/gcp-dataproc.svg';
import { ReactComponent as GcpCloudDatastore } from '../../assets/gcp-datastore.svg';
import { ReactComponent as GcpDebugger } from '../../assets/gcp-debugger.svg';
import { ReactComponent as GcpCloudDNS } from '../../assets/gcp-dns.svg';
import { ReactComponent as GcpFirewallRules } from '../../assets/gcp-firewallRules.svg';
import { ReactComponent as GcpCloudIam } from '../../assets/gcp-iam.svg';
import { ReactComponent as GcpIdentityAwareProxy } from '../../assets/gcp-identityAwareProxy.svg';
import { ReactComponent as GcpCloudInterconnect } from '../../assets/gcp-interconnect.svg';
import { ReactComponent as GcpKMS } from '../../assets/gcp-kms.svg';
import { ReactComponent as GcpKubernetesEngine } from '../../assets/gcp-kuberun.svg';
import { ReactComponent as GcpLogging } from '../../assets/gcp-logging.svg';
import { ReactComponent as GcpCloudMl } from '../../assets/gcp-ml.svg';
import { ReactComponent as GcpMonitoring } from '../../assets/gcp-monitoring.svg';
import { ReactComponent as GcpNaturalLanguageAPI } from '../../assets/gcp-neturalLanguage.svg';
import { ReactComponent as GcpCloudSpanner } from '../../assets/gcp-spanner.svg';
import { ReactComponent as GcpCloudSql } from '../../assets/gcp-sql.svg';
import { ReactComponent as GcpStackdriver } from '../../assets/gcp-stackdriver.svg';
import { ReactComponent as GcpCloudStorage } from '../../assets/gcp-storage.svg';
import { ReactComponent as GcpTrace } from '../../assets/gcp-trace.svg';
import { ReactComponent as GcpCloudTranslateAPI } from '../../assets/gcp-translation.svg';
import { ReactComponent as GcpCloudVisionAPI } from '../../assets/gcp-vision.svg';
import { ReactComponent as GcpVPC } from '../../assets/gcp-vpc.svg';
import { ReactComponent as GcpCloudVPN } from '../../assets/gcp-vpn.svg';
import { ReactComponent as AzureAks } from '../../assets/azure-aks.svg';
import { ReactComponent as GkeIcon } from '../../assets/gcp-gke.svg';
import { IconType } from 'ox-common-types';
import {
  CloudDeploymentAWSSubTypes,
  CloudDeploymentAzureSubTypes,
  CloudDeploymentGCPSubTypes,
  CloudDeploymentItem,
  CloudDeploymentTypes,
} from '../types/cloud-deployment-types';

export const cloudDeploymentAWSIconMap = new Map<string, IconType>([
  [CloudDeploymentAWSSubTypes.CloudFront, CloudFrontIcon],
  [CloudDeploymentAWSSubTypes.Lambda, AwsLambdaIcon],
  [CloudDeploymentAWSSubTypes.S3, S3Icon],
  [CloudDeploymentAWSSubTypes.CloudFormation, CloudFormationIcon],
  [CloudDeploymentAWSSubTypes.ApiGateway, ApiGateway],
  [CloudDeploymentAWSSubTypes.IAM, IamIcon],
  [CloudDeploymentAWSSubTypes.DynamoDB, DynamoDBIcon],
  [CloudDeploymentAWSSubTypes.EC2, AwsEC2],
  [CloudDeploymentAWSSubTypes.CloudTrail, AwsCloudTrail],
  [CloudDeploymentAWSSubTypes.KMS, AwsKMS],
  [CloudDeploymentAWSSubTypes.VPC, AwsVPC],
  [CloudDeploymentAWSSubTypes.Support, AwsSupport],
  [CloudDeploymentAWSSubTypes.ConfigService, AwsConfigureService],
  [CloudDeploymentAWSSubTypes.Config, AwsConfigureService],
  [CloudDeploymentAWSSubTypes.ECR, AwsEcr],
  [CloudDeploymentAWSSubTypes.RDS, AwsRds],
  [CloudDeploymentAWSSubTypes.ELB, AwsElb],
  [CloudDeploymentAWSSubTypes.RedShift, AwsRedShift],
  [CloudDeploymentAWSSubTypes.Macie, AwsMacie],
  [CloudDeploymentAWSSubTypes.GuardDuty, AwsGuardDuty],
  [CloudDeploymentAWSSubTypes.ES, AwsEs],
  [CloudDeploymentAWSSubTypes.Route53, AwsRoute53],
  [CloudDeploymentAWSSubTypes.ACM, AwsAcm],
  [CloudDeploymentAWSSubTypes.TrustAdvisor, AwsTrustAdvisor],
  [CloudDeploymentAWSSubTypes.SQS, AwsSqs],
  [CloudDeploymentAWSSubTypes.SNS, AwsSns],
  [CloudDeploymentAWSSubTypes.CloudFormation, AwsCloudFormation],
  [CloudDeploymentAWSSubTypes.AccessAnalyzer, AwsAccessAnalyzer],
  [CloudDeploymentAWSSubTypes.ECS, AwsCloudEcs],
  [CloudDeploymentAWSSubTypes.AutoScaling, AwsAutoScaling],
  [CloudDeploymentAWSSubTypes.EKS, AwsEKS],
  [CloudDeploymentAWSSubTypes.SecurityHub, AwsSecurityHub],
  [CloudDeploymentAWSSubTypes.SageMaker, AwsSageMaker],
  [CloudDeploymentAWSSubTypes.Glue, AwsGlue],
  [CloudDeploymentAWSSubTypes.SSM, AwsSSM],
  [CloudDeploymentAWSSubTypes.DynamoDB, AwsDynamoDB],
  [CloudDeploymentAWSSubTypes.EFS, AwsEFS],
  [CloudDeploymentAWSSubTypes.CloudWatch, AwsCloudWatch],
  [CloudDeploymentAWSSubTypes.Glacier, AwsGlacier],
  [CloudDeploymentAWSSubTypes.Fargate, AwsFargate],
]);

export const cloudDeploymentGCPIconMap = new Map<string, IconType>([
  [CloudDeploymentGCPSubTypes.ComputeEngine, GcpComputeEngine],
  [CloudDeploymentGCPSubTypes.GoggleComputeEngine, GcpComputeEngine],
  [CloudDeploymentGCPSubTypes.KubernetesEngine, GcpKubernetesEngine],
  [CloudDeploymentGCPSubTypes.AppEngine, GcpAppEngine],
  [CloudDeploymentGCPSubTypes.CloudFunctions, GcpCloudFunctions],
  [CloudDeploymentGCPSubTypes.CloudConsole, GcpCloudConsole],
  [CloudDeploymentGCPSubTypes.Stackdriver, GcpStackdriver],
  [CloudDeploymentGCPSubTypes.Trace, GcpTrace],
  [CloudDeploymentGCPSubTypes.Logging, GcpLogging],
  [CloudDeploymentGCPSubTypes.Debugger, GcpDebugger],
  [CloudDeploymentGCPSubTypes.Monitoring, GcpMonitoring],
  [CloudDeploymentGCPSubTypes.CloudLoadBalancing, GcpCloudBalancing],
  [CloudDeploymentGCPSubTypes.CloudCDN, GcpCloudCDN],
  [CloudDeploymentGCPSubTypes.CloudDNS, GcpCloudDNS],
  [CloudDeploymentGCPSubTypes.FirewallRules, GcpFirewallRules],
  [CloudDeploymentGCPSubTypes.CloudInterconnect, GcpCloudInterconnect],
  [CloudDeploymentGCPSubTypes.CloudVPN, GcpCloudVPN],
  [CloudDeploymentGCPSubTypes.CloudBigtable, GcpCloudBigtable],
  [CloudDeploymentGCPSubTypes.CloudDatastore, GcpCloudDatastore],
  [CloudDeploymentGCPSubTypes.CloudSpanner, GcpCloudSpanner],
  [CloudDeploymentGCPSubTypes.CloudSql, GcpCloudSql],
  [CloudDeploymentGCPSubTypes.CloudStorage, GcpCloudStorage],
  [CloudDeploymentGCPSubTypes.GoogleCloudStorage, GcpCloudStorage],
  [CloudDeploymentGCPSubTypes.BigQuery, GcpBigQuery],
  [CloudDeploymentGCPSubTypes.CloudDataflow, GcpCloudDataflow],
  [CloudDeploymentGCPSubTypes.CloudDataprep, GcpCloudDataprep],
  [CloudDeploymentGCPSubTypes.CloudDataproc, GcpCloudDataproc],
  [CloudDeploymentGCPSubTypes.CloudIotCore, GcpCloudIotCore],
  [CloudDeploymentGCPSubTypes.CloudIam, GcpCloudIam],
  [CloudDeploymentGCPSubTypes.GoogleCloudIam, GcpCloudIam],
  [CloudDeploymentGCPSubTypes.CloudEndpoints, GcpCloudEndpoints],
  [CloudDeploymentGCPSubTypes.IdentityAwareProxy, GcpIdentityAwareProxy],
  [CloudDeploymentGCPSubTypes.KMS, GcpKMS],
  [CloudDeploymentGCPSubTypes.DataLossPrevention, GcpDataLossPrevention],
  [CloudDeploymentGCPSubTypes.CloudMl, GcpCloudMl],
  [CloudDeploymentGCPSubTypes.NaturalLanguageAPI, GcpNaturalLanguageAPI],
  [CloudDeploymentGCPSubTypes.CloudSpeechAPI, GcpCloudSpeechAPI],
  [CloudDeploymentGCPSubTypes.CloudVisionAPI, GcpCloudVisionAPI],
  [CloudDeploymentGCPSubTypes.CloudTranslateAPI, GcpCloudTranslateAPI],
  [CloudDeploymentGCPSubTypes.CloudRun, GcpCloudRun],
  [CloudDeploymentGCPSubTypes.CloudBuild, GcpCloudBuild],
  [CloudDeploymentGCPSubTypes.VPC, GcpVPC],
  [CloudDeploymentGCPSubTypes.GoggleVpc, GcpVPC],
  [CloudDeploymentGCPSubTypes.GKE, GkeIcon],
  [CloudDeploymentGCPSubTypes.GKE.toLocaleLowerCase(), GkeIcon], // gke and GKE are not aligned between artifacts and app-flows
]);

export const cloudDeploymentAzureIconMap = new Map<string, IconType>([
  [CloudDeploymentAzureSubTypes.VisualStudio, AzureVisualStudio],
  [CloudDeploymentAzureSubTypes.DevtestLabs, AzureDevTestLabs],
  [CloudDeploymentAzureSubTypes.VSapp, AzureVSapp],
  [CloudDeploymentAzureSubTypes.HockeyApp, AzureHockeyApp],
  [CloudDeploymentAzureSubTypes.DeveloperTools, AzureDeveloperTools],
  [CloudDeploymentAzureSubTypes.AzurePortal, AzureAzurePortal],
  [CloudDeploymentAzureSubTypes.Schedular, AzureSchedular],
  [
    CloudDeploymentAzureSubTypes.OperationsManagement,
    AzureOperationsManagement,
  ],
  [CloudDeploymentAzureSubTypes.Automation, AzureAutomation],
  [CloudDeploymentAzureSubTypes.LogAnalytics, AzureLogAnalytics],
  [CloudDeploymentAzureSubTypes.KeyVault, AzureKeyVault],
  [CloudDeploymentAzureSubTypes.SecurityCenter, AzureSecurityCenter],
  [CloudDeploymentAzureSubTypes.VirtualMachines, AzureVirtualMachines],
  [
    CloudDeploymentAzureSubTypes.VirtualMachinesScale,
    AzureVirtualMachinesScale,
  ],
  [CloudDeploymentAzureSubTypes.CloudService, AzureCloudService],
  [CloudDeploymentAzureSubTypes.Batch, AzureBatch],
  [CloudDeploymentAzureSubTypes.ServiceFabric, AzureServiceFabric],
  [CloudDeploymentAzureSubTypes.ContainerSecurity, AzureContainerSecurity],
  [CloudDeploymentAzureSubTypes.WebApps, AzureWebApps],
  [CloudDeploymentAzureSubTypes.MobileApps, AzureMobileApps],
  [CloudDeploymentAzureSubTypes.ApiApps, AzureApiApps],
  [CloudDeploymentAzureSubTypes.ApiManagement, AzureApiManagement],
  [CloudDeploymentAzureSubTypes.NotificationsHubs, AzureNotificationsHubs],
  [CloudDeploymentAzureSubTypes.MobileEngagement, AzureMobileEngagement],
  [CloudDeploymentAzureSubTypes.Functions, AzureFunctions],
  [CloudDeploymentAzureSubTypes.SqlDatabase, AzureSqlDatabase],
  [CloudDeploymentAzureSubTypes.DocumentDb, AzureDocumentDb],
  [CloudDeploymentAzureSubTypes.RedisCache, AzureRedisCache],
  [CloudDeploymentAzureSubTypes.Storage, AzureStorage],
  [CloudDeploymentAzureSubTypes.StorSimple, AzureStorSimple],
  [CloudDeploymentAzureSubTypes.SqlDataWarehouse, AzureSqlDataWarehouse],
  [CloudDeploymentAzureSubTypes.SqlServerStretch, AzureSqlServerStretch],
  [CloudDeploymentAzureSubTypes.DataLakeAnalytics, AzureDataLakeAnalytics],
  [CloudDeploymentAzureSubTypes.DataLakeStore, AzureDataLakeStore],
  [CloudDeploymentAzureSubTypes.HDInsights, AzureHDInsights],
  [CloudDeploymentAzureSubTypes.MachineLearning, AzureMachineLearning],
  [CloudDeploymentAzureSubTypes.StreamAnalytics, AzureStreamAnalytics],
  [CloudDeploymentAzureSubTypes.DataFactory, AzureDataFactory],
  [CloudDeploymentAzureSubTypes.PowerBI, AzurePowerBI],
  [CloudDeploymentAzureSubTypes.IotSuite, AzureIotSuite],
  [CloudDeploymentAzureSubTypes.IotHub, AzureIotHub],
  [CloudDeploymentAzureSubTypes.EventHubs, AzureEventHubs],
  [CloudDeploymentAzureSubTypes.Cortana, AzureCortana],
  [CloudDeploymentAzureSubTypes.CognitiveService, AzureCognitiveService],
  [CloudDeploymentAzureSubTypes.MediaService, AzureMediaService],
  [CloudDeploymentAzureSubTypes.ContentDelivery, AzureContentDelivery],
  [CloudDeploymentAzureSubTypes.ActiveDir, AzureActiveDir],
  [CloudDeploymentAzureSubTypes.B2C, AzureB2C],
  [CloudDeploymentAzureSubTypes.DomainService, AzureDomainService],
  [CloudDeploymentAzureSubTypes.MultiFactor, AzureMultiFactor],
  [CloudDeploymentAzureSubTypes.LogicApps, AzureLogicApps],
  [CloudDeploymentAzureSubTypes.DevOps, AzureDevops],
  [CloudDeploymentAzureSubTypes.Automation, Automation],
  [CloudDeploymentAzureSubTypes.Aks, AzureAks],
]);

export const cloudDeploymentIconMapsByType = new Map([
  [CloudDeploymentTypes.AWS, cloudDeploymentAWSIconMap],
  [CloudDeploymentTypes.GCP, cloudDeploymentGCPIconMap],
  [CloudDeploymentTypes.Azure, cloudDeploymentAzureIconMap],
]);

export const getServiceIcon = (type: string) => {
  return (
    cloudDeploymentAWSIconMap.get(type) ||
    cloudDeploymentGCPIconMap.get(type) ||
    cloudDeploymentAzureIconMap.get(type)
  );
};

const findCloudDeploymentSubTypeTitle = (
  type: CloudDeploymentTypes,
  subType: string,
) => {
  switch (type) {
    case CloudDeploymentTypes.AWS:
      return Object.keys(CloudDeploymentAWSSubTypes).find(
        subTypeKey =>
          (CloudDeploymentAWSSubTypes as Record<string, string>)[subTypeKey] ===
          subType,
      );
    case CloudDeploymentTypes.GCP:
      return Object.keys(CloudDeploymentGCPSubTypes).find(
        subTypeKey =>
          (CloudDeploymentAWSSubTypes as Record<string, string>)[subTypeKey] ===
          subType,
      );
    case CloudDeploymentTypes.Azure:
      return Object.keys(CloudDeploymentAzureSubTypes).find(
        subTypeKey =>
          (CloudDeploymentAWSSubTypes as Record<string, string>)[subTypeKey] ===
          subType,
      );
    default:
      return subType;
  }
};

export const replaceCloudDeploymentSubTypes = (
  cloudDeploymentItems: CloudDeploymentItem[],
) => {
  cloudDeploymentItems.forEach(item => {
    item.subType =
      findCloudDeploymentSubTypeTitle(item.type, item.subType) || item.subType;
  });
};

export const typesCloudDeployment = (
  cloudDeploymentItems: CloudDeploymentItem[],
) => {
  return [
    cloudDeploymentItems.filter(
      cloudDeployment => cloudDeployment.type === CloudDeploymentTypes.AWS,
    ),
    cloudDeploymentItems.filter(
      cloudDeployment => cloudDeployment.type === CloudDeploymentTypes.GCP,
    ),
    cloudDeploymentItems.filter(
      cloudDeployment => cloudDeployment.type === CloudDeploymentTypes.Azure,
    ),
  ];
};
