import { Divider, Link, Theme, Typography } from '@mui/material';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import { AppFlowGeneralInfo } from './AppFlowGeneralInfo';
import {
  AppFlowToolTipItem,
  ApplicationFlowItemType,
  LocationAppFlow,
} from './types/app-flow-types';
import {
  generateCategoryToolTipIcon,
  generateTypesMap,
  isCloudDeploymentType,
} from './utils/app-flow-utils';

export const AppFlowToolTip: FC<AppFlowToolTipProps> = ({
  appFlowType,
  header,
}) => {
  const { classes } = useStyles();

  const flows = generateTypesMap(appFlowType);
  return (
    <div className={classes.container}>
      {header && (
        <Typography
          variant='body1'
          color='text.secondary'
          mt={0.5}
          className={classes.typeDescription}
          component='div'>
          {appFlowType[0].system || appFlowType[0].system}
        </Typography>
      )}
      <Typography
        variant='body1'
        color='text.secondary'
        fontSize='0.87rem'
        mt={0.5}
        className={classes.typeDescription}
        component='div'>
        {isCloudDeploymentType(appFlowType[0].type) ||
        appFlowType[0].type === ApplicationFlowItemType.Kubernetes
          ? appFlowType[0].type
          : appFlowType[0].subType ||
            appFlowType[0].name ||
            appFlowType[0].system}
      </Typography>
      {flows &&
        [...flows].map((flow, index) => {
          const CategoryIcon = generateCategoryToolTipIcon(
            appFlowType[0].type,
            flow[0],
          );
          return (
            <div className={classes.cloudDeploymentToolTip} key={index}>
              {isCloudDeploymentType(appFlowType[0].type) ? (
                <Typography
                  variant='body1'
                  color='text.secondary'
                  mt={0.5}
                  className={classes.typeDescription}
                  component='div'>
                  {flow[0] && `${flow[0]}:`}
                  {CategoryIcon && <CategoryIcon className={classes.icon} />}
                </Typography>
              ) : (
                <></>
              )}
              {flow[1] &&
                flow[1]
                  .slice(0, 5)
                  .map((item: AppFlowToolTipItem, indexFlow: number) => {
                    return item.link ? (
                      <div key={indexFlow}>
                        <AppFlowGeneralInfo info={item} />
                        <Link
                          href={item.link}
                          target='_blank'
                          className={classes.typeDescription}>
                          <Typography variant='caption' color='text.primary'>
                            {item.name || item.hash}
                          </Typography>
                        </Link>
                      </div>
                    ) : item.location ? (
                      item.location?.map(
                        (locate: LocationAppFlow, indexLocation) => {
                          return (
                            <div key={indexLocation}>
                              <AppFlowGeneralInfo info={item} />
                              {item.hash && (
                                <Typography
                                  variant='body2'
                                  color='text.secondary'
                                  mt={0.5}>
                                  Hash:
                                </Typography>
                              )}
                              <Link
                                href={locate.link || ''}
                                target='_blank'
                                className={classes.typeDescription}>
                                <Typography
                                  variant='caption'
                                  color='text.primary'
                                  mt={0.5}>
                                  {item.hash || item.hash}
                                </Typography>
                              </Link>
                              <Divider
                                sx={{
                                  marginBlockStart: '5px',
                                }}
                              />
                            </div>
                          );
                        },
                      )
                    ) : (
                      <Typography variant='body2' color='text.primary' mt={0.5}>
                        {item?.name}
                      </Typography>
                    );
                  })}
              {flow[1]?.length > 5 && (
                <Typography variant='body2' color='text.secondary' mt={0.5}>
                  + {flow[1].length - 5} more
                </Typography>
              )}
            </div>
          );
        })}
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    overflowY: 'auto',
    maxHeight: window.innerHeight / 2.5,
  },
  typeDescription: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    display: '-webkit-box',
    lineClamp: 2,
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
  cloudDeploymentToolTip: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    marginInlineStart: theme.spacing(0.5),
    height: '1rem',
    width: '1rem',
  },
  title: {
    color: theme.palette.text.secondary,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

interface AppFlowToolTipProps {
  appFlowType: AppFlowToolTipItem[];
  header?: boolean;
}
