import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import { GetApplicationsInput } from '../../applications-types';
import query from './get-applications-conditional-filters.gql';
import { LazyFiltersResponse } from 'ox-filter-utils';
import { openSnackbar } from 'snackbar-utils';
const getApplicationsFiltersLazy = (
  client: ApolloClient<NormalizedCacheObject>,
) => {
  return {
    execute: async (
      getApplicationsInput: GetApplicationsInput,
      cache = true,
    ) => {
      try {
        const results = await client.query<ApplicationsFiltersLazyResponse>({
          query,
          fetchPolicy: cache
            ? ApolloClientCache.CacheFirst
            : ApolloClientCache.NetworkOnly,
          variables: { getApplicationsInput },
        });
        if (!results.data) {
          return null;
        }
        return results.data.getApplicationsConditionalFilters;
      } catch (error) {
        logger.error('Failed to fetch application filters', error);
        openSnackbar('Failed to fetch application filters', {
          variant: 'error',
        });
        return null;
      }
    },
  };
};

export interface ApplicationsFiltersLazyResponse {
  getApplicationsConditionalFilters: LazyFiltersResponse;
}

export default getApplicationsFiltersLazy;
