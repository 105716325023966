import { useAuth0 } from '@auth0/auth0-react';
import { LogName } from '@oxappsec/audit-log-service';
import { AppPages } from 'app-navigator';
import { fetchAssignedAppOwners } from 'app-owners-logic';
import { setAppIsReady, setAppUser, setLdClient, useAppStore } from 'app-store';
import { useTokenStore } from 'common-auth';
import { AppSpinner } from 'common-components';
import { isOxUserEmail } from 'common-utils';
import { getEnvironmentType, isProd, isRemoteSite } from 'env-utils';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { logger } from 'logging-utils';
import { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TelemetryManager, initAppcues } from 'telemetry';
import { setLoggedInUserConnection } from '../../connections/utils/selected-connection-store-utils';
import { loadDashboardInfo } from '../../dashboard/actions/dashboard-actions';
import { getNearlyExpiredExclusions } from '../../exclusions/actions/exclusions-actions';
import { useOxFlags } from '../../feature-flags/actions/feature-flags-actions';
import { setLoggedInUserId } from '../../members/api/members-api';
import { setUserRoles } from '../../members/api/user-roles-api';
import {
  setUserPermissions,
  setUserScopes,
} from '../../members/store-actions/user-scopes-store-actions';
import { CUSTOM_ROLES } from '../../members/types/user-role-types';
import { fetchScanSettingsAndStatus } from '../../new-scan/actions/scan-actions';
import {
  fetchOrgInfo,
  getAvailableOrganizations,
  setChosenOrganization,
  useOrganizations,
} from '../../organizations/api/organizations-api';
import { useOrgInfo } from '../../organizations/store-actions/org-info-store-actions';
import { getLastLoggedInOrg } from '../../organizations/utils/local-storage-utils';
import { fetchActiveProfileOverviewAction } from '../../policies/api/policies-api';
import {
  fetchCanUserRequestAccess,
  shouldNavigateToRequestAccess,
} from '../../request-access/api/request-access-settings-api';
import { loadSettingsMapByName } from '../../settings/actions/settings-actions';
import { getEditApplicationsTagsData } from '../../tags/actions/edit-applications-tags-content-actions';
import { viewPreferencesActions } from '../../view-preferences/actions/view-preferences-actions';
import {
  logUserIntoOrganization,
  setGeneralError,
  setPageError,
  useDebugToggle,
} from '../actions/app-general-actions';
import {
  checkIfEmptyOrg,
  dispatchAppReady,
  initApp,
} from '../actions/app-init-actions';
import { auditUserLogin } from '../actions/app-login-actions';
import { registerUserConsent } from '../actions/app-registration-actions';
import {
  navigateToSSOLoginPage,
  shouldNavigateToSSOLoginPage,
} from '../utils/sso-navigation-utils';
import { loadConnectorsByFamily } from '../../connectors/api/connectors-api';

const AppLoader: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { initialized, isFirstLogin } = useAppStore();
  const { loadingNewToken } = useTokenStore();
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { organizations } = useOrganizations();
  const { organization: orgInfo } = useOrgInfo();
  const ldClient = useLDClient();
  const { appOwnersMigration } = useOxFlags();
  useEffect(() => {
    ldClient && setLdClient(ldClient);
  }, [ldClient]);
  useDebugToggle();
  const didFetchAppOwners = useRef(false);
  const [ldInitialized, setLdInitialized] = useState(false);

  useEffect(() => {
    if (user && !user.email_verified) {
      navigate(AppPages.EmailVerification);
    }
  }, [navigate, user]);

  useEffect(() => {
    if (user) {
      setAppUser(user);
      // Timeout for ldClient to identify the user
      setTimeout(() => {
        setLdInitialized(true);
      }, 3000);
      // setup for launch darkly context (feature flags)
      ldClient
        ?.identify({
          user: { key: user.sub, orgId: user.org_id, email: user.email },
          org: {
            key: user.org_id,
            orgId: user.org_id,
            creationDate: orgInfo?.created_at, // orgInfo is fetched in the next useEffect which might be delayed for some API calls
          },
          kind: 'multi',
        })
        .then(() => {
          logger.info('LD client identify success');
          setLdInitialized(true);
        })
        .catch((error: Error) => {
          logger.error('LD client identify failed', error);
        });
      // setup telemetry with the current user
      TelemetryManager.setTelemetryUser({
        id: user.sub!,
        email: user.email!,
        orgId: user.org_id,
        name: user.name!,
      });
      TelemetryManager.setTelemetryAction('Login', user);
      // In production start recording sessions
      if (isProd() && !isOxUserEmail(user.email)) {
        TelemetryManager.startRumRecordingSession();
      }

      // setup user scopes
      setUserScopes();

      // setup user roles
      setUserRoles(user[CUSTOM_ROLES]);

      // setup user permissions
      setUserPermissions(user[CUSTOM_ROLES]);

      // members page - setting the current user id
      setLoggedInUserId(user.sub);

      // login settings page - setting the current login connection
      setLoggedInUserConnection(user.sub);

      // App-cues (formerly known as WalkMe)
      if (!isRemoteSite()) {
        initAppcues(user);
      }
    }
  }, [ldClient, user, orgInfo]);

  useEffect(() => {
    if (isFirstLogin) {
      return;
    }

    logUserIntoOrganization(
      isAuthenticated,
      organizations,
      getAccessTokenSilently,
      getAvailableOrganizations,
      fetchOrgInfo,
      fetchCanUserRequestAccess,
    )
      .then(({ isFirstLogin: onboardingStarted, canUserRequestAccessInfo }) => {
        // Telemetry logs need for the onboarding
        TelemetryManager.addTelemetryLogsContext('env', getEnvironmentType());
        TelemetryManager.addTelemetryLogsContext('user', user);

        // navigate user to the SSO login page if user has no orgs and user logged in with SSO
        if (onboardingStarted && user && shouldNavigateToSSOLoginPage(user)) {
          navigateToSSOLoginPage(user);
          return;
        }

        // navigate user to request access flow
        if (
          onboardingStarted &&
          shouldNavigateToRequestAccess(canUserRequestAccessInfo)
        ) {
          setAppIsReady(true);
          navigate(AppPages.RequestAccess);
          return;
        }

        // navigate user to the onboarding flow
        if (onboardingStarted && user?.email_verified) {
          setAppIsReady(true);
          navigate(AppPages.Onboarding);
          return;
        }

        const lastLoggedInOrg = getLastLoggedInOrg();
        const noLastLoginOrg = !lastLoggedInOrg && organizations.length !== 0;

        const orgIdInUser = user?.org_id;
        const userOrg = organizations.find(org => org.id === orgIdInUser); // this is based on what we have in the token and what we have in the orgs list
        const orgFallback = noLastLoginOrg ? organizations[0] : lastLoggedInOrg; // this is based on the last logged in org or the first org in the list

        const org = userOrg ? userOrg : orgFallback; // prefer token over last logged in org

        // Providing organization to telemetry context
        TelemetryManager.setTelemetryContext({ ...org });
        TelemetryManager.addTelemetryLogsContext('organization', org);

        // The actual setting of organization for a user
        setChosenOrganization(org);
      })
      .catch(e => {
        logger.error(e);
        setGeneralError(true);
        setPageError(true);
      });
  }, [
    getAccessTokenSilently,
    isAuthenticated,
    organizations,
    isFirstLogin,
    navigate,
    user,
  ]);

  useEffect(() => {
    if (!initialized) {
      initApp();
    }
  }, [initialized]);

  const isLoadDashboardInfoCalled = useRef(false);

  useEffect(() => {
    const isMatchingPage = [
      AppPages.Onboarding,
      AppPages.IDPConfigure,
      AppPages.GitHubAppInstallationConfigure,
      AppPages.GitHubAppROInstallationConfigure,
      AppPages.BitbucketAppInstallationConfigure,
    ].includes(location.pathname);
    if (isMatchingPage || isFirstLogin) {
      return;
    }
    if (
      user &&
      user.org_id &&
      user.email_verified &&
      !loadingNewToken &&
      !isLoadDashboardInfoCalled.current
    ) {
      isLoadDashboardInfoCalled.current = true;

      // Get the policy count (active/disabled) to display in top bar.
      fetchActiveProfileOverviewAction();
      getNearlyExpiredExclusions();
      loadSettingsMapByName();
      viewPreferencesActions.loadUserViewSettings();
      loadConnectorsByFamily(false);

      loadDashboardInfo().then(lastReport => {
        fetchOrgInfo(false).then(orgInfo => {
          const navigateToOnboarding =
            orgInfo?.isOnboardingRequired &&
            !lastReport && // if we have a report we should never navigate to onboarding
            !(location.pathname === AppPages.Connectors && !!location.search);
          if (navigateToOnboarding && isRemoteSite()) {
            navigate(AppPages.Connectors);
            return;
          }
          if (navigateToOnboarding) {
            navigate(AppPages.Onboarding);
            return;
          }
          // In home root - default page dashboard
          if (location.pathname === AppPages.Home) {
            navigate(AppPages.Dashboard);
          }
        });
      });
    }
  }, [
    loadingNewToken,
    location.pathname,
    navigate,
    user,
    isFirstLogin,
    location.search,
  ]);

  useEffect(() => {
    if (user && user.org_id && !loadingNewToken) {
      checkIfEmptyOrg();
      fetchScanSettingsAndStatus();
      registerUserConsent(user);
      auditUserLogin(user, LogName.Login);
      dispatchAppReady(user);
      getEditApplicationsTagsData([]);
    }
    if (user && user.org_id && ldInitialized && !didFetchAppOwners.current) {
      didFetchAppOwners.current = true;
      fetchAssignedAppOwners(appOwnersMigration);
    }
  }, [appOwnersMigration, loadingNewToken, user, ldInitialized]);

  if (initialized) return children as ReactElement;

  return <AppSpinner />;
};

export default AppLoader;
