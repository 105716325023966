import { FC, useEffect } from 'react';
import { useOxFlags } from '../../feature-flags/api/feature-flags-api';
import { _privateFlagsStore } from './feature-flags-store';

export const FeatureFlagsHandler: FC = () => {
  const flags = useOxFlags();

  useEffect(() => {
    _privateFlagsStore._flags = flags;
  }, [flags]);

  return null;
};
