import React from 'react';
import { SeverityColor } from 'severity-utils';
export const getSeveritySumupText = (
  importantSeverityBreakdown: string[],
  oxSeverity: string | number,
) => {
  if (importantSeverityBreakdown.length) {
    const isSingleRule = importantSeverityBreakdown.length === 1;
    const rulesText = isSingleRule
      ? `Severity Factor "${importantSeverityBreakdown[0]}" always sets severity to `
      : `Severity Factors ${importantSeverityBreakdown
          .map(value => `"${value}"`)
          .join(' & ')} always set severity to `;

    return (
      <>
        {rulesText}
        <SeverityColor severity={oxSeverity}>
          {color => (
            <span
              style={{
                color,
                textTransform: 'capitalize',
                whiteSpace: 'nowrap',
              }}>
              <b>{oxSeverity}</b>
            </span>
          )}
        </SeverityColor>
      </>
    );
  } else {
    return 'No severity changing Severity Factors';
  }
};
