import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { logger } from 'logging-utils';
import { ApolloClientCache } from 'ox-common-types';
import {
  MessagingRecipient,
  MessagingVendorTypes,
} from '../../types/messaging-types';
import mutation from './send-message.gql';

const sendMessage = (client: ApolloClient<NormalizedCacheObject>) => {
  return {
    execute: async (sendNotifications: SendNotificationsInput) => {
      try {
        const results = await client.mutate<SendMessageResponse>({
          mutation,
          fetchPolicy: ApolloClientCache.NoCache,
          variables: { sendNotifications },
        });
        return results.data?.sendNotifications;
      } catch (error) {
        logger.error('Failed to send message', error);
        return null;
      }
    },
  };
};

export interface SendNotificationsInput {
  messagingVendor: MessagingVendorTypes;
  issuesIds: string[];
  comment: string;
  recipients: MessagingRecipient[];
}

export interface SendMessageResponse {
  sendNotifications: {
    error: string;
    sentSuccessfully: boolean;
  };
}
export default sendMessage;
