import { createGraphInfo } from 'issue-trend-utils';
import { SeverityType } from 'ox-common-types';
import { openSnackbar } from 'snackbar-utils';
import { ref, snapshot } from 'valtio';
import { GlobalDataViewSelector } from '../../app/components/DataViewSelector/global-data-view-selector-store';
import { calcDateRange } from '../../app/store-actions/top-bar-store-actions';
import TopBarStore from '../../app/stores/top-bar-store';
import InventoryFiltersStore from '../../dashboard-inventory/stores/inventory-filters-store';
import ScanStore from '../../new-scan/store/scan-store';
import applicationsService from '../services';
import ApplicationsStore from '../stores/applications-store';
import { extractSingleApplication } from '../utils/application-utils';
import { applicationsStoreActions } from '../stores/applications-store-actions';

export interface LoadSingleAppParams {
  cache: boolean;
}

export const loadSingleApplication = async (params?: LoadSingleAppParams) => {
  applicationsStoreActions.setLoadingSingleApp(true);
  const { selectedAppId } = snapshot(ApplicationsStore);
  const { cache = true } = params || {};
  if (selectedAppId) {
    const response = await applicationsService.getSingleApplication.execute(
      {
        applicationId: selectedAppId,
      },
      cache,
    );
    if (response) {
      applicationsStoreActions.setApplication(
        extractSingleApplication(response),
      );
    } else {
      openSnackbar('Failed to fetch application', { variant: 'error' });
    }
  }
  applicationsStoreActions.setLoadingSingleApp(false);
};

export const getBpForApp = async (appId: string) => {
  const response = await applicationsService.getSingleApplication.execute({
    applicationId: appId,
  });
  if (response) {
    if (response.overridePriority !== -1) {
      applicationsStoreActions.setBpForSelectedApp(response.overridePriority);
    } else {
      applicationsStoreActions.setBpForSelectedApp(
        response.originalBusinessPriority,
      );
    }
  }
};

export const saveLastDrawerHeight = (height: number) => {
  applicationsStoreActions.setLastDrawerHeight(height);
};

export const loadIssuesScore = async (appIds?: string) => {
  const { filters } = snapshot(InventoryFiltersStore);
  const { dateRange } = snapshot(TopBarStore);
  const [from, to] = calcDateRange(dateRange);
  const { isScanning } = snapshot(ScanStore);
  const { selectedTagIds, selectedAppOwnersEmails } = snapshot(
    GlobalDataViewSelector,
  );

  try {
    ApplicationsStore.isLoadingApplicationIssuesScore = true;
    const response = await applicationsService.getIssuesScore.execute(
      {
        limit: 5,
        filters,
        owners: selectedAppOwnersEmails,
        tagIds: selectedTagIds,
        dateRange: { from, to },
        appIds: [appIds || ApplicationsStore.selectedApp?.appId],
      },
      !isScanning,
    );
    if (response) {
      const severitiesToShow = [
        SeverityType.Info,
        SeverityType.Low,
        SeverityType.Medium,
        SeverityType.High,
        SeverityType.Critical,
        SeverityType.Appoxalypse,
      ];

      ApplicationsStore.applicationIssuesScore = ref(
        createGraphInfo(response, severitiesToShow),
      );
    }
  } finally {
    ApplicationsStore.isLoadingApplicationIssuesScore = false;
  }
};
