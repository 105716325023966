import { LocationAppFlow } from './app-flow-types';

export enum CloudDeploymentTypes {
  AWS = 'AWS',
  GCP = 'GCP',
  Azure = 'Azure',
  Generic = 'Generic',
}

export enum CloudDeploymentServices {
  AWSServices = 'AWS Services',
  GCPServices = 'GCP Services',
  AzureServices = 'Azure Services',
}

export interface CloudDeploymentItem {
  type: CloudDeploymentTypes;
  subType:
    | CloudDeploymentAzureSubTypes
    | CloudDeploymentAWSSubTypes
    | CloudDeploymentGCPSubTypes
    | string;
  name: string;
  hash: string;
  hashType: string;
  location: LocationAppFlow[];
}

export enum CloudDeploymentAzureSubTypes {
  VisualStudio = 'visualStudio',
  DevtestLabs = 'devTestLabs',
  VSapp = 'vsapp',
  HockeyApp = 'hockeyApp',
  DeveloperTools = 'developerTools',
  AzurePortal = 'azurePortal',
  Schedular = 'schedular',
  OperationsManagement = 'operationsManagement',
  Automation = 'automation',
  LogAnalytics = 'logAnalytics',
  KeyVault = 'keyVault',
  SecurityCenter = 'securityCenter',
  VirtualMachines = 'virtualMachines',
  VirtualMachinesScale = 'virtualMachinesScale',
  CloudService = 'cloudService',
  Batch = 'batch',
  ServiceFabric = 'serviceFabric',
  ContainerSecurity = 'containerSecurity',
  WebApps = 'webApps',
  MobileApps = 'mobileApps',
  LogicApps = 'logicApps',
  ApiApps = 'apiApps',
  ApiManagement = 'apiManagement',
  NotificationsHubs = 'notificationsHubs',
  MobileEngagement = 'mobileEngagement',
  Functions = 'functions',
  SqlDatabase = 'sqlDatabase',
  DocumentDb = 'documentDb',
  RedisCache = 'redisCache',
  Storage = 'storage',
  StorSimple = 'storSimple',
  SqlDataWarehouse = 'sqlDataWarehouse',
  SqlServerStretch = 'sqlServerStretch',
  DataLakeAnalytics = 'dataLakeAnalytics',
  DataLakeStore = 'dataLakeStore',
  HDInsights = 'hdInsights',
  MachineLearning = 'machineLearning',
  StreamAnalytics = 'streamAnalytics',
  DataFactory = 'dataFactory',
  PowerBI = 'powerBi',
  IotSuite = 'iotSuite',
  IotHub = 'iotHub',
  EventHubs = 'eventHubs',
  Cortana = 'cortana',
  CognitiveService = 'cognitiveService',
  MediaService = 'mediaService',
  ContentDelivery = 'contentDelivery',
  ActiveDir = 'activeDir',
  B2C = 'b2c',
  DomainService = 'domainService',
  MultiFactor = 'multiFactor',
  AzurePipeline = 'azurePipeline',
  DevOps = 'devOps',
  Aks = 'aks',
}

export enum CloudDeploymentGCPSubTypes {
  ComputeEngine = 'computeEngine',
  GoggleComputeEngine = 'Google Compute Engine',
  KubernetesEngine = 'kubernetesEngine',
  AppEngine = 'appEngine',
  CloudFunctions = 'cloudFunctions',
  CloudConsole = 'cloudConsole',
  Stackdriver = 'stackdriver',
  Trace = 'trace',
  Logging = 'logging',
  Debugger = 'debugger',
  Monitoring = 'monitoring',
  CloudLoadBalancing = 'cloudLoadBalancing',
  CloudCDN = 'cloudCdn',
  CloudDNS = 'cloudDns',
  FirewallRules = 'firewallRules',
  CloudInterconnect = 'cloudInterconnect',
  CloudVPN = 'cloudVpn',
  CloudBigtable = 'cloudBigtable',
  CloudDatastore = 'cloudDatastore',
  CloudSpanner = 'cloudSpanner',
  CloudSql = 'cloudSql',
  CloudStorage = 'cloudStorage',
  GoogleCloudStorage = 'Google Cloud Storage',
  BigQuery = 'bigQuery',
  CloudDataflow = 'cloudDataflow',
  CloudDataprep = 'cloudDataprep',
  CloudDataproc = 'cloudDataproc',
  CloudIotCore = 'cloudIotCore',
  CloudIam = 'cloudIam',
  GoogleCloudIam = 'Google Cloud IAM',
  Automation = 'automation',
  CloudEndpoints = 'cloudEndpoints',
  VPC = 'vpc',
  GoggleVpc = 'Google VPC',
  IdentityAwareProxy = 'identityAwareProxy',
  KMS = 'kms',
  DataLossPrevention = 'dataLossPrevention',
  CloudMl = 'cloudMl',
  NaturalLanguageAPI = 'naturalLanguageApi',
  CloudSpeechAPI = 'cloudSpeechApi',
  CloudVisionAPI = 'cloudVisionApi',
  CloudTranslateAPI = 'cloudTranslateApi',
  CloudRun = 'cloudRun',
  CloudBuild = 'cloudBuild',
  CircleCi = 'circleCi',
  BuildKite = 'buildKite',
  Jenkins = 'jenkins',
  TravisCi = 'travisCi',
  GKE = 'GKE',
}

export enum CloudDeploymentAWSSubTypes {
  IAM = 'iam',
  Config = 'config',
  Support = 'support',
  EC2 = 'ec2',
  CloudTrail = 'cloudtrail',
  ConfigService = 'configservice',
  S3 = 's3',
  KMS = 'kms',
  VPC = 'vpc',
  ECR = 'ecr',
  RDS = 'rds',
  ELB = 'elb',
  RedShift = 'redshift',
  Macie = 'macie',
  GuardDuty = 'guardduty',
  CloudFront = 'cloudfront',
  ES = 'es',
  Route53 = 'route53',
  Lambda = 'lambda',
  ApiGateway = 'apigateway',
  ACM = 'acm',
  TrustAdvisor = 'trustedadvisor',
  SQS = 'sqs',
  SNS = 'sns',
  CloudFormation = 'cloudformation',
  ECS = 'ecs',
  AccessAnalyzer = 'accessanalyzer',
  AutoScaling = 'autoscaling',
  EKS = 'eks',
  SecurityHub = 'securityhub',
  SageMaker = 'sagemaker',
  Glue = 'glue',
  SSM = 'ssm',
  DynamoDB = 'dynamodb',
  EFS = 'efs',
  CloudWatch = 'cloudwatch',
  Glacier = 'glacier',
  Fargate = 'fargate',
}
