export enum TabQueryParam {
  OrgSettings = 'organization',
  LoginSettings = 'login',
  NotificationSettings = 'notification',
  ApiKeySettings = 'apiKey',
  AuditLogs = 'auditLogs',
  ScanSettings = 'scanSettings',
  Usability = 'usabilitySettings',
  Secrets = 'secrets',
  ApplicationsSettings = 'applicationsSettings',
  PipelineSettings = 'pipelineSettings',
  ViewSettings = 'viewSettings',
}

export interface SubSettings {
  name: string;
  icon: SettingsIcon;
  dependsOnConnectorId: string;
  settings: SettingsSubSettings[];
  idSubSetting: string;
  isDevelopment?: boolean;
}
export interface SettingsObj {
  id: string;
  userId: string;
  name?: string;
  text?: string;
  header?: string;
  tooltip?: string;
  updatedAt: Date;
  enabled?: boolean;
  isDevelopment?: boolean;
  configured?: number;
  configuredDefault?: number;
  settingsType: string;
  settingsSubType: SettingsSubType;
  topLimit?: number;
  bottomLimit?: number;
  valueList?: string[];
  inputType?: InputTypeSetting;
  dependOn?: string;
  isDisabled?: boolean;
  autoCompleatLabel?: string;
  valueType?: string;
  position: number;
  subSettings?: SubSettings[];
  radioOptions?: SettingsObj[];
  inputText?: string;
  ffKey?: string;
  defineBranchPerRepo?: DefineBranchPerRepo[];
}

export interface DefineBranchPerRepo {
  appId: string;
  appName?: string;
  inputText: string;
  sourceControl: string;
}

export interface RadioOptions {
  isChecked: boolean;
  headerSubSetting: string;
  idSubSetting?: string;
  dependsOnSubSetting?: string;
}

export interface SettingsSubSettings {
  idSubSetting: string;
  dependsOnSubSetting: string;
  textSubSetting: string;
  headerSubSetting: string;
  inputTypeSubSetting: InputTypeSetting;
  radioOptions: RadioOptions[];
  inputText: string;
}

export enum InputTypeSetting {
  Number = 'Number',
  Text = 'Text',
  List = 'List',
  Boolean = 'Boolean',
  Dropdown = 'Dropdown',
  Radio = 'Radio',
  SubSettings = 'SubSettings',
}

export enum SettingsIcon {
  GitLab = 'GitLab',
  GitHub = 'GitHub',
  Bitbucket = 'Bitbucket',
  Azure = 'Azure',
  Gerrit = 'Gerrit',
}

export interface SettingsByName {
  name: string;
  isDevelopment?: boolean;
  settings: SettingsObj[];
  ffKey?: string;
}

export enum SettingsType {
  Scan = 'Scan',
  Usability = 'Usability',
  Applications = 'Applications',
}

export enum SettingsSubType {
  ChatGPT = 'ChatGPT',
  ChatGPTCodeSnippet = 'ChatGPTCodeSnippet',
  PRChatGPT = 'PRChatGPT',
  Monorepo = 'Monorepo',
  MonorepoSplitBy = 'MonorepoSplitBy',
  ScheduleScan = 'ScheduleScan',
  ScheduledScanHour = 'ScheduledScanHour',
  AdvancedOptionsToolTip = 'AdvancedOptionsToolTip',
  ConfiguredIrrelevantAppsTime = 'ConfiguredIrrelevantAppsTime',
  ConfiguredAdvancedOptionsToolTipTime = 'ConfiguredAdvancedOptionsToolTipTime',
  ExcludeFiles = 'ExcludeFiles',
  ConfiguredIrrelevantImagesTime = 'ConfiguredIrrelevantImagesTime',
  DefineBranch = 'DefineBranch',
  DisableCodeSmellIssues = 'DisableCodeSmellIssues',
  ScanImagesByTags = 'ScanImagesByTags',
  ExtraScanSettings = 'ExtraScanSettings',
}

export enum SettingsName {
  ChatGPT = 'ChatGPT',
  Applications = 'Applications',
  Exclude = 'Exclude File or Folder Names',
  ScheduleScan = 'Schedule Scan',
  Tooltips = 'Tooltips',
  ContainerSecurity = 'Container Security',
  DisableCodeSmellIssues = 'Code Quality Issues',
  ExtraSettings = 'Extra Settings',
  None = 'None',
}

export interface SettingsRes {
  settings: SettingsObj;
}

export type LoadSettingsParams = {
  update?: boolean;
  cache?: boolean;
  limit?: number;
};

export interface SettingsInput {
  settingsSubType?: SettingsSubType;
  id?: string;
}

export interface UpdateSettingsRes {
  success: boolean;
  reasonFailed?: string;
}

export interface UpdateSettingsInput {
  settingsSubType?: SettingsSubType;
  settingsType: string;
  enabled?: boolean;
  configured?: number;
  valueList?: string[];
  inputText?: string;
  subSettings?: UpdateSubSettingsInput;
  defineBranchPerRepo?: DefineBranchPerRepo[];
}

export interface MultiBranchToScanPerAppInputItem {
  appId: string;
  resourceOrgName?: string;
  branches: string[];
}

export type AppBranchesToScanInput = MultiBranchToScanPerAppInputItem[];

export interface UpdateSubSettingsInput {
  inputText: string;
  idSubSetting: string;
}
export interface ResetSettingsInput {
  settingsSubType: string;
}

export enum SettingsAction {
  Update = 'update',
  Reset = 'reset',
}

export enum DefineBranchOptions {
  ScanMainBranch = 'Scan the default branch',
  DefineBranch = 'Define the branch that will be scanned',
}

export const settingsName = new Map<string, string>([
  [SettingsSubType.ChatGPT, 'ChatGPT'],
  [SettingsSubType.ChatGPTCodeSnippet, 'ChatGPT Code Snippet'],
  [SettingsSubType.PRChatGPT, 'PR ChatGPT'],
  [SettingsSubType.Monorepo, 'Monorepo'],
  [SettingsSubType.MonorepoSplitBy, 'Monorepo Split By'],
  [SettingsSubType.ScheduleScan, 'Schedule Scan'],
  [
    SettingsSubType.ConfiguredIrrelevantImagesTime,
    'Configured Container Age Limit for Scanning',
  ],
  [SettingsSubType.DefineBranch, 'Define Branch for Scan'],
  [SettingsSubType.ScheduledScanHour, 'Scheduled Scan Hour'],
  [SettingsSubType.AdvancedOptionsToolTip, 'Advanced Options ToolTip'],
  [
    SettingsSubType.ConfiguredIrrelevantAppsTime,
    'Configured Irrelevant Apps Time',
  ],
  [
    SettingsSubType.ConfiguredAdvancedOptionsToolTipTime,
    'Configured Advanced Options ToolTip Time',
  ],
  [SettingsSubType.ExcludeFiles, 'Exclude Files'],
  [SettingsSubType.DefineBranch, 'Define Branch'],
  [SettingsSubType.DisableCodeSmellIssues, 'Disable Code Quality Issues'],
  [SettingsSubType.ScanImagesByTags, 'Scan Images By Tags'],
  [SettingsSubType.ExtraScanSettings, 'Extra Scan Settings'],
]);

export const settingsOrder: string[] = [
  SettingsName.ScheduleScan,
  SettingsName.Applications,
  SettingsName.ContainerSecurity,
  SettingsName.DisableCodeSmellIssues,
  SettingsName.Exclude,
  SettingsName.Tooltips,
  SettingsName.ChatGPT,
  SettingsName.ExtraSettings,
];

export const applicationSettingsRadioOptions = [
  {
    headerSubSetting: DefineBranchOptions.ScanMainBranch,
    isChecked: true,
  },
  {
    headerSubSetting: DefineBranchOptions.DefineBranch,
    isChecked: false,
  },
];
