import { createStore, derive } from 'store-utils';
import { FeatureFlags } from '../../feature-flags/feature-flags-types';
import { AppStore } from 'app-store/src/app-store';

export const _privateFlagsStore = createStore<PrivateFlagsStoreState>(
  /*
   THIS STORE IS PRIVATE AND SHOULD NOT BE ACCESSED DIRECTLY
   It is used to store the flags after they are fetched from LaunchDarkly
   Use FeatureFlagsStore to access the flags!
  */
  {
    _flags: {} as FeatureFlags,
  },
  'Private Feature Flags Store',
);

export const FeatureFlagsStore = derive(
  {
    flags: (get): FeatureFlags | null => {
      const { user } = get(AppStore);
      const { _flags } = get(_privateFlagsStore);
      return user ? _flags : null;
    },
  },
  {
    proxy: createStore({}, 'Public Feature Flags Store'),
  },
);

interface PrivateFlagsStoreState {
  _flags: FeatureFlags;
}
