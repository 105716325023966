import { Divider, Theme } from '@mui/material';
import { FC, useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';
import { IssueDetails } from '../../../../../types/issues-types';
import IssueCardDescription from '../../../../IssueCard/IssueCardDescription';
import IssueCardRecommendation from '../../../../IssueCard/IssueCardRecommendation';
import DevIssueSummaryHeader from './DevIssueSummaryHeader';
import DevSummaryLearnMore from './DevSummaryLearnMore';
import DevSummaryActions from './DevSummaryActions';
import { viewPreferencesActions } from '../../../../../../../view-preferences/actions/view-preferences-actions';

const DevSummaryTab: FC<React.PropsWithChildren<IssueSummaryTabProps>> = ({
  selectedIssue,
  filterIssuesBy,
  isEnabledChatGpt,
  moveToTab,
}) => {
  const { classes } = useStyles();
  const moveToEnabledTab = useCallback(
    (tabName: string) => {
      viewPreferencesActions.enableTabSettingLocally([
        'Issues',
        'Active Issues',
        tabName,
      ]);
      moveToTab(tabName);
    },
    [moveToTab],
  );
  return (
    <div className={classes.issueCard}>
      <DevIssueSummaryHeader
        correlatedIssueId={selectedIssue.correlatedIssueId}
        registryType={selectedIssue.correlatedRegistry}
        appType={selectedIssue.app.type}
        category={selectedIssue.category.name}
        filters={filterIssuesBy}
        severity={selectedIssue.severity}
        latestCommit={selectedIssue.latestCommit}
        moveToTab={moveToEnabledTab}
      />
      <Divider sx={{ mb: 1, mt: 2 }} />
      <div className={classes.twoCols}>
        <div>
          {selectedIssue.secondTitleMarkup && (
            <IssueCardRecommendation
              isResolvedIssuesPage={false}
              fixLink={selectedIssue.fixLink}
              issueId={selectedIssue.issueId}
              fixAvailable={selectedIssue.isFixAvailable}
              prAvailable={selectedIssue.isPRAvailable}>
              {selectedIssue.recommendationMarkup}
            </IssueCardRecommendation>
          )}
          <DevSummaryActions />
        </div>

        {selectedIssue.recommendationMarkup &&
          selectedIssue.secondTitleMarkup && (
            <Divider sx={{ mx: 2 }} orientation='vertical' flexItem />
          )}

        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          {selectedIssue.recommendationMarkup && (
            <IssueCardDescription
              maxHeight={50}
              isEnabledChatGpt={isEnabledChatGpt}
              learnMoreUrl={selectedIssue.learnMore[0]}
              issueId={selectedIssue.issueId}
              isGptAvailable={selectedIssue.isGPTFixAvailable}
              isResolvedIssuesPage={false}>
              {selectedIssue.secondTitleMarkup}
            </IssueCardDescription>
          )}
          <Divider flexItem />
          <DevSummaryLearnMore moveToTab={moveToEnabledTab} />
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  category: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  categoryIcon: {
    width: '1em',
    height: '1em',
    flexShrink: 0,
    color: theme.palette.primary.main,
  },
  summaryContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
  },
  issueCard: {
    backgroundColor: theme.palette.background.paper,
    height: '100%',
  },
  appTypeSummeryScore: {
    display: 'flex',
  },
  appTypeSummery: {
    display: 'flex',
  },
  repoSummery: {
    display: 'flex',
    flexDirection: 'row',
  },
  twoCols: {
    display: 'flex',
    '& > div': {
      flex: '1 1 100%',
    },
    height: '100%',
  },
}));

interface IssueSummaryTabProps {
  selectedIssue: IssueDetails;
  filterIssuesBy: {};
  isEnabledChatGpt: boolean;
  moveToTab;
}

export default DevSummaryTab;
