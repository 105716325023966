import { AppPages, decodeFromUrl, navigateWithParameters } from 'app-navigator';
import {
  ConditionalFilter,
  FilterItems,
  FilterPage,
  getFilterItemsLabels,
  getOpenFilterItemIds,
  SearchInput,
} from 'ox-filter-utils';
import { snapshot } from 'valtio';

import { createSimpleAsyncAction } from 'async-utils';
import { logger } from 'logging-utils';
import { Nullable } from 'ox-common-types';
import getFilterLabels from 'ox-filter-utils/src/get-filter-labels/get-filter-labels';
import { GlobalDataViewSelector } from '../../../app/components/DataViewSelector/global-data-view-selector-store';
import { calcDateRange } from '../../../app/store-actions/top-bar-store-actions';
import TopBarStore from '../../../app/stores/top-bar-store';
import { LoadIssuesFiltersParams } from '../../common/types/issues-types';
import { conditionalFiltersUtils } from '../../common/utils/conditional-filters-utils';
import pipelineIssuesService from '../services';
import {
  closeIssuesDrawer,
  setPipelineIssuesConditionalFiltersInStore,
  setPipelineIssuesFilterItemsInStore,
} from '../store-actions/pipeline-issues-store-actions';
import PipelineIssuesStore from '../stores/pipeline-issues-store';
import { loadIssuesPipeline } from './pipeline-issues-actions';
import { debounce } from 'lodash-es';

export const onChangePipelinesIssuesConditionalFilter = (
  conditionalFiltersUpdated: ConditionalFilter[],
  type?: string,
) => {
  setPipelineIssuesConditionalFiltersInStore(conditionalFiltersUpdated);
  navigateWithParameters(AppPages.PipelineIssues, {
    conditionalFilters: conditionalFiltersUpdated,
  });
  closeIssuesDrawer();
  loadIssuesPipeline({ update: true });
  getPipelineIssuesFilterItems(type, true);
};

export const clearPipelineConditionalFilter = (type: string) => {
  const { conditionalFilters } = snapshot(PipelineIssuesStore);
  const conditionalFiltersUpdated = conditionalFilters.filter(
    filter => filter.fieldName !== type,
  );
  navigateWithParameters(AppPages.PipelineIssues, {
    conditionalFilters: conditionalFiltersUpdated,
  });
  setPipelineIssuesConditionalFiltersInStore(conditionalFiltersUpdated);
  getPipelineIssuesFilterItems(type, true);
};

export const setInitialFilters = (
  filtersFromUrl: string | null,
  conditionalFiltersFromUrl: string | null,
) => {
  try {
    if (conditionalFiltersFromUrl) {
      const conditionalFilters = decodeFromUrl(conditionalFiltersFromUrl);
      setPipelineIssuesConditionalFiltersInStore(
        conditionalFilters as ConditionalFilter[],
      );
      navigateWithParameters(AppPages.PipelineIssues, {
        conditionalFilters: conditionalFilters,
      });
    } else {
      const _filters = filtersFromUrl ? decodeFromUrl(filtersFromUrl) : {};
      const conditionalFilters =
        conditionalFiltersUtils.translateFiltersToConditionalFilters(
          _filters as {},
        );
      setPipelineIssuesConditionalFiltersInStore(conditionalFilters);
      navigateWithParameters(AppPages.PipelineIssues, {
        conditionalFilters: conditionalFilters,
      });
    }
  } catch {
    logger.error('Error parsing filters from url');
  }
};

export const setPipelineIssuesFilterLabels = createSimpleAsyncAction(
  async () => {
    const filterLabels = await getFilterLabels(FilterPage.PipelineIssues);
    const filterItems: FilterItems = getFilterItemsLabels(filterLabels);

    PipelineIssuesStore.filterItems = filterItems;
  },
  {
    asyncState: PipelineIssuesStore.loadingFilters,
    errorMessage: 'Failed to load applications filter labels',
  },
);

export const getPipelineIssuesFilterItems = async (
  type?: Nullable<string>,
  isOpen?: boolean,
  params?: LoadIssuesFiltersParams,
) => {
  const currentFilterItem =
    PipelineIssuesStore.filterItems &&
    type &&
    PipelineIssuesStore.filterItems[type];

  if (currentFilterItem) {
    currentFilterItem.isOpen = Boolean(isOpen);
    currentFilterItem.isLoading = Boolean(isOpen);
  }
  const { cache = true, limit = 100 } = params || {};
  const { selectedAppOwnersEmails, selectedTagIds } = snapshot(
    GlobalDataViewSelector,
  );
  const { filterItems, conditionalFilters } = snapshot(PipelineIssuesStore);
  const { searchValues } = snapshot(PipelineIssuesStore);
  const { dateRange } = snapshot(TopBarStore);
  const [from, to] = calcDateRange(dateRange);
  const filterItem = filterItems && type && filterItems[type];
  const openFilterItemIds = filterItems
    ? getOpenFilterItemIds(filterItems)
    : [];

  const isCurrentFilterItemClosed = filterItem && !filterItem.isOpen;
  const isAllFiltersClosed =
    !openFilterItemIds || openFilterItemIds.length === 0;

  if (isAllFiltersClosed || isCurrentFilterItemClosed) {
    if (currentFilterItem) {
      currentFilterItem.isLoading = false;
    }
    return;
  }

  const filtersApiParams = {
    owners: selectedAppOwnersEmails,
    tagIds: selectedTagIds,
    limit,
    dateRange: { from, to },
    search: searchValues,
    openItems: openFilterItemIds,
    conditionalFilters,
  };
  PipelineIssuesStore.isLoadingFilters = true;
  const results =
    await pipelineIssuesService.getPipelineIssuesFilterItems.execute(
      filtersApiParams,
      cache,
    );
  PipelineIssuesStore.isLoadingFilters = false;

  if (currentFilterItem) {
    currentFilterItem.isLoading = false;
  }
  if (results?.filters && filterItems) {
    setPipelineIssuesFilterItemsInStore(results?.filters, filterItems);
  }
};

export const handleSearchInOpenFilterItemsPipelineIssues = debounce(
  (searchValues: SearchInput[]) => {
    PipelineIssuesStore.searchValues = searchValues;
    getPipelineIssuesFilterItems();
  },
  500,
);
