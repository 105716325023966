import { snapshot } from 'valtio';
import viewPreferencesService from '../services';
import UserViewPreferencesStore from '../stores/user-view-preference-store';
import { PageSetting } from '../types';
import {
  setIsLoadedUserPreferences,
  setUserViewPreferences,
} from '../stores-actions/view-preferences-store-actions';
import { findPages } from '../stores/view-preferences-form-store';

const loadUserViewSettings = async () => {
  const settings = await viewPreferencesService.getUserViewSettings.execute();
  if (settings) {
    setUserViewPreferences(settings);
  }
  setIsLoadedUserPreferences(true);
};

const enableTabSettingLocally = (path: string[]) => {
  const { preferences } = snapshot(UserViewPreferencesStore);

  const updatePreferences = (
    settings: PageSetting[],
    path: string[],
  ): PageSetting[] => {
    if (path.length === 0) return settings;

    return settings.map(setting => {
      if (setting.page === path[0]) {
        if (path.length === 1) {
          return {
            ...setting,
            value: true,
          };
        } else if (setting.children) {
          return {
            ...setting,
            children: updatePreferences(setting.children, path.slice(1)),
          };
        }
      }
      return setting;
    });
  };

  const newPreferences = updatePreferences(preferences, path);

  setUserViewPreferences(newPreferences);
};

const getAvailableContentPerPage = (
  page: string,
  preferences: PageSetting[],
) => {
  const contents = findPages(preferences, page.split('-'));

  const tabsWithAggregations = contents?.reduce((acc, content) => {
    if (content.aggregation?.length) {
      return acc.concat(
        content.aggregation.map(agg => ({
          ...content,
          children: [],
          page: agg,
        })),
      );
    }
    return acc.concat(content);
  }, [] as PageSetting[]);
  return tabsWithAggregations;
};

const filterTabsByPreferences = (
  tabItems: { title: string }[],
  preferences: PageSetting[],
  page: string,
) => {
  const avalableTabs = getAvailableContentPerPage(page, preferences);
  return tabItems?.filter(tab => {
    const item = avalableTabs?.find(t => t.page === tab.title);
    if (!item) return true;

    return item.value;
  });
};

export const viewPreferencesActions = {
  loadUserViewSettings,
  enableTabSettingLocally,
  filterTabsByPreferences,
};
