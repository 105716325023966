import { Tab, Theme } from '@mui/material';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import { OxTabs } from './OxTabs/OxTabs';

export const CardTabs: FC<CardTabsProps> = ({
  tabs,
  selectedTab,
  onChange,
}) => {
  const { classes } = useStyles();
  return (
    <OxTabs
      variant='scrollable'
      className={classes.tabs}
      selectedTab={selectedTab}
      onChange={onChange}
      classes={{ root: classes.tabsRoot }}>
      {tabs.map(({ title, icon }) => (
        <Tab
          iconPosition='start'
          key={title}
          value={title}
          icon={icon}
          label={title}
          className={classes.tab}
          sx={{ fontWeight: 400, textTransform: 'none' }}
          classes={{ root: classes.tabRoot }}
        />
      ))}
    </OxTabs>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  tabs: {
    position: 'sticky',
    top: 0,

    zIndex: 1000,
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,

    '& .MuiTab-root.Mui-selected': {
      color: theme.palette.primary.light,
    },
  },
  tab: {
    transition: theme.transitions.create('color'),
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  tabsRoot: {
    minHeight: 50,
    height: 50,
  },
  tabRoot: {
    minHeight: 50,
    height: 50,
  },
}));

export interface CardTabsProps {
  selectedTab: string;
  tabs: {
    title: string;
    component?: JSX.Element;
    icon?: JSX.Element | undefined;
  }[];
  onChange?: (event: React.SyntheticEvent, value: string) => void;
}
