import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ServiceExecute } from 'ox-common-types';
import { CancelReportFPInput } from '../../types/issues-types';
import mutation from './cancel-report-false-positive.gql';

const cancelReportFalsePositive = (
  client: ApolloClient<NormalizedCacheObject>,
): ServiceExecute<CancelReportFPInput, boolean> => {
  return {
    execute: async (
      cancelReportFPInput: CancelReportFPInput,
    ): Promise<boolean> => {
      const results = await client.mutate<CancelReportFalsePositiveResponse>({
        mutation,
        variables: { input: cancelReportFPInput },
      });
      if (results.data?.cancelReportFalsePositiveIssue) {
        return results.data?.cancelReportFalsePositiveIssue;
      }
      throw new Error('Failed to cancel report false positive alert');
    },
  };
};

export interface CancelReportFalsePositiveResponse {
  cancelReportFalsePositiveIssue: boolean;
}

export default cancelReportFalsePositive;
