import { Nullable, RepoTypes } from 'ox-common-types';

export interface Organization {
  id: string;
  name: string;
  display_name: string;
  branding: Nullable<Branding>;
  customerTier?: CustomerTier;
  developersCount?: number;
  trialEndTimestamp?: number;
  trialStartTimestamp?: number;
  daysLeftForTrial?: number;
  users?: DevCount[];
  resourceType?: string;
  usersFromApi?: SimpleUser[];
  developersCountAPI?: number;
  developersCountCommits?: number;
  created_at?: Date;
  isOnboardingRequired?: boolean;
}

export interface SimpleUser {
  id: string;
  name: string;
  username: string;
}
export interface DevCount {
  filtered: boolean;
  committerAuthor: string;
  committerEmail: string;
  latestSha: string;
  latestCommitDate: string | Date;
  link: string;
  gitType: RepoTypes;
}

export interface Branding {
  logo_url: string;
  colors: Record<string, unknown>;
}

export enum CustomerTier {
  Free = 'Free',
  Standard = 'Standard',
  Paying = 'Paying',
}
