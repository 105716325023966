import { Theme, Typography, Divider, IconButton, Tooltip } from '@mui/material';
import { FC, useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';
import ApplyOnlyFilter from './ApplyOnlyFilter';
import CopyOption from './CopyOption';
import FilterOption from './FilterOption';
import {
  SettingsSubType,
  SettingsType,
  UpdateSettingsInput,
} from '../../../../settings/types/settings-types';
import { GiSightDisabled } from 'react-icons/gi';
import { updateSettings } from '../../../../settings/actions/settings-actions';
import { openSnackbar } from 'snackbar-utils';
import { AppPages } from 'app-navigator';
import { SnackbarMessageWithLink } from 'ox-react-components';
import React from 'react';
import CopyLinkOption from './CopyLinkOption';

const ActionsOptions: FC<React.PropsWithChildren<ActionsOptionsProps>> = ({
  value,
  filterType,
  filters,
  id,
  withFilter,
  withName,
  displayValue,
  moveToApp,
  copyLink,
}) => {
  const { classes } = useStyles();

  const handleChange = useCallback(() => {
    const input: UpdateSettingsInput = {
      enabled: false,
      settingsType: SettingsType.Usability,
      settingsSubType: SettingsSubType.AdvancedOptionsToolTip,
    };
    updateSettings(input, true);
    const disableOptionsMsg = React.createElement(SnackbarMessageWithLink, {
      message:
        'Enhanced Hover Actions have been turned off. To re-enable, please navigate to the Usability tab on the Settings page.',
      linkTitle: 'Link to change the Usability Settings ',
      linkHref: `${AppPages.Settings}?tab=usabilitySettings`,
    });
    openSnackbar(disableOptionsMsg, {
      variant: 'success',
    });
  }, []);

  return (
    <div className={classes.container}>
      {withName && (
        <>
          <Typography variant='body2' color='text.primary' fontWeight='bold'>
            {displayValue || value}
          </Typography>
        </>
      )}
      <Divider />
      <div className={classes.options}>
        {copyLink && (
          <>
            <CopyLinkOption copyLink={copyLink} />
            <Divider orientation='vertical' flexItem />
          </>
        )}

        <CopyOption value={value} />

        {withFilter && (
          <>
            <Divider orientation='vertical' flexItem />
            <FilterOption
              value={value}
              isAvailable={
                filters ? filters.length === 1 && filters[0] === value : false
              }
              filterType={filterType ? filterType : ''}
              id={id}
            />
            <Divider orientation='vertical' flexItem />
            <ApplyOnlyFilter
              value={value}
              filterType={filterType ? filterType : ''}
              id={id}
              isAvailable={
                filters ? filters.length === 1 && filters[0] === value : false
              }
            />
          </>
        )}
        <Divider orientation='vertical' flexItem />
        <Tooltip title={'Disable Hover Actions'}>
          <IconButton size='small' color='primary' onClick={handleChange}>
            <GiSightDisabled fontSize='1.2rem' />
          </IconButton>
        </Tooltip>
        {/* {moveToApp && isDevelopment() && (
          <>
            <Divider orientation='vertical' flexItem />
            <MoveToAppOption moveToApp={moveToApp} />
          </>
        )} */}
      </div>
    </div>
  );
};

const useStyles = makeStyles()((theme: Theme) => ({
  toolTip: {
    marginBlockStart: theme.spacing(0.8),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(0.2),
  },
  options: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: theme.spacing(1),
  },
}));

interface ActionsOptionsProps {
  value: string;
  displayValue?: string;
  id?: string;
  filterType?: string;
  filters?: string[];
  withFilter?: boolean;
  withName?: boolean;
  moveToApp?: () => void;
  copyLink?: { link: string; label: string };
}

export default ActionsOptions;
