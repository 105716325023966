import { logger } from 'logging-utils';
import whatsNewService from '../services';
import * as storeActions from '../store-actions/whats-new-store-actions';

export const closeWhatsNew = async (dismiss: boolean) => {
  if (dismiss) {
    try {
      const ids = storeActions.getWhatsNewIds();
      storeActions.clearWhatsNewItems();
      await whatsNewService.dismissWhatNewItems.execute(ids);
    } catch (err) {
      logger.error('Failed to dismiss whats-new items');
    }
  }
};
