import {
  ClickAwayListener,
  IconButton,
  Popper,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { ScopeNames } from '@oxappsec/ox-unified-permissions/src/scopes';
import { getParamFromUrl } from 'app-navigator';
import { AppStore } from 'app-store/src/app-store';
import { OxSearchBox } from 'ox-react-components/src/OxSearcBox/OxSearchBox';
import {
  ChangeEvent,
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FaUserTag } from 'react-icons/fa6';
import {
  MdKeyboardArrowDown as KeyboardArrowDownOutlinedIcon,
  MdKeyboardArrowUp as KeyboardArrowUpOutlinedIcon,
} from 'react-icons/md';
import { openSnackbar } from 'snackbar-utils';
import { makeStyles } from 'tss-react/mui';
import { useSnapshot } from 'valtio';
import DataViewModalContainer from './DataViewModalContainer';
import DataViewSelectorOptionsList from './DataViewSelectorOptionsList';
import { UNASSIGNED_OWNER_VALUE } from './data-view-selector-types';
import { GlobalDataViewSelector } from './global-data-view-selector-store';
import {
  setFilterValue,
  setSelectedAppOwnersEmails,
  setSelectedTagIds,
} from './global-data-view-selector-store-actions';
import { applicationsStoreActions } from '../../../applications/stores/applications-store-actions';

const DataViewSelectorContainer: FC = () => {
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const { user } = useSnapshot(AppStore);
  const {
    dataViewSelectorOptions,
    filterValue,
    selectedAppOwnersEmails,
    selectedTagIds,
  } = useSnapshot(GlobalDataViewSelector);

  const isEntireOrg =
    selectedAppOwnersEmails?.length === 0 && selectedTagIds.length === 0;
  const isUnassignedOwner =
    selectedAppOwnersEmails?.length === 1 &&
    selectedAppOwnersEmails.includes(UNASSIGNED_OWNER_VALUE);
  const isShowNotAvailable =
    user?.scopes && dataViewSelectorOptions.length === 0;
  const open = Boolean(anchorEl) && !isShowNotAvailable;
  const id = open ? 'show-issues-data-pop-over' : undefined;

  const setSelectedOptions = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const selectedId = event.target.value;
      if (
        selectedId === AppOwnerDefault.EntireOrganization ||
        selectedId === AppOwnerDefault.EntireScope
      ) {
        setSelectedAppOwnersEmails([]);
        setSelectedTagIds([]);
      } else if (
        selectedId === AppOwnerDefault.UnAssigned ||
        selectedId === UNASSIGNED_OWNER_VALUE
      ) {
        applicationsStoreActions.clearSelectedApplication();
        setSelectedAppOwnersEmails([UNASSIGNED_OWNER_VALUE]);
      } else {
        const itemsWithoutUnassigned = selectedAppOwnersEmails.filter(
          email => email !== UNASSIGNED_OWNER_VALUE,
        );
        const currentItem = dataViewSelectorOptions.find(
          option => option.id === selectedId,
        );
        if (!currentItem) return;

        switch (currentItem.scopeName) {
          case ScopeNames.AppOwner:
            if (itemsWithoutUnassigned.includes(currentItem.id)) {
              setSelectedAppOwnersEmails(
                itemsWithoutUnassigned.filter(
                  email => email !== currentItem.id,
                ),
              );
            } else {
              setSelectedAppOwnersEmails([
                ...itemsWithoutUnassigned,
                currentItem.id,
              ]);
            }
            break;
          case ScopeNames.Tags:
            if (selectedTagIds.includes(currentItem.id)) {
              setSelectedTagIds(
                selectedTagIds.filter(tagId => tagId !== currentItem.id),
              );
            } else {
              setSelectedTagIds([...selectedTagIds, currentItem.id]);
            }
            break;
        }
      }
    },
    [selectedAppOwnersEmails, selectedTagIds, dataViewSelectorOptions],
  );

  const onToggleOpen = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      setAnchorEl(open ? null : event.currentTarget);
    },
    [open],
  );

  const onClickAway = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onFilterOptions = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFilterValue(event.target.value.toLowerCase().trimStart());
    },
    [],
  );

  useEffect(() => {
    const appOwnersAsString = getParamFromUrl('owners');
    const tagsAsString = getParamFromUrl('tagIds');
    const appOwnersArr = appOwnersAsString?.split(',') || [];
    const tagsArr = tagsAsString?.split(',') || [];
    setSelectedAppOwnersEmails(appOwnersArr);
    setSelectedTagIds(tagsArr);
  }, []);

  useEffect(() => {
    // This will check if the selected tags are available in the scope,
    // if not then remove them from the selected tags and show a snackbar
    const params = new URLSearchParams(window.location.search);
    const tagsAsString = params.get('tagIds');
    const tagsArr = tagsAsString?.split(',') || [];
    const tagsOptionsIds = dataViewSelectorOptions.reduce(
      (acc: string[], option) => {
        if (option.scopeName === ScopeNames.Tags) {
          acc.push(option.id);
        }
        return acc;
      },
      [],
    );

    const isInvalidTags = tagsArr.reduce((acc, tag) => {
      if (tagsOptionsIds.length > 0 && !tagsOptionsIds.includes(tag)) {
        return true;
      }
      return acc;
    }, false);

    if (isInvalidTags) {
      setSelectedTagIds([]);
      openSnackbar(
        'Some of the selected tags are not available in your scope',
        { variant: 'error' },
      );
    }
  }, [dataViewSelectorOptions, selectedTagIds]);

  useEffect(() => {
    const userScopeIsEntireOrg = user && !user?.scopes;
    if (userScopeIsEntireOrg) {
      setSelectedTagIds([]);
    }
  }, [user]);

  const selectedOptionsString = useMemo(() => {
    const selectedItems = [...selectedAppOwnersEmails, ...selectedTagIds];
    const selectedOptions = dataViewSelectorOptions.filter(option =>
      selectedItems.includes(option.id),
    );

    if (isShowNotAvailable) {
      return AppOwnerDefault.NotAvailable;
    } else if (selectedOptions.length === 1) {
      return selectedOptions[0].displayName;
    } else if (selectedOptions.length > 1) {
      return ` ${selectedOptions[0].displayName} (+${
        selectedOptions.length - 1
      })`;
    } else if (isUnassignedOwner) {
      return AppOwnerDefault.UnAssigned;
    } else if (user?.scopes) {
      return AppOwnerDefault.EntireScope;
    } else {
      return AppOwnerDefault.EntireOrganization;
    }
  }, [
    selectedAppOwnersEmails,
    selectedTagIds,
    isUnassignedOwner,
    isShowNotAvailable,
    user?.scopes,
    dataViewSelectorOptions,
  ]);

  return (
    <>
      <ClickAwayListener onClickAway={onClickAway}>
        <div className={classes.showIssuesFor}>
          <div
            onClick={onToggleOpen}
            aria-describedby={id}
            className={classes.selectedOwners}>
            {<FaUserTag />}
            <Tooltip
              arrow
              title={
                isShowNotAvailable ? 'No applications in your scope' : undefined
              }
              placement='top'>
              <Typography
                textAlign='center'
                lineHeight={2}
                className={classes.textOverflow}
                variant='body2'>
                {selectedOptionsString}
              </Typography>
            </Tooltip>
            {open ? (
              <IconButton color='inherit' size='small'>
                <KeyboardArrowUpOutlinedIcon />
              </IconButton>
            ) : (
              <IconButton color='inherit' size='small'>
                <KeyboardArrowDownOutlinedIcon />
              </IconButton>
            )}
          </div>
          <Popper
            placement='bottom'
            className={classes.popper}
            id={id}
            open={open}
            anchorEl={anchorEl}>
            <div className={classes.search}>
              <OxSearchBox value={filterValue} onChange={onFilterOptions} />
            </div>
            <DataViewSelectorOptionsList
              filterValue={filterValue}
              user={user}
              options={dataViewSelectorOptions}
              onOptionSelect={setSelectedOptions}
              entireOrg={isEntireOrg}
              unassignedOwner={isUnassignedOwner}
              selectedAppOwnersEmails={selectedAppOwnersEmails}
              selectedTagIds={selectedTagIds}
              ownersCount={dataViewSelectorOptions.length}
            />
          </Popper>
        </div>
      </ClickAwayListener>
      <DataViewModalContainer />
    </>
  );
};

export enum AppOwnerDefault {
  EntireOrganization = 'Entire Organization',
  UnAssigned = 'Unassigned',
  EntireScope = 'Entire Scope',
  NotAvailable = 'Not Available',
}

const useStyles = makeStyles()((theme: Theme) => ({
  search: {
    padding: theme.spacing(1),
  },
  textOverflow: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    flexGrow: 1,
  },
  popper: {
    zIndex: theme.zIndex.drawer + 2,
    borderRadius: theme.shape.borderRadius,
    width: '12.5rem',
    boxShadow: theme.shadows['6'],
    backgroundColor: theme.palette.background.paper,
  },
  selectedOwners: {
    cursor: 'pointer',
    height: '2.1rem',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    paddingInline: theme.spacing(1),
    border: `1px solid ${theme.palette.text.secondary}`,
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
  },
  showIssuesFor: {
    width: '12.5rem',
  },
}));

export default DataViewSelectorContainer;
