import {
  Box,
  Divider,
  List,
  ListItem,
  Typography,
  useTheme,
} from '@mui/material';
import { severityToName } from 'ox-common-types';
import { TrendIcon } from '../TrendIcon/TrendIcon';
import { CveSeverity, CveSeverityIconMap } from '../Vulnerabilities/icon-utils';
import { SeverityColor } from 'severity-utils';
import toNumber from 'lodash/toNumber';
import { getSeveritySumupText } from './utils';

const SeverityListItem: React.FC<{ title: string; severity: string }> = ({
  title,
  severity,
}) => (
  <ListItem
    sx={{ display: 'flex', px: 1, py: 0.5, justifyContent: 'space-between' }}>
    <b>{title}</b>
    <SeverityColor severity={severity}>
      {color => (
        <span
          style={{
            color,
            textTransform: 'capitalize',
            whiteSpace: 'nowrap',
          }}>
          <b>{severity}</b>
        </span>
      )}
    </SeverityColor>
  </ListItem>
);

const FactorListItem: React.FC<{ factor: Factor }> = ({ factor }) => {
  const theme = useTheme();
  const Icon = CveSeverityIconMap.get(factor.sfName as CveSeverity); // Adjust type as needed

  return (
    <ListItem
      sx={{ display: 'flex', px: 1, py: 0.5, justifyContent: 'space-between' }}>
      <Box display='flex' alignItems='center' flexDirection='row' gap={0.5}>
        <TrendIcon size={16} value={factor.sfChangedNumber} />
        {Icon && <Icon width={16} />}
        {factor.sfName}
      </Box>
      <Box
        sx={{
          pl: 1,
          py: 0.5,
          textWrap: 'nowrap',
          color:
            factor.sfChangedNumber > 0
              ? theme.palette.categoryRiskColor.high
              : theme.palette.categoryRiskColor.passed,
        }}>
        <b>
          {factor.sfChangedNumber > 0
            ? `+${factor.sfChangedNumber}`
            : factor.sfChangedNumber}
        </b>
      </Box>
    </ListItem>
  );
};

const SeverityFactorsBreakdown: React.FC<SeverityFactorsBreakdownProps> = ({
  originalSeverity,
  oxSeverity,
  severityTitle,
  finalSeverityTitle,
  factors = [],
  importantSeverityBreakdown = [],
  secondSeverity,
  secondSeverityTitle,
  bottomDescription,
  overrideSeverity,
  overrideSeverityReason,
}) => {
  const oxSeverityFormatted = toNumber(oxSeverity)
    ? severityToName[oxSeverity as number]
    : oxSeverity;

  const severitySumupText =
    importantSeverityBreakdown.length || !factors.length
      ? getSeveritySumupText(importantSeverityBreakdown, oxSeverity)
      : null;

  return (
    <List sx={{ minWidth: 300 }}>
      <SeverityListItem title={severityTitle} severity={originalSeverity} />

      {secondSeverity && secondSeverityTitle && (
        <SeverityListItem
          title={secondSeverityTitle}
          severity={secondSeverity}
        />
      )}

      <Divider />

      {factors.length && !severitySumupText ? (
        factors
          .filter(factor => Math.abs(factor.sfChangedNumber) >= 1)
          .map(factor => <FactorListItem key={factor.sfName} factor={factor} />)
      ) : (
        <ListItem sx={{ display: 'flex', px: 1, py: 1, alignItems: 'center' }}>
          <Typography color='text.primary' variant='body2'>
            {severitySumupText}
          </Typography>
        </ListItem>
      )}
      {overrideSeverity && (
        <SeverityListItem
          title={`${overrideSeverityReason ?? ''} Issue Severity Override`}
          severity={oxSeverityFormatted.toString()}
        />
      )}

      <Divider />

      <SeverityListItem
        title={finalSeverityTitle}
        severity={oxSeverityFormatted.toString()}
      />

      {bottomDescription && (
        <ListItem sx={{ px: 1, py: 1, display: 'flex', alignItems: 'center' }}>
          <Typography
            color='text.secondary'
            variant='caption'
            sx={{ fontStyle: 'italic' }}>
            {bottomDescription}
          </Typography>
        </ListItem>
      )}
    </List>
  );
};

export interface SeverityFactorsBreakdownProps {
  originalSeverity: string;
  oxSeverity: string | number;
  severityTitle: string;
  finalSeverityTitle: string;
  factors: { sfName: string; sfChangedNumber: number }[];
  importantSeverityBreakdown?: string[];
  secondSeverity?: string;
  secondSeverityTitle?: string;
  bottomDescription?: boolean | string | React.ReactElement;
  overrideSeverityReason?: string;
  overrideSeverity?: boolean;
}

export enum OverrideSeverityReason {
  Manual = 'Manual',
  Workflow = 'Workflow',
}

interface Factor {
  sfName: string;
  sfChangedNumber: number;
}

export default SeverityFactorsBreakdown;
